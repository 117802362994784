import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation,
    Navigate
  } from "react-router-dom";
import OnlinePayements from './OnlinePayements';
import HeaderSectionComponent from './utils/HeaderSectionComponent';
import FooterComponent from './utils/FooterComponent';
import SignUp from './SignUp';
import SignIn from './SignIn';
import UserDashboard from './UserDashboard';
import Transactions from './Transactions';
import Councils from './Councils';
import HealthInsurance from './HealthInsurance';
import Insurance from './Insurance';
import MicroFinance from './MicroFinance';
import Utilities from './Utilities';
import AddFavouriteBiller from './AddFavouriteBiller';
import Contact from './Contact';



class Wrapper extends Component {
    render() {
        let isLoggedIn = localStorage.getItem('token') != null && localStorage.getItem('isLoggedIn') != null && localStorage.getItem('user') != null;
        console.log(`user is logged in ? ${isLoggedIn}`);
        return (
            <div>
                <div className="header-top-bar">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="contact-info">
                                    <p><i className="ti-mobile"></i><a href="tel:+263771222151">+263771222151</a></p>
                                    <p><i className="ti-email"></i> <a href="mailto:EcocashHelpdesk@ecocashholdings.co.zw">EcocashHelpdesk@ecocashholdings.co.zw</a></p>
                                </div>
                            </div>
                            <div className="col-md-6 text-md-right">
                                <ul className="login-area">
                                <Link to='/sign-in'><li><a className="login"><i className="ti-power-off"></i> Login</a></li></Link>
                                <Link to='/sign-up'> <li><a className="rsgiter"><i className="ti-plus"></i> Register</a></li></Link>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
               <HeaderSectionComponent />

               <Routes>
                    <Route path="/health-insurance" element={<HealthInsurance/>}/>
                    <Route path="/councils" element={<Councils/>}/>
                    <Route path="/sign-up" element={<SignUp/>}/>  
                    <Route path="/sign-in" element={<SignIn/>}/>  
                    <Route path="/life-insurance" element={<Insurance/>}/>
                    <Route path="/micro-finance" element={<MicroFinance/>}/>
                    <Route path="/utility-bills" element={<Utilities/>}/>
                    <Route exact path="/" element={<Councils/>}/>
                    <Route exact path="/contactus" element={<Contact/>}/>

                    <Route
                        path="/add-biller"
                        replace 
                        element={
                            !isLoggedIn ? (
                            <Navigate to="/sign-in" />
                            ) : (
                            <AddFavouriteBiller />
                            )
                        }
                        />

                    <Route path="/dashboard"  
                        replace 
                        element={
                            !isLoggedIn ? (
                            <Navigate to="/sign-in" />
                            ) : (
                            <UserDashboard />
                            )
                        }/> 

                    <Route path="/transactions" 
                    replace 
                     element={
                        !isLoggedIn ? (
                        <Navigate to="/sign-in" />
                        ) : (
                        <Transactions />
                        )
                    }
                    />  



                </Routes>

                

                <section>
                    <FooterComponent/>
                </section>

            </div>
        );
    }
}

export default Wrapper;
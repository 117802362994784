import React, {Component, useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';
import "aos/dist/aos.css";
import axios from 'axios';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import {BundleCategoryContext, BundleContext} from "../config/contexts";
import { CSVLink, CSVDownload } from "react-csv";
import DataTable, { createTheme } from 'react-data-table-component';
import { AuthService } from '../services/AuthService';


import aaa from '../../src/econetfooter.png'
import { BuyBulkBundles } from './facebookPixelEvent';

const csvData = [
    ["PhoneNumber"],
    ["07XXXXXXXX"],
    ["07XXXXXXXX"]
  ];

 

const currencyCode = [
    {label: "ZWG", value: " ZWG"},
    {label: "USD", value: "USD"},
];

export default function () {
    const { airtimeState, setAirtimeState, show,changeHandlerBulkBundlesAirtime , parsedDataBulkBundle, setParsedDataBulkBundle,
         parsedData, setParsedData, tableRows, setTableRows, values, 
         setValues, tableRowsBulkBundle, setTableRowsBulkBundle,valuesBulkBundle, 
         setValuesBulkBundle,responseBundleList,  setResponseBundleList} = useContext(BundleContext)
    const {categories, setCategories, setSelectedCategory, setBundleCategory} = useContext(BundleCategoryContext)
    const authService = new AuthService();
     

   
    const [showUpload, setShowUpload] = useState(false)


   

    const onUpload = (e) => setShowUpload(true)
    const ref = useRef(null)


    const handleClickSetFocus = () => {
        ref.current.focus();
      };

      const columns = [
        {
          name: 'PhoneNumber',
          selector: 'PhoneNumber',
          sortable: true,
        },
       
      ];


     


    useEffect(() => {
        BuyBulkBundles();
        authService.getToken().then((response)=>{
            //console.log(response.data.body);
      axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/bundles/get-all-bundle-categories`,
          {
          headers: {
              'Authorization': `Bearer ${response.data.body}`
          }
          }
      )
            .then((res) => {
                const {body} = res.data;
                setCategories(body)
            })
            .catch((e) => {
                show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: 'Bundle categories could not be fetched!',
                    life: 3000
                })
            })
    }, [])

})
    return (
        <>
            <div className="row">
                <div className="col-md-12 form-area pt-5">
                    <div className='text-center'>
                        <FormLabel id="airtime-radio-buttons-group-label" className="text-4 mb-3"><h5
                            className="text-4 mb-3">Choose Bundles Category</h5></FormLabel>
                    </div>
                    <div className="mb-3">
                        {categories.map((c, i) => {
                            return (
                                <div className="custom-control custom-radio custom-control-inline"
                                     key={i}>
                                    <label>

                                        <input type="radio"
                                               name="biller"
                                               value={c.name}
                                               onClick={handleClickSetFocus}
                                               onChange={(e) => setSelectedCategory(e.target.value)}
                                        />
                                        <div className="card align-items-center" style={{
                                            borderRadius: "15px",
                                            boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
                                        }}>
                                            <div className="card-body ">
                                                <img src={c.imageUrl} alt={c.name}/>
                                            </div>
                                            <p className='text-5'>{c.name}</p>
                                        </div>
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
{/* 
            <FormControl>
                <FormLabel id="airtime-radio-buttons-group-label" className="text-4 mb-3"><h5
                    className="text-4 mb-3">Buy bundle for</h5></FormLabel>
                <RadioGroup
                    aria-labelledby="airtime-radio-buttons-group-label"
                    defaultValue={numberOptions.myNumber}
                    row
                    name="radio-buttons-group"
                    onChange={(e) => setNumberOption(e.target.value)}
                >
                    <FormControlLabel value={numberOptions.myNumber} control={<Radio/>} label="My Number"/>
                    <FormControlLabel value={numberOptions.otherNumber} control={<Radio/>} label="Other Mobile"/>
                </RadioGroup>
            </FormControl> */}

            <div className="row">
                <div className="m-auto">
                    <form>
                        <div className="row">
                            <div className="form-group col-sm-6">
                                <label htmlFor="payingMsisdn">My Mobile Number<small>(required)</small></label>
                                <input name="payingMsisdn"
                                       className="form-control" type="text"
                                       required="required"
                                       placeholder="Enter Mobile Number (07********)"
                                       value={airtimeState.payingMsisdn}
                                       onChange={e => setAirtimeState((s) => ({
                                           ...s,
                                           payingMsisdn: e.target.value
                                       }))}
                                       ref={ref}
                                />
                            </div>
                            {/* {
                                numberOption !== numberOptions.myNumber && (
                                    <div className="form-group col-sm-6">
                                        <label htmlFor="receivingMsisdn">Receiving Mobile
                                            Number<small>(required)</small></label>
                                        <input name="receivingMsisdn"
                                               className="form-control" type="text"
                                               required="required"
                                               placeholder="Enter Mobile Number (07********)"
                                               value={airtimeState.receivingMsisdn}
                                               onChange={e => setAirtimeState((s) => ({
                                                   ...s,
                                                   receivingMsisdn: e.target.value
                                               }))}
                                        />
                                    </div>
                                )
                            } */}
                            <div className="form-group col-sm-6">
                                <label htmlFor="currencyCode">Select
                                    Currency <small>(required)</small></label>
                                {/* <Select options={currencyCode}
                                        placeholder="ZWG/USD"
                                        clasName="form-control test-class"
                                        required="required"
                                        InputValue={airtimeState.currencyCode}
                                        onChange={e => setBundleCategory((s) => ({
                                            ...s,
                                            currencyCode: e.value
                                        }))}/> */}

                                            {/* <Select options={currencyCode}
                                                        placeholder="ZWG"
                                                        clasName="form-control test-class"
                                                        required="required"
                                                        InputValue={airtimeState.currencyCode}
                                                        onChange={e => setBundleCategory((s) => ({
                                                            ...s,
                                                            currencyCode: e.value
                                                        }))}/>  */}



                             {/* <Select options={currencyCode}
                                        placeholder="ZWG/USD"
                                        clasName="form-control test-class"
                                        required="required"
                                        InputValue={airtimeState.currencyCode}
                                        onChange={e => setBundleCategory((s) => ({
                                            ...s,
                                            currencyCode: e.value
                                        }))}/> */}

                                <select 

                                    InputValue={airtimeState.currencyCode}

                                    onChange={e => {setBundleCategory((s) => ({
                                        ...s,
                                        currencyCode: e.target.value
                                    }));
                                }}

                                            className="form-control"
                                            >
                                        <option>Select Currency</option>        
                                        <option key={'ZWG'} value={'ZWG'}>ZWG</option>
                                        <option key={'USD'} value={'USD'}>USD</option>

                                </select> 


                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="amount">Ecocash Pin<small>(required)</small></label>
                                <input name="pin"
                                       className="form-control" type="password"
                                       required="required"
                                       placeholder="Enter your Pin"
                                       value={airtimeState.mpin}
                                       onChange={e => setAirtimeState((s) => ({
                                           ...s,
                                           mpin: e.target.value
                                       }))}
                                />
                            </div>

                            

                            <div className="form-group col-sm-6">
                                <label htmlFor="payingMsisdn">Upload your file  with Numbers here<small>(required)</small></label>
                                <input 
                                       className="form-control" type="file"
                                       required="required"
                                       name="file"
                                       onClick={(e) => {onUpload(e);handleClickSetFocus();}}
                                       
                                       onChange={(e) => {changeHandlerBulkBundlesAirtime (e)}}
                                       accept=".csv"
                                    //    value={airtimeState.payingMsisdn}
                                    //    onChange={e => setAirtimeState((s) => ({
                                    //        ...s,
                                    //        payingMsisdn: e.target.value
                                    //    }))}
                                    // disabled
                                    // value={airtimeState.msisdn}
                                />
                            </div>

                            <div className="form-group col-sm-6">


                            <label htmlFor="payingMsisdn">Download Sample Excel file<small>(required)</small></label>
                            <CSVLink data={csvData} 
                             filename={"Bulk_Bundles_Sample_file.csv"}
                            className="btn btn-default">Download </CSVLink>;
                            </div>

                            <div>

                            <br />
                            <br />

                            {/* <table id="dtBasicExample" class="table table-striped table-bordered table-sm" cellspacing="0" width="100%">
                                <thead>
                                
                                <tr> */}
                                {/* {tableRowsBulkBundle.map((rows, index) => {
                                    return <th key={index}>{rows}</th>;
                                    })}
                                </tr>
                                </thead>
                                <tbody>
                                {valuesBulkBundle.map((value, index) => {
                                    return (
                                    <tr key={index}>
                                        {value.map((val, i) => {
                                        return <td key={i}>{val}</td>;
                                        })}
                                    </tr>
                                    );
                                })}
                                </tbody>
                                
                            </table> */}
                             {
                                showUpload ?
                                 <DataTable
                                 title="Receiving Numbers And Amount"
                                 columns={columns}
                                 data={parsedDataBulkBundle}
                                 pagination
                                 theme="solarized"
                             />
                                : <></>

                                }
                               
                                



                            {/* <MDBDataTable
                            paging={false}
                            data={data}
                            /> */} 
                            </div>



                            <div>

                            <br />
                            <br />

                            {/* <table id="dtBasicExample" class="table table-striped table-bordered table-sm" cellspacing="0" width="100%" ref={ref}>
                                <thead>
                                
                                <tr>
                                {responseBundleList.map((rows, index) => {
                                    return <th key={index}>{rows}</th>;
                                    })}
                                </tr>
                                </thead>
                                <tbody>
                                {responseBundleList.map((value, index) => {
                                    return (
                                    <tr key={index}>
                                        {value.map((val, i) => {
                                        return <td key={i}>{val}</td>;
                                        })}
                                    </tr>
                                    );
                                })}
                                </tbody>
                                
                            </table> */}

                                



                            {/* <MDBDataTable
                            paging={false}
                            data={data}
                            /> */} 
                            </div>

                            {/* <div className="form-group col-sm-6">
                                <label htmlFor="amount">Amount<small>(required)</small></label>
                                <input name="msisdn"
                                       className="form-control" type="text"
                                       required="required"
                                       placeholder="Enter Amount"
                                       value={airtimeState.amount}
                                       onChange={e => setAirtimeState((s) => ({
                                           ...s,
                                           amount: e.target.value
                                       }))}
                                />
                            </div> */}
                            <div>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </>
    );
}

// export default airtimeStatement;

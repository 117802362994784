import React, { Component,useRef } from 'react';
import "aos/dist/aos.css";
import { AuthService } from '../services/AuthService';
import { Link } from 'react-router-dom'
import vector from '../images/vector.png';
import { UserService } from '../services/UserService';
import { Toast } from 'primereact/toast';
import {Navigate, useNavigate} from 'react-router-dom';
import axios from 'axios'
import { SocialIcon } from 'react-social-icons';
import { ContactUs } from './facebookPixelEvent';
import UserAsidePane from './utils/UserAsidePane';
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import TeloneBroadbandUSD from './TeloneBroadbandUSD';
import imgAirtime from '../../src/zbczwl.png'
import imgBundles from '../../src/zbcusd.png'
import TeloneBroadbandZWL from './TeloneBroadbandZWL';
import ZbcUsdPackage from './ZbcUsdPackage';
import ZbcZwlPackage from './ZbcZwlPackage';
import MoovahInsurance from './MoovahInsurance';




export const  withNavigation = (Component) => {
    return props => <Component {...props} navigate={useNavigate()} />;

  } 


 
  
  const econetOptions = [{
      value: 'airtime', img: imgAirtime
  },{
      value: 'bundles', img: imgBundles
  }
  ]
        
  
class MoovahDevices extends Component{

    constructor(props) {
        super(props);
        this.state = {
            Categories:null,
            SelectedCategory:null,
            body:[],
            numberOptions:null,
            numberOption: 'myNumber',
            setNumberOption:null,
            BroadbandState:null,
            payingMsisdn:'',
            receivingMsisdn:'',
            mpin:'',
            currency: '',
            toggleState:false,




        };
        this.textInput = React.createRef();

    };
    


    componentDidMount() {


        this.setState({currency:localStorage.getItem("currency")});
        console.log(this.state.currency,'currencyontactus');


    }
handleClickSetFocus (){
        this.textInput.current.focus(null);
      };

handleDisplayPaymentConfirmation (){

}

      

    render(){

        
        return(
            <div>

                 <Toast ref={(el) => this.toast = el} />

                    {/* <section className="page-feature py-5">
                        <div className="container text-center">
                            <div className="row">
                                <div className="col-md-6">
                                    <h2 className=" text-left">Telone Broadband</h2>
                                </div>
                                <div className="col-md-6">
                                    <div className="breadcrumb text-right">
                                    <Link to='/'><a >Home</a></Link>
                                        <span>/ Telone BroadBand</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </section> */}

                    <div id="content" className="py-4">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-3">
                            <aside className="sidebar">
                                <UserAsidePane/>
                            </aside>

                            </div>

                            <div className="col-lg-9">


                                <div className="row">
                                    <div className="col-md-12 form-area pt-5">
                                    <img
                                    src={`${process.env.PUBLIC_URL}/images/moovah.png`}
                                    alt="Example"
                                    style={{ width: '225px', height: '225px' }}
                                    />
                                    {/* <FormLabel id="airtime-radio-buttons-group-label" className="text-4 mb-3"><h5
                                     className="text-4 mb-3">Select Currency</h5></FormLabel> */}
{/* 
                                     <div className="mb-3">
                                                        {econetOptions.map((c, i) => {
                                                            return (
                                                                <div className="custom-control custom-radio custom-control-inline"
                                                                     key={i}>
                                                                    <label>

                                                                        <input type="radio"
                                                                               name="biller"
                                                                               defaultChecked={c.value === econetOptions[0].value}
                                                                            //    onClick={(e) => {this.textInput.current.focus();}}
                                                                               value={c.value}
                                                                               onChange={(e) => {
                                                                                   e.target.value === 'airtime' ? this.setState({toggleState:false}) : this.setState({toggleState:true})
                                                                               }}
                                                                        />
                                                                        <div className="card align-items-center" style={{
                                                                            borderRadius: "15px",
                                                                            boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                                                                            width:"95%",
                                                                            height:"95%"
                                                                        }}>
                                                                            <div className="card-body ">
                                                                                <img src={c.img} alt={c.value}/>
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            )
                                                        })}
                                                    </div> */}

                                

                                {/* <div className='text-center'>
                                <FormLabel id="airtime-radio-buttons-group-label" className="text-4 mb-3"><h5
                                    className="text-4 mb-3">Choose Package </h5></FormLabel>
                                </div> */}


                                <div className='col-lg-11 col-md-9 m-auto text-' >
                                <MoovahInsurance/>
                                </div>
                                     </div>
                                </div>







                                </div>
                        

                            

                                             
 
            </div>
            </div>
            </div>
            </div>



        );

    }




}

export default withNavigation(MoovahDevices);
import React, { Component } from 'react';
import {Link , Navigate, useNavigate} from 'react-router-dom';


class WelcomeComponent extends Component {
    render() {
        return (
            <div>
                 <section class="page-feature py-5">
                    <div class="container text-center">
                        <div class="row">
                            <div class="col-md-6">
                                <h2 class=" text-left">Welcome</h2>
                            </div>
                            <div class="col-md-6">
                                <div class="breadcrumb text-right">
                                <Link to='/'><a >Home</a></Link>
                                    <span>/ Welcome</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="error-section">
                    <div class="container">
                        <div class="row">
                        <div class="col-lg-6 col-md-9 m-auto text-center">
                            <div class="sec-heading">
                            <h2 class="sec-title">Welcome to EcoCash Online Bill Payments</h2>
                            <p class="sec-subtitle">Your account has been created successfully. Sign in to save your favourite billers, make express payments, view transactions and many more...</p>
                            </div>
                            <Link to='/sign-in'><a class="btn btn-filled btn-round" style={{color:'#fff'}}><span class="bh"></span> <span>sign in</span></a></Link>
                        </div>
                        </div>
                    </div>
                    </section>
            </div>
        );
    }
}

export default WelcomeComponent;
import React, {Component, useContext, useEffect, useRef, useState,createContext,useReducer} from 'react';
import "aos/dist/aos.css";
import {Toast} from 'primereact/toast';
import axios from 'axios';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserAsidePane from './utils/UserAsidePane';
import UserProfileHeader from "./utils/UserProfileHeader";
import {Context} from "./Store";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import ConfirmationModal from "./ConfirmationModal";
import {BundleContext} from "../config/contexts";
import AirtimePurchase from './BulkAirtimePurchase';
import { CSVLink, CSVDownload } from "react-csv";
import Papa from 'papaparse';
import ReactPolling from "react-polling";
import {Dialog} from 'primereact/dialog';
import {ProgressSpinner} from 'primereact/progressspinner';
import { Modal, Button, Form } from "react-bootstrap";
//import {DataTable} from 'primereact/datatable';

import DataTable, { createTheme } from 'react-data-table-component';
import { BuyBulkAirtime } from './facebookPixelEvent';





const currencyCode = [
    {label: "ZWG", value: "ZWG"},
    {label: "USD", value: "USD"},
];

// createTheme('solarized', {
//     text: {
//       primary: '#268bd2',
//       secondary: '#2aa198',
//     },
//     background: {
//       default: '#FFFFFF',
//     },
//     context: {
//       background: '#FFFFFF',
//       text: '#FFFFFF',
//     },
//     divider: {
//       default: '#073642',
//     },
//     action: {
//       button: 'rgba(0,0,0,.54)',
//       hover: 'rgba(0,0,0,.08)',
//       disabled: 'rgba(0,0,0,.12)',
//     },
//   });
  







export default function ()  {
   // const [inputRef, setInputFocus] = useFocus();

    // const ref = useRef(null)

    // const handleClickSetFocus = () => {
    //     const el = document.getElementById('dtBasicExample');
    //     el.focus();
    //   };
    // const [inputRef, setInputFocus] = useFocus()

    //   const useFocus = () => {
    //     const htmlElRef = useRef(null)
    //     const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
    
    //     return [ htmlElRef, setFocus ] 
    // }
    const [showResults, setShowResults] = useState(false)
    


    const onBuyAirtime = (e) => setShowResults(true)

    const onUpload = (e) => setShowUpload(true);

    useEffect(() => {
        BuyBulkAirtime();
      },[]);
    

    

    const csvData = [
        ["PhoneNumber", "Amount"],
        ["07XXXXXXXX", "60"],
        ["07XXXXXXXX", "70"]
      ];

      const columns = [
        {
          name: 'PhoneNumber',
          selector: 'PhoneNumber',
          sortable: true,
        },
        {
          name: 'Amount',
          selector: 'Amount',
          sortable: true,
          right: true,
        },
      ];
      
                 // console.log(parsedData);
                //   status: 200,
                //   message: 'Successful Recharge', 
                //   responseCode: '000', 
                //   reference: 'CTR.190722.084717.904.ECO.WH5K'
                //   public String receiving_msisdn;

                const responseColumns = [
                    {
                      name: 'PhoneNumber',
                      selector: 'phoneNumber',
                      sortable: true,
                    },
                    {
                      name: 'message',
                      selector: 'message',
                      sortable: true,
                      right: true,
                    },

                    // {
                    //     name: 'responseCode',
                    //     selector: 'responseCode',
                    //     sortable: true,
                    //     right: true,
                    //   },

                      {
                        name: 'reference',
                        selector: 'reference',
                        sortable: true,
                        right: true,
                      },
                  ];


//   // State to store parsed data
//   const [parsedData, setParsedData] = useState([]);

//   //State to store table Column name
//   const [tableRows, setTableRows] = useState([]);

//   //State to store the values
//   const [values, setValues] = useState([]);


 




 

         


        //   values.map((values) => {
        //    // var pharmacyTotal = 0;
        //     var clientTotal = 0;
          
        //     for (let i = 0; i < values.length; i++) {
        //       //pharmacyTotal += values[i].pharmacy;
        //       clientTotal += values[i].amount;
        //       console.log(clientTotal);

        //     }})

          
        //    // console.log(tableRows);
        //    // console.log(values);

        // const tabdata = values.map((rows) => {
        //     return rows
        // })

                
            
          
        

     





    // const dataSet1 = [
    //     {
    //         name: "Johson",
    //         amount: 30000,
    //         sex: 'M',
    //         is_married: true
    //     },
    //     {
    //         name: "Monika",
    //         amount: 355000,
    //         sex: 'F',
    //         is_married: false
    //     }
    // ];

    const {  airtimeState, setAirtimeState, formSubmit, buttonHandler, setRenderComponent, changeHandlerBulkAirtime ,  parsedData, setParsedData, tableRows, setTableRows, values, setValues,recesponseDto, setrecesponseDto,ref,showUpload, setShowUpload} = useContext(BundleContext)
   
    return (


        <>
        
       <div className="row">
                <div className="m-auto">
                    <form  onSubmit={(e) => formSubmit(e)} method="POST">
                        <div className="row">
                            <div className="form-group col-sm-6">
                                <label htmlFor="payingMsisdn">My Mobile Number<small>(required)</small></label>
                                <input name="payingMsisdn"
                                       className="form-control" type="text"
                                       required="required"
                                       placeholder="Enter Mobile Number (07********)"
                                       value={airtimeState.payingMsisdn}
                                       onChange={e => setAirtimeState((s) => ({
                                           ...s,
                                           payingMsisdn: e.target.value
                                       }))}  
                                />
                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="currencyCode">Select
                                    Currency <small>(required)</small></label>
                                {/*<div className="col-md-12">*/}
                                {/* <Select options={currencyCode}
                                        placeholder="Select Currency"
                                        className="form-control test-class"
                                        required="required"
                                        InputValue={airtimeState.currencyCode}
                                        onChange={e => setAirtimeState((s) => ({
                                            ...s,
                                            currencyCode: e.value
                                        }))}/> */}
{/* 
                                <select 
                                    InputValue={airtimeState.currencyCode}

                                    onChange={e => {setAirtimeState((s) => ({
                                        ...s,
                                        currencyCode: e.target.value
                                            })); console.log(e)}}
                                            
                                            className="form-control"
                                            >
                                        <option>ZWG</option>
                                        {/* <option>USD</option>   */}


                                {/* <Select options={currencyCode}
                                                        placeholder="Select Currency"
                                                        className="form-control"
                                                        required="required"
                                                        InputValue={airtimeState.currencyCode}
                                                        onChange={e => setAirtimeState((s) => ({
                                                            ...s,
                                                            currencyCode: e.value
                                                        }))}
                                                        
                                                        /> */}


                                                    <select 

                                                    InputValue={airtimeState.currencyCode}

                                                    onChange={e => {setAirtimeState((s) => ({
                                                        ...s,
                                                        currencyCode: e.target.value
                                                    }));
                                                    }}

                                                            className="form-control"
                                                            >
                                                        <option>Select Currency</option>        
                                                        <option key={'ZWG'} value={'ZWG'}>ZWG</option>
                                                        <option key={'USD'} value={'USD'}>USD</option>

                                                    </select> 
                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="amount">Ecocash Pin<small>(required)</small></label>
                                <input name="pin"
                                       className="form-control" type="password"
                                       required="required"
                                       placeholder="Enter your Pin"
                                       value={airtimeState.mpin}
                                       onChange={e => setAirtimeState((s) => ({
                                           ...s,
                                           mpin: e.target.value
                                       }))}
                                />
                            </div>

                    


                            

                            <div className="form-group col-sm-6">
                                <label htmlFor="payingMsisdn">Upload your file  with Numbers here<small>(required)</small></label>
                                <input 
                                       className="form-control" type="file"
                                       required="required"
                                       name="file"
                                       onClick={(e) => {onUpload(e);}}
                                       onChange={(e) => {changeHandlerBulkAirtime (e)}}
                                       accept=".csv"
                                       ref={ref}
                                    //    value={airtimeState.payingMsisdn}
                                    //    onChange={e => setAirtimeState((s) => ({
                                    //        ...s,
                                    //        payingMsisdn: e.target.value
                                    //    }))}
                                    // disabled
                                    // value={airtimeState.msisdn}
                                />
                            </div>

                            <div className="form-group col-sm-6">


                            <label htmlFor="payingMsisdn">Download Sample Excel file<small>(required)</small></label>
                            <CSVLink data={csvData}  
                             filename={"Bulk_Airtime_Sample_File.csv"}
                            className="btn btn-default">Download </CSVLink>;
                            </div>


                            <div>

                                <br />
                                <br />
                                
                                {/* <table id="dtBasicExample" class="table table-striped table-bordered table-sm" cellspacing="0" width="100%">
                                    <thead>
                                    <tr>
                                        {tableRows.map((rows, index) => {
                                        return <th key={index}>{rows}</th>;
                                        })}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {values.map((value, index) => {
                                        return (
                                        <tr key={index}>
                                            {value.map((val, i) => {
                                            return <td key={i}>{val}</td>;
                                            })}
                                        </tr>
                                        );
                                    })
                                    }
                                   
                                    </tbody>
                                    
                                </table> */}

                            

                                {
                                showUpload ?
                                 <DataTable
                                 title="Receiving Numbers And Amount"
                                 columns={columns}
                                 data={parsedData}
                                 pagination
                                 theme="solarized"
                                 
                             />
                             
                                : <></>

                                }

                              <br />
                            <button type="button" onClick={(e) => {buttonHandler(e); setRenderComponent(true);onBuyAirtime(e); }}
                            className="btn btn-default"  ref={ref}>{airtimeState.buttonText}</button>

                                <br />
                                <br />
                                {
                                 showResults ?
                                    <DataTable
                                    title="Report of  Successful Recharge"
                                    columns={responseColumns}
                                    data={recesponseDto}
                                    pagination
                                    theme="solarized"
                                />
                                : <></>

                                }

                                 <br />
                                <br /> 
                                { 
                                    showResults ?
                                    <CSVLink data={recesponseDto} 
                                    filename={"Bulk_Airtime_Recharge_Report.csv"}
                                     className="btn btn-default">Download </CSVLink>
                                : <></>

                                }
                                

                                

                            {/* <MDBDataTable
                                paging={false}
                                data={data}
                                /> */} 
                            </div>
                      </div>
                   </form>
                </div>
           </div>
           </>
    )
}

//export default BulkAirtime;


import React, { Component} from 'react';
import OnlinePayements from './OnlinePayements';

export default class Insurance extends Component {

    render(){ return (
        <div>
            <OnlinePayements category={process.env.REACT_APP_LIFE_INSURANCE_ID}/>
        </div>
    )
   }
    
};

import axios from 'axios';

export class BillerTransactionService {

    getAccountTransactions(token, msisdn){
        return  axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/biller-transaction/find-by-msisdn/${msisdn}`, {
             headers: {
                 'Authorization': `Bearer ${token}`
             }
         })
     }

}
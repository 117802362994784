import React, {useContext, useEffect, useLayoutEffect, useRef} from "react";
import {Carousel} from "react-bootstrap";
import Bundles from "./Bundles";
import BundleCategory from "./BundleCategory";
import {BundleCategoryContext, BundleContext} from "../config/contexts";

const BundlesCarousel = () => {
    const {setRenderComponent, airtimeState, carousel} = useContext(BundleContext)
    const {handleBundleCategory, carouselIndex, setCarouselIndex, handleBundleConfirmation, proceed} = useContext(BundleCategoryContext)

    useEffect(() => {
        proceed && carousel.current.next();
    }, [proceed])

    return(
        <div className='row'>
            <Carousel
                className='mb-6'
                interval={null}
                indicators={false}
                controls={false}
                ref={carousel}
                onSlid={(i, e) => setCarouselIndex(i)}
            >
                <Carousel.Item className='p-sm-2 mb-5 pb-5'>
                    <Bundles />
                </Carousel.Item>
                <Carousel.Item>
                    <BundleCategory />
                </Carousel.Item>
            </Carousel>
            <div className='justify-content-between'>
                {carouselIndex === 0 ? (
                        <button type="submit" onClick={(e) => { handleBundleCategory(e);}} className="btn btn-default">{'Next'}</button>
                ) : (
                    <>
                        <button type="submit" onClick={() => carousel.current.prev()}
                                className="btn btn-default" style={{width:'200px'}}>{'Prev'}</button>
                                &nbsp;
                                &nbsp;

                        <button type="submit" onClick={(e) => {handleBundleConfirmation(e); setRenderComponent(false)}}
                                className="btn btn-default" style={{width:'200px'}}>{airtimeState.buttonText === 'Buy Airtime' ? 'Buy Bundle' : airtimeState.buttonText}</button></>
                    )}
            </div>
        </div>
    )
}

export default BundlesCarousel;
import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation,
    Navigate,
    useNavigate
  } from "react-router-dom";


import { SubscriberAccountsService } from '../services/SubscriberAccountsService';
import ecocash from '../images/ecocash-white-logo.png';
import UserProfileHeader from './utils/UserProfileHeader';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import ReactPolling from "react-polling";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { PaymentService } from '../services/PaymentService';
import { Message } from 'primereact/message';
import { BillerCategoryService } from '../services/BillerCategoryService';
import { BillerService } from '../services/BillerService';
import UserAsidePane from './utils/UserAsidePane';
import TransactionDetails from './TransactionDetails';
import { Checkbox } from 'primereact/checkbox';

import {AuthService} from '../services/AuthService';
import { DashBoard } from './facebookPixelEvent';


class UserDashboard extends Component {
    constructor(props){
        super(props);
        this.state={
            savedBillers:[],
            displayXpressPyamentDialog:false,
            displayRecurringPaymentDialog:false,
            position: 'center',
            selectedBiller:null,
            amount:'',
            sourceReference:'',
            isProcessingPayment:false,
            displayNewAccounttDialog:false,
            billerCategories:[],
            selectedBillerCategory:null,
            displayableBillers:[],
            activeAddRecurringBillerAccountTabIndex:0,
            billers:[],
            sBiller:null,
            rMsisdn:'',
            rAmount:0,
            rPin:'',
            token:'',
            displayDisableRecurringPaymentDialog:false,
            isProcessing:false,
            isShowingPaymentTransactionDetailDialog:false,
            transactionDetails:null,
            checked:false,
            isShowingTermsAndConditionsDialog:false,
            guestUserToken:null
            //isRecurringPaymentEnabled:true,
           
            

            


        };

        this.subscriberService = new SubscriberAccountsService();
        this.billerCategory = new BillerCategoryService();
        this.paymentService = new PaymentService();
        this.billerService = new BillerService();
        this.authService = new AuthService();
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
        this.paymentConfirmation = this.paymentConfirmation.bind(this);
        this.accept = this.accept.bind(this);
        this.reject = this.reject.bind(this);
        this.confirmMarkAsRecurring = this.confirmMarkAsRecurring.bind(this);
        this.disableRecurring = this.disableRecurring.bind(this);
        this.disableRecurringConfirmation = this.disableRecurringConfirmation.bind(this);

    }

    // const tabdata = values.map((rows) => {
    //     return rows
    // })


   

   

    onClick(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }

        this.setState(state);
    }

    onHide(name) {
        this.setState({
            [`${name}`]: false,
        });
    }

    renderFooter(name) {
        return (
            <div>
                <Button label="Close" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-text" />
                {/* <Button label="Yes" icon="pi pi-check" onClick={() => this.onHide(name)} autoFocus /> */}
            </div>
        );
    }

    paymentConfirmation(event){
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            accept: this.accept,
            reject: this.reject
        });
    } 

    accept(){
        //this.setState({isProcessingPayment:true});
         let paymentDto = {
                endUserId: this.state.selectedBiller.subscriber.msisdn,
                billerCode: this.state.selectedBiller.biller.code,
                billerName: this.state.selectedBiller.biller.name,
                currency: this.state.selectedBiller.currency,
                amount: this.state.amount,
                accountNumber: this.state.selectedBiller.accountNumber,
                accountName: this.state.selectedBiller.subscriber.accountName,
                isPinless: false,
              };

               //console.log(paymentDto);

            var token = localStorage.getItem('token');
            
            this.paymentService.makePayment(token, paymentDto)
            .then(response => {
                this.setState({sourceReference:response.data.body.field3, isProcessingPayment:true});
                // this.toast.show({ severity: 'info', summary: 'Transaction success', detail: 'Your payment was successful', life: 3000 });

            })
            .catch(error => {
               // console.log(error)
                 this.toast.show({ severity: 'error', summary: 'Transaction failed', detail: 'Payment was not successful. Please try again', life: 3000 });

            });
    };

    reject(){
        // this.toast.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };


    confirmMarkAsRecurring(){
        let recurringPaymentDto = {
            msisdn: this.state.rMsisdn,
            billerCode: this.state.selectedBiller.biller.code,
            amount: this.state.rAmount,
            accountNumber: this.state.selectedBiller.accountNumber,
            pin:this.state.rPin
            
        };


        this.setState({isProcessing:true});
        this.subscriberService.markAsRecurringPayment(this.state.token, recurringPaymentDto)
        .then(response =>{
           // console.log(response);
            this.toast.show({ severity: 'info', summary: 'Success', detail: 'Your Account has been set as a recurring payment', life: 6000 })
            this.setState({displayRecurringPaymentDialog:false, isProcessing:true});

        })
        .catch(error =>{
            this.setState({isProcessing:false});
            this.toast.show({ severity: 'error', summary: 'Transaction failed', detail: 'Failed to set the account as a recurring payment. Please try again', life: 3000 })
        })

    }

    disableRecurring(){
        let recurringPaymentDto = {
            msisdn: this.state.rMsisdn,
            billerCode: this.state.selectedBiller.biller.code,
            amount: this.state.rAmount,
            accountNumber: this.state.selectedBiller.accountNumber,
            pin:this.state.rPin
        };
        this.setState({isProcessing:true});

        this.subscriberService.disableRecurringPayment(this.state.token, recurringPaymentDto)
        .then(response =>{
            this.setState({isProcessing:false})
            this.toast.show({ severity: 'info', summary: 'Success', detail: 'Your Account has been disabled', life: 6000 })

        })
        .catch(error =>{
            this.setState({isProcessing:false});
            this.toast.show({ severity: 'error', summary: 'Transaction failed', detail: 'Failed to disable recurring payment. Please try again', life: 3000 })
        })

    }

    disableRecurringConfirmation(event){
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            accept: this.disableRecurring,
            reject: this.reject
        });
    }


    componentDidMount(){
        var user = JSON.parse(localStorage.getItem('user'));
        var token = localStorage.getItem('token');
       // console.log('log',token);
        this.setState({token:token});
        // this.authService.getToken().then(
        // resp=>this.setState({ token: resp.data.body},()=>{
            // console.log(token); //this.state.token
        this.subscriberService.getSubscriberAccounts(token, user.msisdn)
            .then(response => {

                DashBoard();


                 //console.log(response.data.body);
    
        
        
                this.setState({savedBillers:response.data.body});
                
        

               
            })
            .catch(error =>{
                // this.toast.show({ severity: 'error', summary: 'Error get saved accounts', detail: 'There was an error getting the saved billers. Please try again later', life: 3000 });
            });

            // const test  =   this.state.savedBillers.map(task => {
            //     return task; 
            //   }
            //   );

            //   console.log(test);


            // const data = this.state.savedBillers.map((rows) => {
            //     return rows.map((r) => {
            //         return r.map((r2) => {
            //             return r2.biller;
            //             });
            //         });
            //     })

            //     console.log(data);


            this.authService.getToken().then(
                response => this.setState({guestUserToken: response.data.body}, () => {

                    this.billerService.getAllBillers(this.state.guestUserToken)
                    .then(res => {
                       // console.log(res.data.body);
                        this.setState(
                            {
                                billers:res.data.body,
                                sBiller : res.data.body[0]
                            });
                            
                        }
                        )
                    .catch(error => 
                        
                        console.log(error)
                        
                        );



                }));

           
        
             

           
  

           
        // this.billerService.getAllBillers(this.state.token)
        // .then(res => {
        //     // console.log(res.data.body);
        //     this.setState(
        //         {
        //             billers:res.data.body,
        //             sBiller : res.data.body[0]
        //         });
                
        //     }
        //     )
        // .catch(error => console.log(error));


       // }))
    }
    render() {
        const user = localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')): null;
        return (
            <div>
                <Toast ref={(el) => this.toast = el} />

                <UserProfileHeader/>
                <div id="content" className="py-4">
                    <div className="container">
                        <div className="row">

                            {/* <!-- Left sidebar --> */}
                            <aside className="col-lg-3 sidebar">
                                <UserAsidePane/>
                            </aside>
                                {/* <!-- Left Panel End --> */}

                            {/* <!-- Middle Panel  --> */}
                            <div className="col-lg-9">
                                <h2 className="admin-heading">{ `${user.username} saved billers`} <Link to="/add-biller" className="account-card-new d-flex align-items-center  btn-link pbtn"> New biller</Link></h2>
                                {/* <!-- Credit or Debit Cards  --> */}
                                <div className="infoItems bg-offwhite  shadow">
                                    <div className="row">
                                        <div className="col-12">
                                                <h3>{`Saved billers (${this.state.savedBillers.length})` }</h3>
                                        </div>
                                        {
                                            this.state.savedBillers.map(biller =>(
                                                <div className="col-12 col-sm-6 col-lg-4 cardBox" key={biller.id}>                             
                                                    <div className="account-card account-card-primary">
                                                        {
                                                            biller.biller.name != 'ZESA Prepaid' ?
                                                             <p className="pirotry text-right">{biller.isRecurringPaymentEnabled ? 'Recurring': 'Non recurring'}</p> 
                                                             :   <></> 
                                                        }
                                                       
                                                        <div className="row">
                                                            <p className="card-number col-12">{biller.accountNumber}</p>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <p className="card-holder-name">{biller.biller.name} : {biller.subscriber.accountName}</p>
                                                            </div>
                                                          
                                                        </div>      
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="card-holder-name">&nbsp;</p> 
                                                            </div>
                                                            <div className="col-6">
                                                                <img className="ml-auto" src={ecocash} alt="ecocash" title=""/>
                                                            </div>
                                                        </div>
                                                        <div className="account-card-overlay"> 
                                                            <a   className="text-light btn-link pbtn" data-id="pay-card-details" 
                                                            onClick={ () => this.setState({selectedBiller : biller, displayXpressPyamentDialog:true})}> <span className="mr-1"><i className="fas fa-credit-card"></i></span>Pay</a> 
                                                           &nbsp; &nbsp;


                                                           


                                                                    {

                                                                     biller.biller.acceptsRecurringPayments ?

                                                                        biller.isRecurringPaymentEnabled ? 
                                                                                    <a  data-target="#edit-card-details"className="text-light btn-link pbtn" data-id="edit-card-details"
                                                                                    onClick={ (e) => {
                                                                                        this.setState({selectedBiller : biller, displayDisableRecurringPaymentDialog:true }); 
                                                                                        // this.disableRecurringConfirmation(e);
                                                                                    }}> <span className="mr-1"><i className="fas fa-times" aria-hidden="true"></i></span>Disable recurring</a> 
                                                                        :
                                                                                    <a  data-target="#edit-card-details"className="text-light btn-link pbtn" data-id="edit-card-details"
                                                                                    onClick={ () => this.setState({selectedBiller : biller,displayRecurringPaymentDialog:true})}> <span className="mr-1"><i className="fas fa-undo" aria-hidden="true">Mark as recurring</i></span></a>
                                                                    :   <></>          
                                                                        
                                                                    }

                                                           
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        
                                    </div>
                                    <div className='row'>
                                        <div className="col-12 col-sm-6 col-lg-4 cardBox" >
                                                <div className="account-card account-card-primary text-center p-4">
                                                    <Link to='/add-biller' className="account-card-new d-flex align-items-center  btn-link pbtn">
                                                        {/* <a href='/add-biller' data-target="#add-new-card"  className="account-card-new d-flex align-items-center  btn-link pbtn" data-id="add-new-card" > */}
                                                            <p className="text-center">
                                                                <span className="text-3"><i className="fas fa-plus-circle"></i></span> 
                                                                <span className="d-block">Add New Biller</span>
                                                            </p>
                                                        {/* </a> */}
                                                    </Link>
                                                </div>                                
                                            </div>
                                    </div>
                                </div>

                            </div>
                            {/* <!-- Middle Panel End --> */}

                        </div>
                    </div>
                </div>
                <Dialog header={this.state.selectedBiller  != null ? `Making a payment to ${this.state.selectedBiller.biller.name} `: ''}  visible={this.state.displayXpressPyamentDialog} position={this.state.position} modal style={{ width: '30%', height:'70%' }} footer={this.renderFooter('displayXpressPyamentDialog')} onHide={() => this.onHide('displayXpressPyamentDialog')} breakpoints={{'960px': '75vw'}}>
                                      
                                        <div className="form-group">
                                                    <label htmlFor="biller">Biller</label>
                                                    <input type="text" className="form-control" data-bv-field="biller" id="biller"  value={this.state.selectedBiller != null ? `${this.state.selectedBiller.biller.name} (${this.state.selectedBiller.biller.code})` : ''} readOnly/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="accountName">Account Name</label>
                                                    <input type="text" className="form-control" data-bv-field="accountName" id="accountName"  value={this.state.selectedBiller != null ? this.state.selectedBiller.subscriber.accountName : ''} readOnly/>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="accountNumber">Account Number</label>
                                                    <input type="text" className="form-control" data-bv-field="accountNumber" id="accountNumber"  value={this.state.selectedBiller != null ? this.state.selectedBiller.accountNumber: ''} readOnly/>                                            </div>
                                                    <div className="form-group">
                                                    <label htmlFor="currency">Account Currency</label>
                                                    <input type="text" className="form-control" data-bv-field="currency" id="currency"  value={this.state.selectedBiller != null ? this.state.selectedBiller.currency: ''} readOnly/></div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="amount">Amount</label>
                                                    <input id="amount" type="number" className="form-control" data-bv-field="amount" required onChange={(e) => {
                                                                                                                        e.persist();
                                                                                                                        this.setState((state) => (state.amount= e.target.value))
                                                                                                                        }}/>
                                                    <Message severity="error" text="Amount is required" />
                                                                                                                                                                        
                                                </div>
                                            </div>
                                
                                        </div>
                                        <button className="btn btn-default btn-center btn-block" onClick={this.paymentConfirmation}>
                                            <span className="bh"></span>
                                            <span>Make Payment</span>
                                        </button>
                </Dialog>

                <Dialog header= { <h3 class="admin-heading bg-offwhite">
                                            <p>Transaction processing</p>
                                            <span>Enter your ecocash pin on your phone...</span>
                                        </h3>
                                    }
                        visible={this.state.isProcessingPayment} style={{ width: '20vw' }}

                        onHide={() => this.onHide('isProcessingPayment')} breakpoints={{'960px': '75vw'}}>
                                <ReactPolling
                                    url={`${process.env.REACT_APP_BACKEND_HOST_URL}/biller-transaction/find-by-source-ref/${this.state.sourceReference}`}
                                    interval={3000} // in milliseconds(ms)
                                    retryCount={3} // this is optional
                                    headers={{
                                        //"Content-Type": "application/json",
                                        'Authorization': `Bearer ${this.state.token}`,
                                    }}
                                    onSuccess={(data) => {
                                        console.log({ data });
                                        if (data.status == "SUCCESS") {
                                            // this.showSticky('success', 'completed successfully');
                                            this.toast.show({ severity: 'info', summary: 'Payment success', detail: 'Your payment was successful', life: 5000 })

                                            this.setState({
                                                isProcessingPayment:false, 
                                                isShowingPaymentTransactionDetailDialog:true,
                                                transactionDetails:data});
                                            
                                            
                                            return false;
                                        } else if (data.status == "FAILED") {
                                            // this.showSticky('error', " failed. Please try again later");
                                            this.toast.show({ severity: 'error', summary: 'Transaction failed', detail: 'Your transaction failed. Please try again later', life: 5000 })

                                            this.setState({isProcessingPayment:false});
                                            return false;
                                        }
                                        return true;
                                    }}
                                    onFailure={() => console.log("handle failure")} // this is optional
                                    render={({ startPolling, stopPolling, isPolling }) => {
                                        if (isPolling) {
                                        return (
                                            <div className="" style={{align:"centre"}}>
                                                <ProgressSpinner />

                                            </div>
                                        );
                                        } else {
                                            //this.setState({isProcessingPayment:false})
                                        return <></>;
                                        }
                                    }}
                            />

                </Dialog>

                <Dialog header='Account Details'  visible={this.state.displayRecurringPaymentDialog} position={this.state.position} modal style={{ width: '40%', height:'90%' }} footer={this.renderFooter('displayRecurringPaymentDialog')} onHide={() => this.onHide('displayRecurringPaymentDialog')} breakpoints={{'960px': '75vw'}}>
                    <hr/>
                   
                                           <br/>
                                                <div class="form-group">
                                                    <label htmlFor="rAccountName">Account Name</label>
                                                    <input type="text" value={this.state.selectedBiller == null ? '' :this.state.selectedBiller.subscriber.accountName} className="form-control form-control-lg" id="rAccountName" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}}  readOnly/>
                                                </div>
                                                
                                                <br/>
                                                <div class="form-group">
                                                    <label htmlFor="rAccounNumber">Account Number</label>
                                                    <input type="text" value={this.state.selectedBiller == null ? '' :this.state.selectedBiller.accountNumber}  className="form-control form-control-lg" id="rAccounNumber" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}}  readOnly/>
                                                </div>

                                              
                                                <br/>
                                                <div class="form-group">
                                                    <label htmlFor="rBillerName">Biller Name</label>
                                                    <input type="text" value={this.state.selectedBiller == null ? '' :this.state.selectedBiller.biller.name}  className="form-control form-control-lg" id="rBillerName" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}}  readOnly/>
                                                </div>

                                                
                                                <br/>
                                                <div class="form-group">
                                                    <label htmlFor="rBillerCode">Biller Code</label>
                                                    <input type="text" value={this.state.selectedBiller == null ? '' :this.state.selectedBiller.biller.code}  className="form-control form-control-lg" id="rBillerCode" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}}  readOnly/>
                                                </div>
                                                <br/>

                                                <div class="form-group">
                                                    <label htmlFor="rMsisdn">Paying Mobile Number</label>
                                                    <input type="text"   className="form-control form-control-lg" id="rMsisdn" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}} onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.rMsisdn= e.target.value))
                                                                                                                }}/>
                                                    <Message severity="error" text="Mobile number is required" />

                                                </div>
                                                <br/>
                                                <div class="form-group">
                                                    <label htmlFor="rAmount">Recurring Amount</label>
                                                    <input type="text"  className="form-control form-control-lg" id="rAmount" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}} onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.rAmount= e.target.value))
                                                                                                                }}/>
                                                    <Message severity="error" text="Amount is required" />

                                                </div>
                                                <br/>
                                                <div class="form-group">
                                                    <label htmlFor="rPin">Ecocash Pin</label>
                                                    <input type="text"  className="form-control form-control-lg" id="rPin" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden',}}  onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.rPin= e.target.value))
                                                                                                                }}/>
                                                    <Message severity="error" text="Ecocash pin is required" />

                                                </div>

                                            <div className='row' style={{marginTop:'20px'}}>
                                                    <div className='col-sm-3'>
                                                        <Button label={ this.state.isProcessing ? 'Processing...' : 'Save'} className="btn btn-default btn-center"  onClick={this.confirmMarkAsRecurring}/>
                                                    </div>
                                            </div>
                </Dialog>

                <Dialog header='Account Details'  visible={this.state.displayDisableRecurringPaymentDialog} position={this.state.position} modal style={{ width: '40%', height:'90%' }} footer={this.renderFooter('displayDisableRecurringPaymentDialog')} onHide={() => this.onHide('displayDisableRecurringPaymentDialog')} breakpoints={{'960px': '75vw'}}>
                    <hr/>
                   
                                           <br/>
                                                <div class="form-group">
                                                    <label htmlFor="rAccountName">Account Name</label>
                                                    <input type="text" value={this.state.selectedBiller == null ? '' :this.state.selectedBiller.subscriber.accountName} className="form-control form-control-lg" id="rAccountName" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}}  readOnly/>
                                                </div>
                                                
                                                <br/>
                                                <div class="form-group">
                                                    <label htmlFor="rAccounNumber">Account Number</label>
                                                    <input type="text" value={this.state.selectedBiller == null ? '' :this.state.selectedBiller.accountNumber}  className="form-control form-control-lg" id="rAccounNumber" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}}  readOnly/>
                                                </div>

                                              
                                                <br/>
                                                <div class="form-group">
                                                    <label htmlFor="rBillerName">Biller Name</label>
                                                    <input type="text" value={this.state.selectedBiller == null ? '' :this.state.selectedBiller.biller.name}  className="form-control form-control-lg" id="rBillerName" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}}  readOnly/>
                                                </div>

                                                
                                                <br/>
                                                <div class="form-group">
                                                    <label htmlFor="rBillerCode">Biller Code</label>
                                                    <input type="text" value={this.state.selectedBiller == null ? '' :this.state.selectedBiller.biller.code}  className="form-control form-control-lg" id="rBillerCode" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}}  readOnly/>
                                                </div>
                                                <br/>

                                                <div class="form-group">
                                                    <label htmlFor="rMsisdn">Paying Mobile Number</label>
                                                    <input type="text"   className="form-control form-control-lg" id="rMsisdn" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}} onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.rMsisdn= e.target.value))
                                                                                                                }}/>
                                                    <Message severity="error" text="Mobile number is required" />

                                                </div>
                                                <br/>
                                              
                                                <div class="form-group">
                                                    <label htmlFor="rPin">Ecocash Pin</label>
                                                    <input type="text"  className="form-control form-control-lg" id="rPin" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden',}}  onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.rPin= e.target.value))
                                                                                                                }}/>
                                                    <Message severity="error" text="Ecocash pin is required" />

                                                </div>

                                            <div className='row' style={{marginTop:'20px'}}>
                                                    <div className='col-sm-3'>
                                                        <Button  label={ this.state.isProcessing ? 'Processing...' : 'Save'} className="btn btn-default btn-center"  onClick={this.disableRecurring}/>
                                                    </div>
                                            </div>
                </Dialog>

                <Dialog   header= { <h3 class="admin-heading bg-offwhite">
                                            <p>Transaction Details</p>
                                            <span>Payment details made</span>
                                        </h3>
                                    }
                             visible={this.state.isShowingPaymentTransactionDetailDialog} position={this.state.position} modal style={{ width: '70%', height:'70%' }} footer={this.renderFooter('isShowingPaymentTransactionDetailDialog')} onHide={() => this.onHide('isShowingPaymentTransactionDetailDialog')} breakpoints={{'960px': '75vw'}}>
                            <TransactionDetails tDetails={this.state.transactionDetails} biller = {this.state.selectedBiller != null ? this.state.selectedBiller.biller : this.state.selectedBiller} />
                </Dialog>

                
            </div>
        );
    }
}

export default UserDashboard;
import React, { Component, useContext } from 'react';
import { Context } from "../Store";

function UserProfileHeader() {
    // render() {
        // const user = localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')): null;
        const [state, dispatch] = useContext(Context);
        let user = JSON.parse(state.user);

        var today = new Date();
        var curTime = today.getHours() + ':' + today.getMinutes();
     
        return (
            <div>
                { 
                    
                    (state.user != null) ? 
                        <div className="profilebar shadow mt-2 border-2">
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <div className="balance-area">
                                            <a href="#">
                                                <div className="header-pro-thumb">
                                                    <img className="rounded-circle" src="images/profile.jpg" alt=""/>
                                                </div> 
                                                <span className="pl-4 pt-1"><b>{ user.username} </b></span>
                                            </a>
                                        

                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="local-time">
                                            <p><b>Local Time:</b> { curTime }</p>
                                        </div>
                                    </div>
                                   
                                    <div className="col notify-col text-right">
                                        <div className="notify-btn"><a href="#"><i className="far fa-bell"></i></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    :<></>
                }
            </div>
        );
    // }
}

export default UserProfileHeader;
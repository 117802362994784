import React, {useRef, useState} from 'react';
import {Toast} from "primereact/toast";
import ConfirmationModal from "./ConfirmationModal";
import UserProfileHeader from "./utils/UserProfileHeader";
import UserAsidePane from "./utils/UserAsidePane";
import Switch from "react-switch";
import axios from "axios";
import Airtime from "./Airtime";
import Bundles from "./Bundles";
import BundlesCarousel from "./BundlesCarousel";
import {BundleContext} from "../config/contexts";
import BundlesHome from "./BundlesHome";
import {useNavigate} from "react-router-dom";
import AirtimeBundleSwitch from "./utils/AirtimeBundleSwitch";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import ReactPolling from "react-polling";
import {Dialog} from 'primereact/dialog';
import {ProgressSpinner} from 'primereact/progressspinner';
import { Modal, Button, Form } from "react-bootstrap";
import { AuthService } from '../services/AuthService';


import imgAirtime from '../../src/buyairtime.png'
import imgBundles from '../../src/buybundles.png'

const astate = {
    msisdn: '',
    payingMsisdn: '',
    receivingMsisdn: '',
    currencyCode: '',
    pricePlanCode: 'VOICE',
    amount: '',
    source: 'WEB',
    mpin: '',
    buttonText: 'Buy Airtime',
};

const aaData = {
    payingMsisdn: '',
    receivingMsisdn: '',
    currencyCode: '',
    pricePlanCode: '',
    amount: 0,
    source: '',
    mpin: '',
}

const numberOptions = {myNumber: 'My number', otherNumber: 'Other Mobile'}


const AirtimeBundles = () => {

    const [airtimeState, setAirtimeState] = useState(astate);
    const [numberOption, setNumberOption] = useState(numberOptions.myNumber);
    const [isPaymentConfirmation, setIsPaymentConfirmation] = useState(false);
    const [responseBundleCategory, setResponseBundleCategory] = useState([])
    const [renderComponent, setRenderComponent] = useState(true)
    const [selectedBundleCategory, setSelectedBundleCategory] = useState({})
    const toast = useRef(null);
    const carousel = useRef(null);
    const authService = new AuthService();
    //const [sourceReference, setSourceReference] = useState(null);


    const [isProcessingPayment, setProcessingPayment] = useState(false);


    const [toggleState, setToggleState] = useState(false)
    const [ token, setToken ] = useState('');

    const data = {
        payingMsisdn: airtimeState.payingMsisdn,
        receivingMsisdn: numberOption === numberOptions.myNumber ? airtimeState.payingMsisdn : airtimeState.receivingMsisdn,
        currencyCode: airtimeState.currencyCode,
        pricePlanCode: 'VOICE',
        amount: airtimeState.amount,
        source: 'WEB',
        mpin: airtimeState.mpin
    }
    const bundleD = {
        ...selectedBundleCategory,
        mpin: airtimeState.mpin
        
    }

    const econetOptions = [{
        value: 'airtime', img: imgAirtime
    },{
        value: 'bundles', img: imgBundles
    }
    ]

    const buttonHandler = async (e) => {
        e.preventDefault()

        if (!airtimeState.payingMsisdn) {
            show({
                severity: 'error',
                summary: 'INPUT MY NUMBER',
                detail: 'Kindly input paying number!',
                life: 3000
            });
        } else if (!airtimeState.receivingMsisdn && numberOption !== numberOptions.myNumber) {
            show({
                severity: 'error',
                summary: 'INPUT OTHER NUMBER',
                detail: 'Kindly input receiving number!',
                life: 3000
            });
        } else if (!airtimeState.currencyCode) {
            show({
                severity: 'error',
                summary: 'Select currency',
                detail: 'Kindly choose the currency option',
                life: 3000
            });
        } else if (!airtimeState.amount) {
            show({
                severity: 'error',
                summary: 'INPUT AMOUNT',
                detail: 'Enter Airtime Amount!',
                life: 3000
            });
        } else {
            setIsPaymentConfirmation(true)
        }
    }

    const formSubmit = async (e) => {
        e.preventDefault()

       
            setAirtimeState((s) => ({...s, buttonText: renderComponent ? '...buying airtime' : '...buying bundles'}))
            const airtimeUrl = `${process.env.REACT_APP_BACKEND_HOST_URL}/bundles/purchase/airtime/v3`;
            const bundleUrl = `${process.env.REACT_APP_BACKEND_HOST_URL}/bundles/purchase/bundle/v3`;

            authService.getToken().then((response)=>{

            axios.post(renderComponent ? airtimeUrl : bundleUrl, renderComponent ? data : bundleD,
                {
                    headers: {
                        'Authorization': `Bearer ${response.data.body}`
                    }
                    }
                )
                .then((res) => {
                   if(res.data.body.responseCode == '000'){
                        //setSourceReference(res.data.body.referenceNumber);
                        //setProcessingPayment(true);

                        show({
                            severity: 'success',
                            summary: 'Payment success',
                            detail: `${res.data.body.message}`,
                            life: 5000
                        });
                        resetForm();
                   } else{
                     if(res.data.body.responseCode == '099'){
                        //setSourceReference(res.data.body.referenceNumber);
                        //setProcessingPayment(true);

                        show({
                            severity: 'error',
                            summary: 'Payment was not successfull ',
                            detail: `${res.data.body.message}, Please check if your information is correct`,
                            life: 5000
                        });
                        
                   }



                   }

                })
                .catch((res) => {
                    showError(res);
                    console.log(res);
                    show({
                        severity: 'error',
                        summary: 'Payment failed',
                        detail: `${res.data.body.message}`,
                        life: 5000
                    });
                    resetForm();
                })
           // })

        });
    
    }
    const showError = (e) => {
        show({

            severity: 'error',
            summary: 'Failed',
            detail: renderComponent ? 'Airtime' : 'Bundle' + ' could not be credited!',
            life: 3000
        })
        resetForm();
    }

    const show = (settings) => {
        toast.current.show(settings);
    }

   

    const resetForm = (buttonText = 'Buy Airtime') => {
        setAirtimeState({
            msisdn: '',
            payingMsisdn: '',
            receivingMsisdn: '',
            currencyCode: '',
            pricePlanCode: 'VOICE',
            amount: '',
            source: 'WEB',
            mpin: '',
            buttonText: 'Buy Airtime',

        })
        setSelectedBundleCategory({})
        setIsPaymentConfirmation(false)
    }

    const handlePaymentConfirmation = (value) => {
        if (!airtimeState.mpin) {
            setIsPaymentConfirmation(true)
        } else {
            setIsPaymentConfirmation(false)
        }
    //   setIsPaymentConfirmation(true)

        formSubmit(value)
    }

    return (
        <BundleContext.Provider value={{
            airtimeState, setAirtimeState,
            numberOption, setNumberOption, selectedBundleCategory, setSelectedBundleCategory,
            isPaymentConfirmation, setIsPaymentConfirmation, data, carousel,
            responseBundleCategory, setResponseBundleCategory, renderComponent, setRenderComponent,
            toast, numberOptions, buttonHandler, formSubmit, show, resetForm, handlePaymentConfirmation
        }}>
            <div>
                <Toast ref={toast}/>
                <ConfirmationModal
                    show={isPaymentConfirmation}
                    toggle={setIsPaymentConfirmation}
                    processPayment={handlePaymentConfirmation}
                    setPin={setAirtimeState}
                    data={data}
                    bundleData={selectedBundleCategory}
                    renderComponent={renderComponent}
                />

                <section className="Airtime">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <aside className="sidebar">
                                    <UserAsidePane/>
                                </aside>
                            </div>
                            <div className="col-lg-9">
                                <div>
                                    <div className='justify-content-around flex-row-auto'>
                                        <div className="sec-heading">


                                            <div className="row">
                                                <div className="col-md-12 form-area pt-5">
                                                        <FormLabel id="airtime-radio-buttons-group-label" className="text-4 mb-3"><h5
                                                            className="text-4 mb-3">Select purchase item</h5></FormLabel>
                                                    <div className="mb-3">
                                                        {econetOptions.map((c, i) => {
                                                            return (
                                                                <div className="custom-control custom-radio custom-control-inline"
                                                                     key={i}>
                                                                    <label>

                                                                        <input type="radio"
                                                                               name="biller"
                                                                               defaultChecked={c.value === econetOptions[0].value}
                                                                               value={c.value}
                                                                               onChange={(e) => {
                                                                                   e.target.value === 'airtime' ? setToggleState(false) : setToggleState(true)
                                                                               }}
                                                                        />
                                                                        <div className="card align-items-center" style={{
                                                                            borderRadius: "15px",
                                                                            boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
                                                                        }}>
                                                                            <div className="card-body ">
                                                                                <img src={c.img} alt={c.value}/>
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>


                                            {/*<FormControl>*/}
                                            {/*    <FormLabel id="airtime-radio-buttons-group-label" className="text-4 mb-3"><h5*/}
                                            {/*        className="text-4 mb-3">Select purchase item</h5></FormLabel>*/}
                                            {/*    <RadioGroup*/}
                                            {/*        aria-labelledby="airtime-radio-buttons-group-label"*/}
                                            {/*        value={toggleState ? 'bundles' : 'airtime'}*/}
                                            {/*        defaultValue="airtime"*/}
                                            {/*        row*/}
                                            {/*        name="radio-buttons-group"*/}
                                            {/*        // onChange={(e) => setSelectAirtimeOrBundle(e.target.value)}*/}

                                            {/*    >*/}
                                            {/*        <FormControlLabel  control={<Radio/>} label="Econet Airtime"/>*/}
                                            {/*        <FormControlLabel value="bundles" control={<Radio/>} label=""/>*/}
                                            {/*    </RadioGroup>*/}
                                            {/*</FormControl>*/}
                                        </div>
                                    </div>
                                    <div className="col-lg-10 col-md-9 m-auto text-left">

                                        <h1 className="sec-title">{!toggleState ? 'ECONET AIRTIME PURCHASE' : 'ECONET BUNDLES PURCHASE'}</h1>
                                    </div>
                                </div>
                                <div className='col-lg-11 col-md-9 m-auto text-'>
                                    {!toggleState ? (<Airtime/>) : (<BundlesHome/>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
{/* 
                <Dialog header={<h3 class="admin-heading bg-offwhite">
                    <p>Transaction processing</p>
                    <span>Enter ecocash pin on your phone...</span>
                </h3>
                } visible={isProcessingPayment} style={{width: '20vw'}}
                onHide={() => setProcessingPayment(false)}
                >
                    <ReactPolling
                        //url={`${process.env.REACT_APP_BACKEND_HOST_URL}/biller-transaction/find-by-source-ref/${sourceReference}`}
                        interval={3000} // in milliseconds(ms)
                        retryCount={3} // this is optional
                        headers={{
                            //"Content-Type": "application/json",
                            'Authorization': `Bearer ${token}`,
                        }}
                        onSuccess={(data) => {
                            if (data.status == "SUCCESS") {
                                carousel.current?.prev();
                                // const {body} = res.data;
                                // show({
                                //     severity: body.transactionStatus.toLowerCase() !== 'failed' ? 'success' : 'error',
                                //     summary: body.transactionStatus,
                                //     detail: body.comment,
                                //     life: 9000
                                // });
                                resetForm()
                                 this.showSticky('success', 'completed successfully');
                                    show({
                                    severity: 'info',
                                    summary: 'Payment success',
                                    detail: 'Your payment was successful',
                                    life: 5000
                                })

                                // this.setState({
                                //     isProcessingPayment: false,
                                //     msisdn: '',
                                //     accountNumber: '',
                                //     amount: '',
                                //     isShowingPaymentTransactionDetailDialog: true,
                                //     transactionDetails: data
                                // });
                                setProcessingPayment(false)
                                return false;
                            } else if (data.status == "FAILED") {
                                // this.showSticky('error', " failed. Please try again later");
                                show({
                                    severity: 'error',
                                    summary: 'Transaction failed',
                                    detail: 'Your transaction failed. Please try again later',
                                    life: 5000
                                })

                                // this.setState({isProcessingPayment: false});
                                setProcessingPayment(false)
                                return false;
                            }
                            return true;
                        }}
                        onFailure={() => console.log("handle failure")} // this is optional
                        render={({startPolling, stopPolling, isPolling}) => {
                            if (isPolling) {
                                return (
                                    <div className="" style={{align: "centre"}}>
                                        <ProgressSpinner/>

                                    </div>
                                );
                            } else {
                                //this.setState({isProcessingPayment:false})
                                return <></>;
                            }
                        }}
                    />
                </Dialog> */}
            </div>
        </BundleContext.Provider>
    )
}

export default AirtimeBundles;
import React, { Component } from 'react';
import "aos/dist/aos.css";
import { AuthService } from '../services/AuthService';
import { Link } from 'react-router-dom'
import vector from '../images/vector.png';
import { UserService } from '../services/UserService';
import { Toast } from 'primereact/toast';
import {Navigate, useNavigate} from 'react-router-dom';



export const  withNavigation = (Component) => {
    return props => <Component {...props} navigate={useNavigate()} />;
  } 

class SignIn extends Component {
    constructor() {
        super();
        this.state = {
          token:null,
          username:'',
          password:'',
          isLoggingIn:false,
          buttonText: 'login'
        };

        this.authService = new AuthService();
        this.userService = new UserService();
        this.hangleLoggingIn = this.hangleLoggingIn.bind(this);
    }

    hangleLoggingIn (){
        if (this.state.username == '' || this.state.password == ''){
            this.toast.show({ severity: 'info', summary: 'Invalid credentials', detail: 'Enter username and password', life: 3000 })
            return;
        }

        this.authService.getTokenWithUsernameAndPassword(this.state.username, this.state.password)
        .then(response => {
            this.setState({token : response.data.body});
            localStorage.setItem('token', response.data.body);

            this.userService.getUser(this.state.token, this.state.username)
            .then(usr => {
                localStorage.setItem('user', JSON.stringify(usr.data.body));
                localStorage.setItem('isLoggedIn', true);
                this.toast.show({ severity: 'info', summary: 'Log in success', detail: 'You are now logged in', life: 5000 })
                this.props.navigate('/dashboard');
            })
            .catch(error => {
                console.log(error);
                this.toast.show({ severity: 'error', summary: 'Log in failed', detail: 'Failed to log you in. please try again later', life: 3000 })
            })
        })
        .catch(error => {
            console.log(error);
            this.toast.show({ severity: 'error', summary: 'Log in failed', detail: 'Failed to log you in. please try again later', life: 3000 })
        });
    }
        render() {
            return (
                
                <div >
                    <Toast ref={(el) => this.toast = el} />

                     <section className="page-feature py-5">
                        <div className="container text-center">
                            <div className="row">
                                <div className="col-md-6">
                                    <h2 className=" text-left">Login</h2>
                                </div>
                                <div className="col-md-6">
                                    <div className="breadcrumb text-right">
                                        <Link to='/'><a >Home</a></Link>
                                        <span>/ Login</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="banner login-registration">
                        <div className="vector-img">
                            <img src={vector} alt=""/>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="content-box">
                                        <h2>Login Account</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sedo<br/> eiusmod tempor incididunt dolore.</p>
                                    </div>
                                    {/* <form action="#" className="sl-form"> */}
                                    <div className="sl-form">
                                        <div className="form-group">
                                            <label>Username</label>
                                            <input type="text" id='username' name='username' placeholder="username" required onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.username= e.target.value))
                                                                                                                }}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input type="password" id='password' name='password' placeholder="Password" required onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.password= e.target.value))
                                                                                                                }}/>
                                        </div>
                                        
                                        <button className="btn btn-filled btn-round" onClick={this.hangleLoggingIn}><span className="bh"></span> <span>{ this.state.buttonText }</span></button>
                                        <p className="notice">Don’t have an account?<Link to='/sign-up'><a >Sign up now</a></Link></p>
                                    </div>
                                    {/* </form> */}
                                </div>
                            </div>
                        </div>
                    </section>
                
                </div>
            );
        }
}

export default withNavigation(SignIn);
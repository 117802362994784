import axios from 'axios';

export class PaymentService{

    makePayment(token, paymentDto){
        const options = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };
      
    
        return axios.post( `${process.env.REACT_APP_BACKEND_HOST_URL}/payment/amount`, paymentDto, options)
    }

    EpgmakePayment(token, paymentDto){
      const options = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
    
  
      return axios.post( `${process.env.REACT_APP_BACKEND_HOST_URL}/epg/biller/payment`, paymentDto, options)
  }
    
}


import React, { Component } from 'react';
import bgImage from '../images/payments2.jpg'
import { Card } from 'primereact/card';
import nyaradzao from '../images/nyaradzo.jpeg'
import { Button } from 'primereact/button';
import microFinance from "../images/microFInance.png";
import zesa from "../images/zesa1.jpeg";
import moovah from "../images/moovah1.png";
import harare from "../images/Hre2.png";
import unversities from '../images/universities.jpeg';
import schoolsLogo from '../images/schools.png';
import merchants from '../images/merchants.jpeg';
import AOS from 'aos';
import "aos/dist/aos.css";
import { AuthService } from '../services/AuthService';
import { Link } from 'react-router-dom'
import Councils from './Councils';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Rating } from 'primereact/rating';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import ReactPolling from "react-polling";
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { BillerService } from '../services/BillerService';
import { PaymentService } from '../services/PaymentService';
import { UserService } from '../services/UserService';
import hospital from '../images/patient.png';



class Home extends Component {
    constructor() {
        super();
        this.state = {
            name: 'Online Bill Payments',
            position: 'center',
            details:[],
            selectedBiller: null,
            billers: null,
            token: null,
            displayableBillers:null,
            isTabEnabled:false,
            activeTabIndex:0,
            isGettingBalance:false,
            balance:0,
            accountName:'',
            accountNumber:'',
            msisdn:'',
            amount:0,
            isShowingPaymentConfirmation:false,
            isProcessingPayment:false,
            sourceReference:'',
            ratingStars:null,
            isAccountTabDisabled:true,
            isBalanceTabDisabled:true,
            isConfirmationTabDisabled:true,
            isCompletionTabDisabled:true,
            displaySignInDialog:false,
            username:'',
            password:'',
            cpassword:'',
            firstName:'',
            lastName:'',
            email:'',
            cmsisdn:''
        };
      AOS.init({ 
          duration : 2000,
          once: false,
          mirror: true,
        });

        this.authService = new AuthService();
        this.billerService = new BillerService();
        this.paymentService = new PaymentService();
        this.userService = new UserService();
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
        this.paymentConfirmation = this.paymentConfirmation.bind(this);
        this.loggedInConfirmation = this.loggedInConfirmation.bind(this);
        this.hangleLoggingIn = this.hangleLoggingIn.bind(this);
        this.accept = this.accept.bind(this);
        this.reject = this.reject.bind(this);
        this.handleCreateAccount = this.handleCreateAccount.bind(this);
      }


      onClick(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }

        this.setState(state);
    }

    onHide(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    accept() {
        //this.setState({isProcessingPayment:true});
         let paymentDto = {
                endUserId: this.state.msisdn,
                billerCode: this.state.selectedBiller.code,
                billerName: this.state.selectedBiller.name,
                amount: this.state.amount,
                accountNumber: this.state.accountNumber,
                accountName: this.state.accountName,
                isPinless: false,
              };
            this.paymentService.makePayment(this.state.token, paymentDto)
            .then(response => {
                this.setState({sourceReference:response.data.body.field3, isProcessingPayment:true});
               // this.setState({isProcessingPayment:false});
            })
            .catch(error => {
                console.log(error)
               // this.setState({isProcessingPayment:false});

            });
       // this.toast.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    }

    handleCreateAccount(){

        let userDto = {
            username: this.state.username,
            password: this.state.password,
            cpassword: this.state.cpassword,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email:this.state.email,
            cmsisdn:this.state.cmsisdn
        }

        if(userDto.username === '' || 
            userDto.password === '' || 
            userDto.cpassword === '' ||  
            userDto.firstName === '' ||  
            userDto.lastName === '' ||  
            userDto.email === '' || 
            userDto.cmsisdn === ''){
            this.toast.show({ severity: 'info', summary: 'Invalid data', detail: 'Please enter all fields', life: 3000 });
            return;
        }
        if(userDto.cpassword !== userDto.password){
            this.toast.show({ severity: 'info', summary: 'Passwords do not match', detail: 'Please enter the same password', life: 3000 });
            return;
        }

        this.userService.create(userDto)
        .then(response => {
            this.toast.show({ severity: 'info', summary: 'Account Created', detail: 'Your account has been created.', life: 3000 });
            this.setState({displaySignInDialog:false})
        })
        .catch(error => {
            this.toast.show({ severity: 'error', summary: 'Error creating account', detail: 'The account could not be created. Please try again later', life: 3000 });
        });

       
    }

    reject() {
        // this.toast.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    paymentConfirmation(event) {
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            accept: this.accept,
            reject: this.reject
        });
    }

    loggedInConfirmation(event){
        if(localStorage.getItem("isLoggedIn")){
            console.log("Welcome:");
        }else{
            confirmPopup({
                target: event.currentTarget,
                message: 'You need to be logged in to access this feature.\n Do u want to log in?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => { this.setState({displaySignInDialog:true})},
                reject: () => {this.toast.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });}
            });
        }
    }

      hangleLoggingIn(){
        if (this.state.username == '' || this.state.password == ''){
            this.toast.show({ severity: 'info', summary: 'Invalid credentials', detail: 'Enter username and password', life: 3000 })
            return;
        }

        this.setState({isLoggingIn:true});
        this.authService.getTokenWithUsernameAndPassword(this.state.username, this.state.password)
        .then(response => {
            localStorage.setItem('user', response.data.body);
            localStorage.setItem('isLoggedIn', true);
        })
        .catch(error => {
            console.log(error);
            this.toast.show({ severity: 'error', summary: 'Log in failed', detail: 'Failed to log you in. please try again later', life: 3000 })
            this.setState({isLoggingIn:false})
        });
    }

    renderFooter(name) {
        return (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-text" />
                {/* <Button label="Yes" icon="pi pi-check" onClick={() => this.onHide(name)} autoFocus /> */}
            </div>
        );
    }

      componentDidMount() {
        this.authService.getToken().then(response => this.setState({ token: response.data.body })).catch(error => {console.log(error)});
        this.billerService.getAllBillers(this.state.token)
        .then(response => {
            var allPartners = response.data.body;
            this.setState(
                {
                    billers:allPartners,
                    selectedBiller:allPartners[0]

                    // billers:allPartners.filter(partner => partner.billerCategory.id == process.env.REACT_APP_COUNCILS_ID),
                    // selectedBiller:allPartners.filter(partner => partner.billerCategory.id == process.env.REACT_APP_COUNCILS_ID)[0]
                });
            }
            )
        .catch(error => console.log(error));
        // this.authService.getToken().then(data => this.setState({ token: data.body })).catch(error => {console.log(error)});
        }
 
        render() {

            const hangleGetBalance = () => {
                this.setState({isGettingBalance:true});
                this.billerService.getBillerAccount(this.state.token, this.state.msisdn, this.state.accountNumber, this.state.selectedBiller.code )
                .then( response => this.setState(
                    {
                        balance : response.data.body.billBalance, 
                        accountName:response.data.body.name, 
                        isGettingBalance:false, 
                        isBalanceTabDisabled:false,
                        activeTabIndex: 2, 
                        details:[
                            {'name':'Account Name', 'value': response.data.body.name},
                            {'name':'Account Number', 'value':this.state.accountNumber},
                            {'name':'Biller Name', 'value': this.state.selectedBiller.name},
                            {'name':'Biller Code', 'value':this.state.selectedBiller.code},
                            {'name':'Balance', 'value': response.data.body.billBalance}
                        ]}))
                .catch(error => {
                    console.log(error);
                    this.setState({isGettingBalance:false, isBalanceTabDisabled:true});
                    this.toast.show({ severity: 'error', summary: 'Transaction failed', detail: 'Error getting account balance. Please try again', life: 3000 });
                });
                
            }
            const innerDiv = {
                margin: 'auto',
                width: '60%',
                padding: '5px',
                marginTop:'20px'
            }

            return (
                
                <div >
                   <Toast ref={(el) => this.toast = el} />
                    <div style={innerDiv} className='row' data-aos="zoom-in">
                    <div className='col-6' data-aos="zoom-in">
                            <Card title="Councils" style={{ width: '25rem', marginBottom: '2em' }} 
                            footer={
                                // <Link to='/councils' style={{color:'#fff', textDecoration: 'none'}} class="pull pull-right">
                                    <Button label="make payment" icon="pi pi-angle-double-right" iconPos="right" className="p-button-success ml-2" 
                                        onClick={() =>{
                                                    this.setState({
                                                        displayableBillers:this.state.billers.filter(partner => partner.billerCategory.id == process.env.REACT_APP_COUNCILS_ID),
                                                        selectedBiller:this.state.billers.filter(partner => partner.billerCategory.id == process.env.REACT_APP_COUNCILS_ID)[0]
                                                    });
                                                    this.onClick('displayPosition', 'center');
                                                }}
                                    />
                                // </Link>
                                } >
                                <hr/>
                                <img src={harare} width="200" height="200"/>
                            </Card>
                        </div>
                        <div className='col-6' data-aos="zoom-in">
                            <Card title="Health Insurance" style={{ width: '25rem', marginBottom: '2em' }} 
                            footer={
                                // <Link to='/councils' style={{color:'#fff', textDecoration: 'none'}} class="pull pull-right">
                                    <Button label="make payment" icon="pi pi-angle-double-right" iconPos="right" className="p-button-success ml-2" 
                                        onClick={() =>{
                                                    this.setState({
                                                        displayableBillers:this.state.billers.filter(partner => partner.billerCategory.id == process.env.REACT_APP_HEALTH_INSURANCE_ID),
                                                        selectedBiller:this.state.billers.filter(partner => partner.billerCategory.id == process.env.REACT_APP_HEALTH_INSURANCE_ID)[0]
                                                    });
                                                    this.onClick('displayPosition', 'center');
                                                }}
                                    />
                                // </Link>
                                } >
                                <hr/>
                                <img src={hospital} width="200" height="200"/>
                            </Card>
                        </div>
                        <div className='col-6' data-aos="zoom-in">
                            <Card title="Insurance" style={{ width: '25rem', marginBottom: '2em' }} 
                            footer={
                                    // <Link to='/insurance' style={{color:'#fff', textDecoration: 'none'}} class="pull pull-right">
                                        <Button label="make payment" icon="pi pi-angle-double-right" iconPos="right" className="p-button-success ml-2" 
                                            onClick={() => {
                                                this.setState({
                                                    displayableBillers:this.state.billers.filter(partner => partner.billerCategory.id == process.env.REACT_APP_LIFE_INSURANCE_ID),
                                                    selectedBiller:this.state.billers.filter(partner => partner.billerCategory.id == process.env.REACT_APP_LIFE_INSURANCE_ID)[0]
                                                });
                                                 this.onClick('displayPosition', 'center');
                                            }}/>
                                    // </Link>
                                }>
                                <hr/>
                            <img src={moovah} width="200" height="200"/>
                            </Card>
                        </div>
                        
                        <div className='col-6' data-aos="zoom-in">
                            <Card title="Microfinance Institutions" style={{ width: '25rem', marginBottom: '2em' }} 
                                footer={
                                            // <Link to='/micro-finance' style={{color:'#fff', textDecoration: 'none'}} class="pull pull-right">
                                                <Button label="make payment" icon="pi pi-angle-double-right" iconPos="right" className="p-button-success ml-2" 
                                                    onClick={() =>{
                                                        this.setState({
                                                            displayableBillers:this.state.billers.filter(partner => partner.billerCategory.id == process.env.REACT_APP_MICRO_FINANCE_ID),
                                                            selectedBiller:this.state.billers.filter(partner => partner.billerCategory.id == process.env.REACT_APP_MICRO_FINANCE_ID)[0]
                                                        });
                                                        this.onClick('displayPosition', 'center');
                                                    }}
                                                />
                                            // </Link>
                                        }>
                                <hr/>
                            <img src={microFinance} width="200" height="200"/>
                            </Card>
                        </div>
                        <div className='col-6' data-aos="zoom-in">
                            <Card title="Utility Bills" style={{ width: '25rem', marginBottom: '2em' }} 
                                footer={
                                    // <Link to='/utilities' style={{color:'#fff', textDecoration: 'none'}} class="pull pull-right">
                                        <Button label="make payment" icon="pi pi-angle-double-right" iconPos="right" className="p-button-success ml-2" 
                                            onClick={() =>{
                                                this.setState({
                                                    displayableBillers:this.state.billers.filter(partner => partner.billerCategory.id == process.env.REACT_APP_UTILITY_BILLS_ID),
                                                    selectedBiller:this.state.billers.filter(partner => partner.billerCategory.id == process.env.REACT_APP_UTILITY_BILLS_ID)[0]
                                                });
                                                this.onClick('displayPosition', 'center');
                                            }}
                                        />
                                    // </Link>
                                }>
                                        <hr/>
                                    <img src={zesa} width="200" height="200"/>
                            </Card>
                        </div>
                        
                        {/* <div className='col' data-aos="zoom-in">
                            <Card title="Schools" style={{ width: '25rem', marginBottom: '2em' }} footer={<Link to='/schools' style={{color:'#fff', textDecoration: 'none'}} class="pull pull-right"><Button label="make payment" icon="pi pi-angle-double-right" iconPos="right" className="p-button-success ml-2" /></Link>}>
                                <hr/>
                            <img src={schoolsLogo} width="200" height="200"/> 
                            </Card>
                        </div>
                        <div className='col' data-aos="zoom-in">
                            <Card title="Universities" style={{ width: '25rem', marginBottom: '2em' }} footer={<Link to='/universities' style={{color:'#fff', textDecoration: 'none'}} class="pull pull-right"><Button label="make payment" icon="pi pi-angle-double-right" iconPos="right" className="p-button-success ml-2" /></Link>}>
                                <hr/>
                                <img src={unversities} width="200" height="200"/>
                            </Card>
                        </div> */}
                        
                    
                        
                        {/* <div className='col' data-aos="zoom-in">
                        <Card title="Merchants" style={{ width: '25rem', marginBottom: '2em' }} footer={<Link to='/merchants' style={{color:'#fff', textDecoration: 'none'}} class="pull pull-right"><Button label="make payment" icon="pi pi-angle-double-right" iconPos="right" className="p-button-success ml-2" /></Link>} >
                            <hr/>
                                <img src={merchants} width="200" height="200"/>
                            </Card>  
                        </div>    */}
                        
                        
                    </div>

                    <Dialog header='Make payment'  visible={this.state.displayPosition} position={this.state.position} modal style={{ width: '90%', height:'90%' }} footer={this.renderFooter('displayPosition')} onHide={() => this.onHide('displayPosition')} breakpoints={{'960px': '75vw'}}>
                       <hr/>
                        <div className='row'>
                            <div style={{paddingLeft:'20%', paddingRight:'20%'}}>
                                <TabView activeIndex={this.state.activeTabIndex}  onTabChange={(e) => this.setState({ activeTabIndex: e.index })}>
                                    <TabPanel header="Biller">
                                        <div className='row' style={{paddingLeft:'10%', marginTop:'2%'}}>
                                            <h3>Choose Biller</h3>
                                           {
                                                this.state.displayableBillers != null ? this.state.displayableBillers.map((biller)=>(
                                                    <div className="col-sm-4" key={biller.id}>
                                                            <div className="card" style={{backgroundColor:'##D5D83D',  borderRadius: "15px", margin:"10px", boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"}}>
                                                                <div className="card-body ">
                                                                    <h6 className="card-title"><RadioButton inputId={biller.id} name="biller" value={biller} onChange={(e) => this.setState({selectedBiller: e.value})}  checked={this.state.selectedBiller.id === biller.id}/>&nbsp;{biller.name}</h6>
                                                                    {/* <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                                                    <a href="#" className="btn btn-primary">Make Payment</a> */}
                                                                    {/* <img className="mw-100 h-100px mb-7 mx-auto" src={doves} /> */}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                                : <></>
                                            }

                                          

                                            <div className='row' style={{marginTop:'20px'}}>
                                                    <div className='col-sm-3'>
                                                        <Button label="Continue" icon="pi pi-arrow-right" iconPos="right" onClick={() => {this.setState({activeTabIndex: 1, isAccountTabDisabled:false})}}/>
                                                    </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel header="Account" disabled={this.state.isAccountTabDisabled}>
                                        <div className='row' style={{paddingLeft:'10%', paddingRight:'10%', marginTop:'2%'}}>
                                            <h3>Account Details</h3>
                                            <br/>
                                            <br/>
                                           
                                                <div class="form-group">
                                                    <label for="accountNumber">Account Number</label>
                                                    <input type="text" class="form-control form-control-lg" id="accountNumber" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}}  onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.accountNumber= e.target.value))
                                                                                                                }}/>
                                                </div>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <div class="form-group">
                                                    <label for="msisdn">Mobile Number</label>
                                                    <input type="text" class="form-control form-control-lg" id="msisdn" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}}  onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.msisdn= e.target.value))
                                                                                                                }}/>
                                                </div>
                                                <br/>

                                                <br/>
                                                <div className='row' style={{marginTop:'20px'}}>
                                                    <div className='col-sm-3'>
                                                        <Button label="Biller" icon="pi pi-arrow-left" iconPos="left" onClick={() => {this.setState({activeTabIndex:0})}}/>
                                                    </div>

                                                    <div className='col-sm-4'>
                                                        <Button label={this.state.isGettingBalance ? "Loading…" : "Get account balance"} icon="pi pi-info-circle" iconPos="left" onClick={hangleGetBalance}/>
                                                    </div>

                                                    <div className='col-sm-3'>
                                                        <Button label="Continue" icon="pi pi-arrow-right" iconPos="right" onClick={() => {this.setState({activeTabIndex:3, isConfirmationTabDisabled:false})}}/>
                                                    </div>
                                                

                                                </div>
                                              
                                        </div>
                                    </TabPanel>
                                    <TabPanel header="Balance" disabled={this.state.isBalanceTabDisabled}>
                                    <div className='row' style={{paddingLeft:'10%', paddingRight:'10%', marginTop:'2%'}}>
                                            <h3>Account Balance</h3>
                                            <br/>

                                            <div className='row'>
                                                <DataTable value={this.state.details} stripedRows responsiveLayout="scroll">
                                                    <Column field="name" header="Name"></Column>
                                                    <Column field="value" header="Value"></Column>
                                                </DataTable>

                                            </div>
                                           
                                                <div className='row' style={{marginTop:'20px'}}>
                                                    <div className='col-sm-3'>
                                                        <Button label="Account" icon="pi pi-arrow-left" iconPos="left" onClick={() => {this.setState({activeTabIndex:1})}}/>
                                                    </div>


                                                    <div className='col-sm-3'>
                                                        <Button label="Continue" icon="pi pi-arrow-right" iconPos="right" onClick={() => {this.setState({activeTabIndex:3, isConfirmationTabDisabled:false})}}/>
                                                    </div>
                                                

                                                </div>
                                              
                                        </div>
                                    </TabPanel>
                                    <TabPanel header="Confirmation" disabled={this.state.isCompletionTabDisabled}>
                                        <div className='row' style={{paddingLeft:'10%', paddingRight:'10%', marginTop:'2%'}}>
                                        <h3>Payment Details</h3>
                                            <div className='row'>

                                                <DataTable value={this.state.details} stripedRows responsiveLayout="scroll">
                                                        <Column field="name" header="Name"></Column>
                                                        <Column field="value" header="Value"></Column>
                                                    </DataTable>
                                            </div>

                                            <div className='row' style={{marginTop:'20px'}}>
                                                <div className='col-sm-3'>
                                                        <h6>Paying Phone Number</h6>
                                                    </div>
                                                        <div className='col-sm-3'>
                                                        <InputText placeholder="msisdn" id='msisdn' value={this.state.msisdn}  onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.msisdn= e.target.value))
                                                                                                                }}/>
                                                        </div> 
                                                       
                                            </div>

                                            <div className='row' style={{marginTop:'20px'}}>
                                                <div className='col-sm-3'>
                                                        <h6>Amount</h6>
                                                    </div>
                                                        <div className='col-sm-3'>
                                                        <InputNumber placeholder="amount" id='amount' requestFocus mode="decimal" minFractionDigits={2} onValueChange={(e) => {
                                                                                                                //e.persist();
                                                                                                                this.setState((state) => (state.amount= e.target.value))
                                                                                                                }}/>
                                                         <Message severity="error" text="Amount is required" />
                                                        </div> 
                                                       
                                            </div>
                                            <div className='row' style={{marginTop:'20px'}}>
                                                        <div className='col-sm-3'>
                                                            <Button label="Account" icon="pi pi-arrow-left" iconPos="left" onClick={() => {this.setState({activeTabIndex:1})}}/>
                                                        </div>
                                                        
                                                        <div className='col-sm-3'>
                                                            <Button label="Continue" id='payButton' icon="pi pi-arrow-right" iconPos="right" onClick={this.paymentConfirmation}/>
                                                        </div>
                                                    

                                            </div>

                                            

                                        </div>
                                    </TabPanel>
                                    <TabPanel header="Completion" disabled={this.state.isCompletionTabDisabled}>
                                        <div className='row' style={{paddingLeft:'10%', paddingRight:'10%', marginTop:'2%'}}>
                                            <h3>Transaction details</h3>
                                            <br/>

                                            <div className='row'>
                                                <br/>
                                                <h1 style={{marginTop:'10px'}}>Your payment to {this.state.selectedBiller != null ? this.state.selectedBiller.name : ''} in account named {this.state.accountName} of {this.state.amount}  was successful.</h1>
                                            </div>
                                            <div className='row' style={{marginTop:'20px'}}>
                                                <h4>Rate us</h4>
                                                <Rating value={this.state.ratingStars} onChange={(e) => this.setState({ratingStars: e.value})} />
                                            </div>

                                            <div className='row' style={{marginTop:'20px'}}>
                                                <h4>Save Transaction</h4>
                                                <div className="field-checkbox">
                                                    <Checkbox inputId="recurringPayments" name="recurringPayments" value="Mark as recurring a payment"  />&nbsp;
                                                    <label htmlFor="recurringYayments">Mark as a recurring payment</label>
                                                </div>
                                                <div className="field-checkbox" style={{marginTop:'2px'}}>
                                                    <Checkbox inputId="expressPayment" name="expressPayment" value="Xpress Payment"/>&nbsp;
                                                    <label htmlFor="expressPayment">Add to favourates</label>
                                                </div>

                                                <div className='col-sm-3' style={{marginTop:'5px'}}>
                                                            <Button label="save " icon="pi pi-check" iconPos="left" />
                                                        </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                       
                    </Dialog>


                    <Dialog header="Enter your ecocash pin on your phone..." visible={this.state.isProcessingPayment} style={{ width: '20vw' }}>
                                <ReactPolling
                                    url={`${process.env.REACT_APP_BACKEND_HOST_URL}/biller-transaction/find-by-source-ref/${this.state.sourceReference}`}
                                    interval={3000} // in milliseconds(ms)
                                    retryCount={3} // this is optional
                                    onSuccess={(data) => {
                                        console.log({ data });
                                        if (data.status == "SUCCESS") {
                                            // this.showSticky('success', 'completed successfully');
                                            this.setState({isProcessingPayment:false, activeTabIndex:4});
                                            
                                            return false;
                                        } else if (data.status == "FAILED") {
                                            // this.showSticky('error', " failed. Please try again later");
                                            this.setState({isProcessingPayment:false, isCompletionTabDisabled:false, activeTabIndex:4});
                                            return false;
                                        }
                                        return true;
                                    }}
                                    onFailure={() => console.log("handle failure")} // this is optional
                                    render={({ startPolling, stopPolling, isPolling }) => {
                                        if (isPolling) {
                                        return (
                                            <div className="" style={{align:"centre"}}>
                                                <ProgressSpinner />

                                            </div>
                                        );
                                        } else {
                                            //this.setState({isProcessingPayment:false})
                                        return <></>;
                                        }
                                    }}
                            />
                </Dialog>


                <Dialog header='Sign in'  visible={this.state.displaySignInDialog} position={this.state.position} modal style={{ width: '90%', height:'90%' }} footer={this.renderFooter('displayPosition')} onHide={() => this.onHide('displaySignInDialog')} breakpoints={{'960px': '75vw'}}>
                <hr/>
                        <div className='row'>
                            <div style={{paddingLeft:'20%', paddingRight:'20%'}}>
                                <TabView>
                                    <TabPanel header="Sign in">
                                        <div className='row' style={{paddingLeft:'10%', marginTop:'2%'}}>
                                            <h3>Log in</h3>
                                            <br/>
                                            <br/>

                                           
                                           
                                                <div class="form-group">
                                                    <label for="username">Username</label>
                                                    <input type="text" class="form-control form-control-lg" id="username" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}}  onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.username= e.target.value))
                                                                                                                }}/>
                                                </div>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <div class="form-group">
                                                    <label for="password">Password</label>
                                                    <input type="password" class="form-control form-control-lg" id="password" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}}  onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.password= e.target.value))
                                                                                                                }}/>
                                                </div>
                                                <br/>

                                                <br/>
                                          

                                            <div className='row' style={{marginTop:'20px'}}>
                                                    <div className='col-sm-3'>
                                                        <Button label="sign in" icon="pi pi-sign-in" iconPos="right" onClick={this.hangleLoggingIn}/>
                                                    </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel header="Create Account" >
                                        <div className='row' style={{paddingLeft:'10%', paddingRight:'10%', marginTop:'2%'}}>
                                            <h3>Account Details</h3>
                                            <br/>
                                            <br/>
                                           
                                                <div class="form-group">
                                                    <label for="firstName">First Name</label>
                                                    <input type="text" class="form-control form-control-lg" id="firstName" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}}  onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.firstName= e.target.value))
                                                                                                                }}/>
                                                </div>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <div class="form-group">
                                                    <label for="lastName">Last Name</label>
                                                    <input type="text" class="form-control form-control-lg" id="lastName" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}}  onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.lastName= e.target.value))
                                                                                                                }}/>
                                                </div>

                                                <br/>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <div class="form-group">
                                                    <label for="email">Email</label>
                                                    <input type="email" class="form-control form-control-lg" id="email" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}}  onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.email= e.target.value))
                                                                                                                }}/>
                                                </div>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <div class="form-group">
                                                    <label for="cmsisdn">Mobile Number</label>
                                                    <input type="text" class="form-control form-control-lg" id="cmsisdn" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}}  onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.cmsisdn= e.target.value))
                                                                                                                }}/>
                                                </div>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <div class="form-group">
                                                    <label for="username">Username</label>
                                                    <input type="text" class="form-control form-control-lg" id="username" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}}  onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.username= e.target.value))
                                                                                                                }}/>
                                                </div>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <div class="form-group">
                                                    <label for="password">Password</label>
                                                    <input type="password" class="form-control form-control-lg" id="password" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}}  onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.password= e.target.value))
                                                                                                                }}/>
                                                </div>

                                                <br/>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <div class="form-group">
                                                    <label for="cpassword">Re-enter Password</label>
                                                    <input type="password" class="form-control form-control-lg" id="cpassword" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}}  onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.cpassword= e.target.value))
                                                                                                                }}/>
                                                </div>
                                                <br/>

                                                <br/>
                                                <div className='row' style={{marginTop:'20px'}}>
                                                    <div className='col-sm-3'>
                                                        <Button label="create account" icon="pi pi-user-plus" onClick={this.handleCreateAccount}/>
                                                    </div>

                                                </div>
                                              
                                        </div>
                                    </TabPanel>
                                    <TabPanel header="Forgot Password">

                                    </TabPanel>
                                </TabView>
                            </div>
                        </div> 
                </Dialog>
                
                </div>
            );
        }
}

export default Home;
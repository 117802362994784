export const SignIn = () => {
    window.fbq('track', 'Login');
}


export const BillerManagerLandPage = () => {
    window.fbq('track', 'BillerManagerLandingPage');
}

//not yet implemented list

export const Register  = () => {
    window.fbq('track', 'Register ');
}

export const Sendemail  = () => {
    window.fbq('track', 'Sendemail');
}

export const Utilitybill = () => {
    window.fbq('track', 'Utilitybill');
}

export const ProcessPayment = () => {
    window.fbq('track', 'ProcessPayment');
}

export const GetBalance = () => {
    window.fbq('track', 'GetBalance');
}

export const AirtimeBundlesLandingPage = () => {
    window.fbq('track', 'AirtimeBundlesLandingPage');
}

export const BuyAirtime = () => {
    window.fbq('track', 'BuyAirtime');
}

export const BuyBundles = () => {
    window.fbq('track', 'BuyBundles');
}

export const BuyBulkAirtime = () => {
    window.fbq('track', 'BuyBulkAirtime');
}

export const BuyBulkBundles = () => {
    window.fbq('track', 'BuyBulkBundles');
}

export const RecurringPayment = () => {
    window.fbq('track', 'RecurringPayment');
}

export const SaveFavourites = () => {
    window.fbq('track', 'SaveFavourites');
}


export const TransactionsPage = () => {
    window.fbq('track', 'TransactionsPage');
}

export const ContactUs = () => {
    window.fbq('track', 'ContactUs');
}

export const DashBoard = () => {
    window.fbq('track', 'DashBoard');
}

export const WalletStatement = () => {
    window.fbq('track', 'WalletStatement');
}

export const DownloadedWalletStatement = () => {
    window.fbq('track', 'DownloadedWalletStatement');
}

export const FailedToDownloadWalletStatement = () => {
    window.fbq('track', 'FailedToDownloadWalletStatement');
}



export const SaveBillers = () => {
    window.fbq('track', 'SaveBillers(NewBiller)');
}



import React, { Component, useState,useEffect,useContext } from 'react';
import Switch from "react-switch";
import OnlinePayements from '../OnlinePayements';
import "./ToggleSwitch.css";
import { Context } from "../Store";

//import 'bootstrap/dist/css/bootstrap.min.css';
//import Select, { components } from 'react-select';
// const textSwitch = {
//     display: "flex",
//     justifyContent:"center",
//     alignItem:"center",
//     height:"100%",
//     fontSize:14,
// 	weight: 300,
//     color:"#fff",
//     paddingRight:2,
    
// }

function ToggleSwitch () {
    const[currency,setCurrency] = useState('ZWG');
    let [checked,setChecked] = useState(false);
    const [state, dispatch] = useContext(Context);

    localStorage.setItem('currency', currency);

    //  const handleChange = (event) =>{
    //       console.log(event);
    //    // setChecked(checked);
	// 	// dispatch({
    //     //     type: "SET_CURRENCY", // The name of the reducer
    //     //     payload: JSON.stringify(checked ? 'ZWG' : 'USD'), // The new state of the reducer
    //     //   });

	// 	// localStorage.setItem("currency", checked ? 'USD':'ZWG');
    //     localStorage.setItem("currency", event.name);
    //   }


    const options = [
        {value: 'ZWG', text: 'ZWG'},
        {value: 'USD', text: 'USD'},
      ];


    const Currency= () => {
        window.fbq('track', 'Currency');
    }
        
      const [selected, setSelected] = useState(options[0].value);
    
      const handleChange = event => {
        setSelected(event.target.value);
        setCurrency(event.target.value)
        setChecked(checked);

        localStorage.setItem('currency', checked ? 'USD':'ZWG');
        localStorage.setItem('currency', event.target.value);
        Currency();

      };





    

      


    //   useEffect(()=>{console.log(currency)});


    // const options = [
    //     //{value: '', text: 'Currency'},
    //     {name: 'ZWG'},
    //     {name: 'USD'},
    //   ];

    //   const [selected, setSelected] = useState(options[0].value);

    //   const onCurrencyChange = (e) => {

    //     setChecked(checked);
    //     console.log(e.value.name)
    //     setCurrency(e.value);
    //     //localStorage.setItem("currency", checked ? 'USD':'ZWG');
    //     localStorage.setItem('currency', e.name);
    // }


    //   const handleChange = event => {
    //     console.log(event.target.value);
    //     setSelected(event.target.value);
    //   };


        return (
            <div>
                 {/* <div className="dropdown-demo"> */}
            
        {/* <Dropdown value={currency} options={options} onChange={onCurrencyChange} optionLabel="name" placeholder="Select a City"/> */}


        {/* <Dropdown value={currency} options={options} onChange={(e) => setCurrency(e.value)} placeholder="Select a Currency"/> */}
        <span class="Currency">Currency  </span>
        <select value={selected} onChange={handleChange}>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>       
      </div>
    //   </div>

            
        );
}




export default ToggleSwitch;

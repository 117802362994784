import React, {useContext} from "react";
import {Table} from "react-bootstrap";
import {BundleContext} from "../config/contexts";

const AirtimePurchase = ({data}) => {
    // const {data} = useContext(BundleContext)

    return(
        <div className="confirm">
            <Table striped variant="secondary">
                <tbody>
                <tr>
                    <td>Paying Number</td>
                    <td>{data?.payingMsisdn}</td>
                </tr>
                <tr>
                    <td>Receiving Number</td>
                    <td>{data?.receivingMsisdn}</td>
                </tr>
                <tr>
                    <td>Currency</td>
                    <td>{data?.currencyCode}</td>
                </tr>
                <tr>
                    <td>Amount</td>
                    <td>{data?.amount}</td>
                </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default AirtimePurchase;
import React, { Component } from 'react';
import OnlinePayements from './OnlinePayements';

class Unversities extends Component {
    render() {
        return (
            <div>
                <OnlinePayements category={process.env.REACT_APP_UNIVERSITIES_ID}/>
            </div>
        );
    }
}

export default Unversities;
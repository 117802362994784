import React, { Component } from 'react';
import OnlinePayements from './OnlinePayements';

export default class InternetServices extends Component {

    render(){ return (
        <div>
            <OnlinePayements category={process.env.REACT_APP_INTERNET_SERVICES_ID}/>
        </div>
    )
   }
    
    
}

import Byo from '../../images/BulawayoCityCouncil.jpeg';
import Celfre from '../../images/CelfreEnergy.jpeg';
import CIMAS from '../../images/CIMAS.jpeg';
import Doves from '../../images/doves.png';
import Edgars from '../../images/edgars.png';
import ZINWA from '../../images/ZINWA.png';
import Fidelity from '../../images/FidelityLife.jpeg';
import Nyaradzo from '../../images/Nyaradzo.png';
import PowerLive from '../../images/PowerLive.jpeg';
import Jet2 from '../../images/Jet2.png';
import Zesa from '../../images/ZESATokens.jpeg';
import FirstMutualhealth from '../../images/FirstMutualhealth.png';
import fbcLogo from '../../images/FBC-Loan-Repayment.jpeg';
import HRE from '../../images/City-of-Harare.jpeg';
import Liquid from '../../images/LiquidHome.png';
import FBCmicroplan from '../../images/FBCmicroplan.png';
import FML from '../../images/First-Mutual-Life.png';
import Econent from '../../images/Econet.png';
import Ecosure from '../../images/EcoSure.png';
import Moovah from '../../images/moovah.jpeg';
import VTL from '../../images/VTL.png';
import PSMS from '../../images/psmas.png';
import OM from '../../images/OM.jpeg';


function ServiceProvidersComponent(){
    return(
        <div className="container">
                <div className="row">
                    <div className=" col-md-12 m-auto text-center">
                        <div className="sec-heading mb-4">
                            <span className="tagline">Service provider</span>
                            <h3 className="sec-title">Our Trusted Partners</h3>
                        </div>
                    </div>
                </div>
                <div className="portfolioITems">
                    <div className="ui-group filters mb-disable">
                        <div className="button-group js-radio-button-group" data-filter-group="color">
                            {/* <button className="button is-checked" data-filter="">All</button> */}
                            <button className="button" data-filter=".data">Councils</button>
                            <button className="button" data-filter=".dth">Prepaid Tokens</button>
                            <button className="button" data-filter=".broadband">Insurance</button>
                            <button className="button" data-filter=".electricity">Microfinance</button>
                            <button className="button" data-filter=".emi">Utility Bills</button>
                            <button className="button" data-filter=".emi">Credit Stores</button>

                            
                        </div>
                    </div>
                    <div className="portGrid row">
                        <div className="protItem col-3 col-md-1 data">
                            <img src={Byo} alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 data">
                            <img src={Celfre} alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 dth">
                            <img src={CIMAS} alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 dth">
                            <img src={Doves} alt="" className="we-work-img"/>
                        </div>
                        
                        <div className="protItem col-3 col-md-1 dth">
                            <img src={ZINWA} alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 dth">
                            <img src={Fidelity} alt="" className="we-work-img"/>
                        </div>

                        <div className="protItem col-3 col-md-1 broadband">
                            <img src={Nyaradzo} alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 broadband">
                            <img src={PowerLive} alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 broadband">
                            <img src={Jet2} alt="" className="we-work-img"/>
                        </div>

                        <div className="protItem col-3 col-md-1 electricity">
                            <img src={Zesa} alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 electricity">
                            <img src={FirstMutualhealth} alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 electricity">
                            <img src={fbcLogo} alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 electricity">
                            <img src={HRE} alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 electricity">
                            <img src={Fidelity} alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 electricity">
                            <img src={Liquid} alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 electricity">
                            <img src={FBCmicroplan} alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 electricity">
                            <img src={FML} alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 dth">
                            <img src={Edgars} alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 electricity">
                            <img src={Econent} alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 electricity">
                            <img src={Ecosure} alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 electricity">
                            <img src={Moovah} alt="" className="we-work-img"/>
                        </div>

                        <div className="protItem col-3 col-md-1 emi">
                            <img src={VTL} alt="" className="we-work-img"/>
                        </div>

                        <div className="protItem col-3 col-md-1 gas">
                            <img src={PSMS} alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 gas">
                            <img src={OM} alt="" className="we-work-img"/>
                        </div>
                       {/* <div className="protItem col-3 col-md-1 gas">
                            <img src="images/bill/gas/3.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 gas">
                            <img src="images/bill/gas/4.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 gas">
                            <img src="images/bill/gas/5.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 gas">
                            <img src="images/bill/gas/6.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 gas">
                            <img src="images/bill/gas/7.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 gas">
                            <img src="images/bill/gas/8.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 gas">
                            <img src="images/bill/gas/9.png" alt="" className="we-work-img"/>
                        </div>

                        <div className="protItem col-3 col-md-1 insurance">
                            <img src="images/bill/insurance/1.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 insurance">
                            <img src="images/bill/insurance/2.png" alt="" className="we-work-img"/>
                        </div>

                        <div className="protItem col-3 col-md-1 land">
                            <img src="images/bill/land/1.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 land">
                            <img src="images/bill/land/2.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 land">
                            <img src="images/bill/land/3.png" alt="" className="we-work-img"/>
                        </div>

                        <div className="protItem col-3 col-md-1 metro">
                            <img src="images/bill/metro/1.png" alt="" className="we-work-img"/>
                        </div>

                        <div className="protItem col-3 col-md-1 postpaid">
                            <img src="images/bill/postpaid/1.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 postpaid">
                            <img src="images/bill/postpaid/2.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 postpaid">
                            <img src="images/bill/postpaid/3.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 postpaid">
                            <img src="images/bill/postpaid/5.png" alt="" className="we-work-img"/>
                        </div>

                        <div className="protItem col-3 col-md-1 prepaid">
                            <img src="images/bill/prepaid/1.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 prepaid">
                            <img src="images/bill/prepaid/2.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 prepaid">
                            <img src="images/bill/prepaid/3.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 prepaid">
                            <img src="images/bill/prepaid/4.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 prepaid">
                            <img src="images/bill/prepaid/5.png" alt="" className="we-work-img"/>
                        </div>

                        <div className="protItem col-3 col-md-1 tax">
                            <img src="images/bill/tax/1.png" alt="" className="we-work-img"/>
                        </div>

                        <div className="protItem col-3 col-md-1 traffic">
                            <img src="images/bill/traffic/1.png" alt="" className="we-work-img"/>
                        </div>

                        <div className="protItem col-3 col-md-1 tv">
                            <img src="images/bill/tv/1.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 tv">
                            <img src="images/bill/tv/2.png" alt="" className="we-work-img"/>
                        </div>

                        <div className="protItem col-3 col-md-1 water">
                            <img src="images/bill/water/1.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 water">
                            <img src="images/bill/water/2.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 water">
                            <img src="images/bill/water/3.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 water">
                            <img src="images/bill/water/4.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 water">
                            <img src="images/bill/water/5.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 gas">
                            <img src="images/bill/land/3.png" alt="" className="we-work-img"/>
                        </div>

                        <div className="protItem col-3 col-md-1 water">
                            <img src="images/bill/metro/1.png" alt="" className="we-work-img"/>
                        </div>

                        <div className="protItem col-3 col-md-1 land">
                            <img src="images/bill/dth/4.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 data">
                            <img src="images/bill/water/2.png" alt="" className="we-work-img"/>
                        </div>
                        <div className="protItem col-3 col-md-1 prepaid">
                            <img src="images/bill/prepaid/6.png" alt="" className="we-work-img"/>
                        </div> */}
                    </div>
                </div>
            </div>
    )
}

export default ServiceProvidersComponent;
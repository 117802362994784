import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation,
    useNavigate
  } from "react-router-dom";
// import vector from '../images/vector.png';
import vector from '../../images/lgimage.jpg';


import { Toast } from 'primereact/toast';


import axios from 'axios';
import UserProfileHeader from '../utils/UserProfileHeader';
import UserAsidePane from '../utils/UserAsidePane';
import CmsUserAsidePane from './cmsUserAsidePane';


export const  withNavigation = (Component) => {
    return props => <Component {...props} navigate={useNavigate()} />;
  } 



class CmsAdminDashboard extends Component {
    state = {
      email: '',
      sent: false,
      buttonText: 'Send Email'
  }


  formSubmit = (e) => {
    e.preventDefault()
  
    this.setState({
        buttonText: '...sending'
    })
  
    let data = {
        
        email: this.state.email,
    
    }
    
    axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/password/reset-password`, data)
    .then( res => {
        this.setState({ sent: true },
             this.resetForm(),
             this.toast.show({ severity: 'success sent', summary: 'Password changed', detail:'Any Email will be sent with a link to reset your password', life: 3000 }),
             this.props.navigate('/'))
    })
    .catch( res => {

     //this.toast.show({ severity: 'success', summary: 'Password changed', detail:'Your password successfully changed', life: 3000 });
      console.log(res);
     
    //   alert("Message failed to send.");
    })
  }

  resetForm = () => {
    this.setState({
        
        email: '',
    
        buttonText: 'Email Sent'
    })
}



    render() {

        
        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <UserProfileHeader/>

                <div id="content" className="py-4">
                    <div className="container">
                        <div className="row">

                            {/* <!-- Left sidebar --> */}
                            <aside className="col-lg-3 sidebar">
                                <CmsUserAsidePane/>
                            </aside>
                </div>

                    </div>

                       </div>
                                {/* <!-- Left Panel End --> */}
               {/* <section className="page-feature py-5">
                    <div className="container text-center">
                        <div className="row">
                            <div className="col-md-6">
                                <h2 className=" text-left">Forgot Password</h2>
                            </div>
                            <div className="col-md-6">
                                <div className="breadcrumb text-right">
                                   <Link to='/'><a >Home</a></Link>
                                    <span>/ Forgot Password</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}


            </div>
        );
    }
}

export default withNavigation(CmsAdminDashboard);
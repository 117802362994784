import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import UserProfileHeader from './utils/UserProfileHeader';
import { BillerCategoryService } from '../services/BillerCategoryService';
import { BillerService } from '../services/BillerService';
import { SubscriberAccountsService } from '../services/SubscriberAccountsService';
import { RadioButton } from 'primereact/radiobutton';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import UserAsidePane from './utils/UserAsidePane';
import {AuthService} from '../services/AuthService'



class AddFavouriteBiller extends Component {

    constructor(props){
        super(props);

        this.state={
            savedBillers:[],
            selectedBiller:null,
            amount:'',
            sourceReference:'',
            isProcessingPayment:false,
            displayNewAccounttDialog:false,
            billerCategories:[],
            selectedBillerCategory:null,
            displayableBillers:[],
            activeAddRecurringBillerAccountTabIndex:0,
            billers:[],
            sBiller:null,
            currency: localStorage.getItem("currency") == null ? "ZWG": localStorage.getItem("currency"),
            isCurrencyMismatch:false,
            token:'',
            TokenWithUserMsisdn:''

        };
        this.subscriberService = new SubscriberAccountsService();
        this.billerCategory = new BillerCategoryService();
        this.billerService = new BillerService();
        this.authService = new AuthService();
        this.filterBillersByCategoryName = this.filterBillersByCategoryName.bind(this);
        this.handleSaveNewRecurringAccount = this.handleSaveNewRecurringAccount.bind(this);
    }
    handleSaveNewRecurringAccount(){

        // this.authService.getGuestUserTokenWithMsisdn(this.state.msisdn).then(
        //     response => this.setState({TokenWithUserMsisdn: response.data.body}, () => {

                this.billerService.getBillerAccount(this.state.token,this.state.msisdn, this.state.accountNumber, this.state.sBiller.code )
                .then( response => {
                 // console.log(response.data.body.currency );
                    var isCurrencyMismatch = response.data.body.currency === localStorage.getItem("currency") ? false:true
                    // console.log(disableProcessPayment);
                if(isCurrencyMismatch){
                    this.toast.show({ severity: 'error', summary: 'Currency Mismatch', detail: 'Selected currency do not match the account currency.Please change currency', life: 6000 });
                }else{
        
                
                let subscriberDto = {
                    msisdn: this.state.msisdn,
                    billerCode: this.state.sBiller.code,
                    existingAccountNumber: this.state.accountNumber,
                    newAccountNumber: this.state.accountNumber,
                    packageCode: null,
                    currency: localStorage.getItem("currency") == null ? "ZWG": localStorage.getItem("currency"),
                    //pin:this.state.pin,
        
                  }
                ;
                // console.log(currency)
                this.subscriberService.createSubscriberAccount(this.state.token, subscriberDto)
                .then(response => {
                    // console.log(subscriberDto);
                    this.setState({msisdn:'', accountNumber:''});
                    this.toast.show({ severity: 'info', summary: 'Request processed', detail: 'Account saved successfully', life: 3000 })
        
                })
                .catch(error => {
                    this.toast.show({ severity: 'error', summary: 'Request failed', detail: 'Error processing request. Please try again', life: 3000 })
        
                });
            }
            })

                
            // }));
    //     this.billerService.getBillerAccount(this.state.token, this.state.msisdn, this.state.accountNumber, this.state.sBiller.code )
    //     .then( response => {
    //         console.log(response.data.body.currency );
    //         var isCurrencyMismatch = response.data.body.currency == localStorage.getItem("currency") ? false:true
    //         // console.log(disableProcessPayment);
    //     if(isCurrencyMismatch){
    //         this.toast.show({ severity: 'error', summary: 'Currency Mismatch', detail: 'Selected currency do not match the account currency.Please change currency', life: 6000 });
    //     }else{

        
    //     let subscriberDto = {
    //         msisdn: this.state.msisdn,
    //         billerCode: this.state.sBiller.code,
    //         existingAccountNumber: this.state.accountNumber,
    //         newAccountNumber: this.state.accountNumber,
    //         packageCode: null,
    //         currency: localStorage.getItem("currency") == null ? "ZWG": localStorage.getItem("currency"),
    //         //pin:this.state.pin,

    //       }
    //     ;
    //     // console.log(currency)
    //     this.subscriberService.createSubscriberAccount(this.state.token, subscriberDto)
    //     .then(response => {
    //         // console.log(subscriberDto);
    //         this.setState({msisdn:'', accountNumber:''});
    //         this.toast.show({ severity: 'info', summary: 'Request processed', detail: 'Account saved successfully', life: 3000 })

    //     })
    //     .catch(error => {
    //         this.toast.show({ severity: 'error', summary: 'Request failed', detail: 'Error processing request. Please try again', life: 3000 })

    //     });
    // }
    // })
    }


    componentDidMount(){
        var user = JSON.parse(localStorage.getItem('user'));
        var token = localStorage.getItem('token');
        console.log("rete",token);

        this.authService.getToken().then(
            resp=> this.setState({ token: resp.data.body},()=>{
        this.billerCategory.getRecurringBillerCategories(this.state.token)
        .then(response => {
            this.setState({
                billerCategories : response.data.body, 
                selectedBillerCategory: response.data.body[0]
            });
        })
        .catch(error => {
            // console.log(error);
            // this.toast.show({ severity: 'error', summary: 'Request failed', detail: 'Error processing request. Please try again', life: 3000 })

        });

        this.subscriberService.getSubscriberAccounts(token, user.msisdn)
            .then(response => {
                // console.log(response.data.body);
                this.setState({savedBillers:response.data.body});
            })
            .catch(error =>{
                // this.toast.show({ severity: 'error', summary: 'Error get saved accounts', detail: 'There was an error getting the saved billers. Please try again later', life: 3000 });
            });


            this.authService.getToken().then(
                resp=> this.setState({ token: resp.data.body},()=>{

                    this.billerService.getAllBillers(this.state.token)
                    .then(res => {
                        // console.log(res.data.body);
                        this.setState(
                            {
                                billers:res.data.body,
                                sBiller : res.data.body[0],
                                displayableBillers:res.data.body
                            });
                        }
                        )
                    .catch(error => console.log(error));

                    
                }));

       
       
            }))

    }

    filterBillersByCategoryName(){
        var bills = this.state.billers.filter(biller => this.state.selectedBillerCategory.name == biller.billerCategory.name)
        this.setState({displayableBillers : bills, sBiller: bills[0], activeAddRecurringBillerAccountTabIndex:1});

    }
    render() {
        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <UserProfileHeader/> 
                {/* <!-- Content  --> */}
                <div id="content" className="py-4">
                    <div className="container">
                        <div className="row">

                            {/* <!-- Left sidebar --> */}
                            <aside className="col-lg-3 sidebar">
                                <UserAsidePane/>
                                  
                
                            </aside>
                            {/* <!-- Left Panel End --> */}

                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-12">
                                        {/* <!-- Available Balance --> */}
                                        <div className="bg-light shadow-sm text-center mb-3">
                                            <div className="d-flex admin-heading pr-3">
                                                <div className="mr-auto">
                                                    <h2 className="text-9 font-weight-400"><i className="fas fa-wallet"></i> Save Favourite Biller</h2>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        {/* <!-- Available Balance End --> */}
                                    </div>
                                </div>

                                    <hr/>
                                            <div className='row'>
                                                    <TabView activeIndex={this.state.activeAddRecurringBillerAccountTabIndex}  onTabChange={(e) => this.setState({ activeAddRecurringBillerAccountTabIndex: e.index })} >
                                                        <TabPanel header="Biller Category">
                                                                <div className='row' style={{marginTop:'2%'}}>
                                                                    <h3>Choose Biller Category</h3>
                                                                {
                                                                        this.state.billerCategories != null ? this.state.billerCategories.map((billerCategory)=>(
                                                                            <div className="col-sm-4" key={billerCategory.name}>
                                                                                    <div className="card" style={{backgroundColor:'##D5D83D',  borderRadius: "15px", margin:"10px", boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"}}>
                                                                                        <div className="card-body ">
                                                                                            <h6 className="card-title"><RadioButton inputId={billerCategory.name} name="billerCategory" value={billerCategory} onChange={(e) => this.setState({selectedBillerCategory: e.value})}  checked={this.state.selectedBillerCategory.name === billerCategory.name}/>&nbsp;{billerCategory.name}</h6> 
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        )
                                                                        : <></>
                                                                    }

                                                                

                                                                    <div className='row' style={{marginTop:'20px'}}>
                                                                            <div className='col-sm-3'>
                                                                                <Button className="btn btn-filled btn-round" label="Continue"  iconPos="right" onClick={this.filterBillersByCategoryName}/>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                        
                                                            <TabPanel header="Biller">
                                                                <div className='row' style={{marginTop:'2%'}}>
                                                                    <h3>Choose Biller</h3>
                                                                    {
                                                                        this.state.displayableBillers.map((biller)=>(
                                                                            <div className="col-sm-4" key={biller.id}>
                                                                                    <div className="card" style={{backgroundColor:'##D5D83D',  borderRadius: "15px", margin:"10px", boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"}}>
                                                                                        <div className="card-body ">
                                                                                            <h6 className="card-title"><RadioButton inputId={biller.id} name="biller" value={biller} onChange={(e) => this.setState({sBiller: e.value})}  checked={this.state.sBiller.id === biller.id}/>&nbsp;{biller.name}</h6>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        )
                                                                    
                                                                    }

                                                                

                                                                    <div className='row' style={{marginTop:'20px'}}>

                                                                    <div className='col-sm-4'>
                                                                            <Button label="Back" className="btn btn-filled btn-round" onClick={() => {this.setState({activeAddRecurringBillerAccountTabIndex:0})}}/>
                                                                        </div>
                                                                            <div className='col-sm-4'>
                                                                                <Button label="Continue" className="btn btn-filled btn-round" onClick={() => {this.setState({activeAddRecurringBillerAccountTabIndex: 2})}}/>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                        <TabPanel header="Account">
                                                            <div className='row' style={{marginTop:'2%'}}>
                                                                <h3>Account Details</h3>
                                                                <br/>
                                                                <br/>
                                                            
                                                                    <div className="form-group">
                                                                        <label htmlFor="accountNumber">Account Number</label>
                                                                        <input type="text" value = {this.state.accountNumber } className="form-control form-control-lg" id="accountNumber" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}}  onChange={(e) => {
                                                                                                                                    e.persist();
                                                                                                                                    this.setState((state) => (state.accountNumber= e.target.value))
                                                                                                                                    }}/>
                                                                    </div>
                                                                    <br/>
                                                                    <br/>
                                                                    <br/>
                                                                    <br/>
                                                                    <div className="form-group">
                                                                        <label htmlFor="msisdn">Mobile Number</label>
                                                                        <input type="text"  value = {this.state.msisdn } className="form-control form-control-lg" id="msisdn" style={{backgroundColor:'#ebf7f6', borderStyle:'hidden'}}  onChange={(e) => {
                                                                                                                                    e.persist();
                                                                                                                                    this.setState((state) => (state.msisdn= e.target.value))
                                                                                                                                    }}/>
                                                                    </div>
                                                                    <br/>

                                                                    <br/>
                                                                    <div className='row' style={{marginTop:'20px'}}>
                                                                        <div className='col-sm-4'>
                                                                            <Button label="Biller category" className="btn btn-filled btn-round" onClick={() => {this.setState({activeAddRecurringBillerAccountTabIndex:0})}}/>
                                                                        </div>

                                                                        <div className='col-sm-4'>
                                                                            <Button label='Biller' className="btn btn-filled btn-round" onClick={() => {this.setState({activeAddRecurringBillerAccountTabIndex:1})}}/>
                                                                        </div>

                                                                        <div className='col-sm-3'>
                                                                            <Button label="save" className="btn btn-filled btn-round" onClick={this.handleSaveNewRecurringAccount}/>
                                                                        </div>
                                                                    

                                                                    </div>
                                                                
                                                            </div>
                                                        </TabPanel>
                                                        
                                                        
                                                    
                                                    </TabView>
                                                </div>
                                        
                            </div>
                                
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddFavouriteBiller;
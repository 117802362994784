import React from 'react';

function FooterComponent(){

    return(

        <footer className="footer">

            <div className="foo-btm">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <p className="copyright text-left el-sm-center">Copyright © &nbsp; { new Date().getFullYear()}
                                <i className=""></i> <a href="#">&nbsp;EcoCash</a>. All rights reserved.</p>
                        </div>
                    
                    </div>

                </div>
            </div>
        </footer>

    )
}

export default FooterComponent;
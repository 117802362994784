import React, { Component} from 'react';
import OnlinePayements from './OnlinePayements';

export default class CreditStores extends Component {
       render(){ 
           return (
            <div>
                <OnlinePayements category={process.env.REACT_APP_CREDIT_STORES_ID}/>
            </div>
        )
       }
    
};

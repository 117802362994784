import React, { Component } from 'react';

class Schools extends Component {
    render() {
        return (
            <div>
                <h1>Schools</h1>
            </div>
        );
    }
}

export default Schools;
import React, { Component } from 'react';

class BePartner extends Component {
    render() {
        return (
            <div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-8">
                                <h3>Want To Become a Biller</h3>
                                <p>Live Life the EcoCash Way!</p>
                            </div>
                            <div className="col-md-4 text-md-right">
                                <a href="https://partnerapplications.ecocash.co.zw/login" target='_blank' className="btn btn-default">Register Now</a>
                            </div>
                        </div>
                    </div>
            </div>
        );
    }
}

export default BePartner;
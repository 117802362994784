import axios from 'axios';
import { format } from 'date-fns';
import md5 from 'crypto-js/md5';
import CryptoJS from 'crypto-js';
import config from '../components/utils/config';

const key2 = config.encryptionkey2;
const iv = config.encryptioniv;

const key = config.passwordkey;
const source = config.source;
const Username = config.Username;
export class AuthService {  
    getGuestUserTokenWithMsisdn(misdn)
    {   

        // console.log("msisdn",misdn);


       
       
        // const key = "EcoHashingK3y2024";
        // const source = "WEB";
        //const Username = " ";



            // Define a pattern for formatting the date
    const pattern = "yyyyMMdd";

    // Create a Date object and format it using the specified pattern
    const date = format(new Date(), pattern);

    // Create a string by concatenating the input parameters
    const concatenatedString = `${misdn}_${key}_${date}_${source}`;

    // Log the string to be hashed (you can use console.log for logging)
    // console.log("***** String to be hashed ***** : ", concatenatedString);

    // Use MD5 hashing to generate the checksum and convert it to uppercase
    const md5Checksum = md5(concatenatedString).toString().toUpperCase();

    // console.log("md5checksum",md5Checksum)

    const data ={        
          username: misdn,          
        password: md5Checksum, 
        source: "WEB",
        userType: "GUEST"
            }



    // const key2 = 'b!5Tb+EjdKyaYj6{M/atWSdkghjd8945';
    // const iv = '1234567890000000';
    
    // Convert object to JSON string
    const jsonString = JSON.stringify(data);
    // console.log('concatenatedString:', user);
    let keyHex = CryptoJS.enc.Utf8.parse(key2);
    let ivHex = CryptoJS.enc.Utf8.parse(iv);
    let messageHex = CryptoJS.enc.Utf8.parse(jsonString);
    
    let encrypted = CryptoJS.AES.encrypt(messageHex, keyHex, {
        iv: ivHex,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

   const finalText  = encrypted.toString();


   console.log("data",finalText);

        return axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/auth/generatetoken`,{
            encryptedData:finalText
        }, {
            headers: {
                "Content-Type":"application/json"
            }
        })  
     }  
    
    getToken()
     {  
        //console.log("msisdn",misdn);
        //const Username = '';
      
       
        // const key = "EcoHashingK3y2024";
        // const source = "WEB";
        // const Username = "GuestUser";



            // Define a pattern for formatting the date
    const pattern = "yyyyMMdd";

    // Create a Date object and format it using the specified pattern
    const date = format(new Date(), pattern);

    // Create a string by concatenating the input parameters
    const concatenatedString = `${Username}_${key}_${date}_${source}`;

    // Log the string to be hashed (you can use console.log for logging)
    // console.log("***** String to be hashed ***** : ", concatenatedString);

    // Use MD5 hashing to generate the checksum and convert it to uppercase
    const md5Checksum = md5(concatenatedString).toString().toUpperCase();

    // console.log("md5checksum",md5Checksum)


   const data = {

             username: Username,          
                password: md5Checksum, 
                source: "WEB",
	            userType: "GUEST"


    }




    // const key2 = 'b!5Tb+EjdKyaYj6{M/atWSdkghjd8945';
    // const iv = '1234567890000000';
    
    // Convert object to JSON string
    const jsonString = JSON.stringify(data);
    // console.log('concatenatedString:', user);

    let keyHex = CryptoJS.enc.Utf8.parse(key2);
    let ivHex = CryptoJS.enc.Utf8.parse(iv);
    let messageHex = CryptoJS.enc.Utf8.parse(jsonString);
    
    let encrypted = CryptoJS.AES.encrypt(messageHex, keyHex, {
        iv: ivHex,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

   const finalText  = encrypted.toString();
 

   //console.log("data",finalText)




        

        return axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/auth/generatetoken`,{
            encryptedData:finalText
        }, {
            headers: {
                "Content-Type":"application/json"
            }
        })   
     }   

 getTokenWithUsernameAndPassword(username, password)
          { 
            const data ={

                username: `${username}`,      
                password: `${password}`, 
                source: "WEB",
                userType: "ACCOUNT_HOLDER"


            }

    // const key2 = 'b!5Tb+EjdKyaYj6{M/atWSdkghjd8945';
    // const iv = '1234567890000000';

            // Convert object to JSON string
    const jsonString = JSON.stringify(data);
    // console.log('concatenatedString:', user);
    let keyHex = CryptoJS.enc.Utf8.parse(key2);
    let ivHex = CryptoJS.enc.Utf8.parse(iv);
    let messageHex = CryptoJS.enc.Utf8.parse(jsonString);
    
    let encrypted = CryptoJS.AES.encrypt(messageHex, keyHex, {
        iv: ivHex,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

   const finalText  = encrypted.toString();

   
  
                return  axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/auth/generate_user_token`,{
                    encryptedData:finalText
                }, {
                    headers: {
                        "Content-Type":"application/json"
                    }
                }) 
                          }}  
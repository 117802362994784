import React, {Component, useContext, useEffect, useRef, useState} from 'react';
import "aos/dist/aos.css";
import {Toast} from 'primereact/toast';
import axios from 'axios';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserAsidePane from './utils/UserAsidePane';
import UserProfileHeader from "./utils/UserProfileHeader";
import {Context} from "./Store";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import ConfirmationModal from "./ConfirmationModal";
import {BundleContext} from "../config/contexts";
import AirtimePurchase from "./AirtimePurchase";
import { BuyAirtime } from './facebookPixelEvent';

const currencyCode = [
  
    {label: "ZWG", value: "ZWG"},
    {label: "USD", value: "USD"},
];


export default function () {


    useEffect(() => {
        BuyAirtime();
      },[]);
    
    
    const {numberOption, numberOptions, airtimeState, setAirtimeState, setNumberOption,formSubmit, buttonHandler, setRenderComponent} = useContext(BundleContext)
    return (
        <>
                <FormControl>
                    {/*<FormLabel id="airtime-radio-buttons-group-label" className="text-4 mb-3"><h5*/}
                    {/*    className="text-4 mb-3">Buy airtime for</h5></FormLabel>*/}
                    <RadioGroup
                        aria-labelledby="airtime-radio-buttons-group-label"
                        defaultValue={numberOptions.myNumber}
                        row
                        name="radio-buttons-group"
                        onChange={(e) => setNumberOption(e.target.value)}
                    >
                        <FormControlLabel value={numberOptions.myNumber} control={<Radio/>} label="My Number"/>
                        <FormControlLabel value={numberOptions.otherNumber} control={<Radio/>} label="Other Mobile"/>
                    </RadioGroup>
                </FormControl>
            <div className="row">
                <div className="m-auto">
                    <form onSubmit={(e) => formSubmit(e)} method="POST">
                        <div className="row">
                            <div className="form-group col-sm-6">
                                <label htmlFor="payingMsisdn">My Mobile Number<small>(required)</small></label>
                                <input name="payingMsisdn"
                                       className="form-control" type="text"
                                       required="required"
                                       placeholder="Enter Mobile Number (07********)"
                                       value={airtimeState.payingMsisdn}
                                       onChange={e => setAirtimeState((s) => ({
                                           ...s,
                                           payingMsisdn: e.target.value
                                       }))}
                                    // disabled
                                    // value={airtimeState.msisdn}
                                />
                            </div>
                            {
                                numberOption !== numberOptions.myNumber && (
                                    <div className="form-group col-sm-6">
                                        <label htmlFor="receivingMsisdn">Receiving Mobile
                                            Number<small>(required)</small></label>
                                        <input name="receivingMsisdn"
                                               className="form-control" type="text"
                                               required="required"
                                               placeholder="Enter Mobile Number (07********)"
                                               value={airtimeState.receivingMsisdn}
                                               onChange={e => setAirtimeState((s) => ({
                                                   ...s,
                                                   receivingMsisdn: e.target.value
                                               }))}
                                        />
                                    </div>
                                )
                            }
                            <div className="form-group col-sm-6">
                                <label htmlFor="currencyCode">Select
                                    Currency <small>(required)</small></label>
                                {/*<div className="col-md-12">*/}
                                {/* <Select options={currencyCode}
                                        placeholder="Select Currency"
                                        className="form-control test-class"
                                        required="required"
                                        InputValue={airtimeState.currencyCode}
                                        onChange={e => setAirtimeState((s) => ({
                                            ...s,
                                            currencyCode: e.value
                                        }))}/> */}
{/* 
                                <select 
                                    InputValue={airtimeState.currencyCode}

                                    onChange={e => {setAirtimeState((s) => ({
                                        ...s,
                                        currencyCode: e.target.value
                                            })); console.log(e)}}
                                            
                                            className="form-control"
                                            >
                                        <option>ZWG</option>
                                        {/* <option>USD</option>   */}


                                <Select options={currencyCode}
                                                        placeholder="Select Currency"
                                                        clasName="form-control test-class"
                                                        required="required"
                                                        InputValue={airtimeState.currencyCode}
                                                        onChange={e => setAirtimeState((s) => ({
                                                            ...s,
                                                            currencyCode: e.value
                                                        }))}/>
                            </div>
                            <div className="form-group col-sm-6">
                                <label htmlFor="amount">Amount<small>(required)</small></label>
                                <input name="msisdn"
                                       className="form-control" type="text"
                                       required="required"
                                       placeholder="Enter Amount"
                                       value={airtimeState.amount}
                                       onChange={e => setAirtimeState((s) => ({
                                           ...s,
                                           amount: e.target.value
                                       }))}
                                />
                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="amount">Ecocash Pin<small>(required)</small></label>
                                <input name="pin"
                                       className="form-control" type="password"
                                       required="required"
                                       placeholder="Enter your Pin"
                                       value={airtimeState.mpin}
                                       onChange={e => setAirtimeState((s) => ({
                                           ...s,
                                           mpin: e.target.value
                                       }))}
                                />
                            </div>
                        </div>
                        {/*<button type="submit"*/}
                        {/*        className="btn btn-default">{airtimeState.buttonText}</button>*/}
                    </form>
                    <button type="button" onClick={(e) => {buttonHandler(e); setRenderComponent(true)}}
                            className="btn btn-default">{airtimeState.buttonText}</button>
                </div>

            </div>
        </>
    );
}

// export default airtimeStatement;

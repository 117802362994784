import axios from 'axios';
import { format } from 'date-fns';
import md5 from 'crypto-js/md5';
import CryptoJS from 'crypto-js';


export class DecryptionService {  

    decryptData(encryptedData) {

        const key2 = 'b!5Tb+EjdKyaYj6{M/atWSdkghjd8945';
        const iv = '1234567890000000';

        let keyHex = CryptoJS.enc.Utf8.parse(key2);
        let ivHex = CryptoJS.enc.Utf8.parse(iv);
     
        let decrypted = CryptoJS.AES.decrypt(encryptedData, keyHex, {
            iv: ivHex,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
     
        // Convert the decrypted data to a string
        let decryptedText = CryptoJS.enc.Utf8.stringify(decrypted);
        
     
        return decryptedText;
    }
     




}
import axios from 'axios';

export class SubscriberAccountsService {

    getSubscriberAccounts(token, msisdn){
      const getData="data";
        return axios.post(
          
            `${process.env.REACT_APP_BACKEND_HOST_URL}/subscriber-account/find-by-msisdn/${msisdn}`,getData,
            {
            headers: {
                Authorization:
                `Bearer ${token}`,
            },
            }
        )
    }

    createSubscriberAccount(token, subscriberDto){
        const options = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };
    
        return axios.post( `${process.env.REACT_APP_BACKEND_HOST_URL}/subscriber-account/create`, subscriberDto, options) 
    }

    markAsRecurringPayment(token, recurringPaymentDto){
        const options = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };
    
        return axios.post( `${process.env.REACT_APP_BACKEND_HOST_URL}/subscriber-account/enable-recurring`, recurringPaymentDto, options) 
    }

  disableRecurringPayment(token, recurringPaymentDto){
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

    return axios.post( `${process.env.REACT_APP_BACKEND_HOST_URL}/subscriber-account/disable-recurring`, recurringPaymentDto, options) 
  }

}
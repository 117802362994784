import React, {useContext, useEffect, useLayoutEffect, useState} from "react";
import {BundleCategoryContext, BundleContext} from "../config/contexts";
import BundlesCarousel from "./BundlesCarousel";
import axios from "axios";
import { AuthService } from '../services/AuthService';

const BundlesHome = () => {
    const {numberOptions, numberOption, airtimeState, show, setResponseBundleCategory, setIsPaymentConfirmation, selectedBundleCategory, setSelectedBundleCategory} = useContext(BundleContext)
    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState()
    const [bundleCategory, setBundleCategory] = useState({})
    const [carouselIndex, setCarouselIndex] = useState(0)
    const [proceed, setProceed] = useState(false);
    const authService = new AuthService();

    useEffect(() => {
        proceed && getBundleCategory()
    }, [proceed])

    const handleBundleCategory = (e) => {
        e.preventDefault()

        if (!selectedCategory){
            show({
                severity: 'error',
                summary: 'Failed',
                detail: 'Please select bundle category!',
                life: 3000
            })
        }else if (!airtimeState.payingMsisdn) {
            show({
                severity: 'error',
                summary: 'INPUT MY NUMBER',
                detail: 'Kindly input paying number!',
                life: 3000
            });
        } else if (!airtimeState.receivingMsisdn && numberOption !== numberOptions.myNumber) {
            show({
                severity: 'error',
                summary: 'INPUT OTHER NUMBER',
                detail: 'Kindly input receiving number!',
                life: 3000
            });
        } else if (!bundleCategory.currencyCode) {
            show({
                severity: 'error',
                summary: 'Select currency',
                detail: 'Kindly choose the currency option',
                life: 3000
            });
        } else {
            setProceed(true)
        }
    }

    const getBundleCategory = () =>{
       new AuthService().getToken().then((response)=>{


        axios.get(`${process.env.REACT_APP_BACKEND_HOST_URL}/bundles/find-by-category-and-currency/${selectedCategory}/${bundleCategory.currencyCode}`,
        {
                    headers: {
                        'Authorization': `Bearer ${response.data.body}`
                    }
                    }
        )
            .then((res) => {
                const {body} = res.data;
                setResponseBundleCategory(body)
                console.log(res)
            })
            .catch((e) => {
                show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: 'Bundle categories could not be fetched!',
                    life: 3000
                })
            })
        })
    }

    const handleBundleConfirmation = (e) => {
        e.preventDefault()
        console.log('selected bundle category', selectedBundleCategory)
    if (!selectedBundleCategory?.id) {
            show({
                severity: 'error',
                summary: 'Select Specific Bundle',
                detail: 'Select the specific bundle you want to purchase',
                life: 3000
            });
        } else {
            setProceed(false)
            setIsPaymentConfirmation(true)
        }
    }

    return(
        <BundleCategoryContext.Provider value={{categories, setCategories, carouselIndex, setCarouselIndex,selectedBundleCategory, setSelectedBundleCategory, proceed,
            selectedCategory, setSelectedCategory, bundleCategory, setBundleCategory, handleBundleCategory, handleBundleConfirmation
        }}>
            <div>
                <BundlesCarousel />
            </div>
        </BundleCategoryContext.Provider>
    )
}

export default BundlesHome;
import axios from 'axios';

export class BillerPackageService{

    getByBillerCode(token, billerCode){
        return axios.get(
            `${process.env.REACT_APP_BACKEND_HOST_URL}/billerPackage/findbybillercode/${billerCode}`,
            {
            headers: {
                Authorization:
                `Bearer ${token}`,
            },
            }
        )
     }
}
import React, { Component } from 'react';
import OnlinePayements from './OnlinePayements';

export default class Councils extends Component {
   
       render(){ return (
            <div>
                <OnlinePayements category={process.env.REACT_APP_COUNCILS_ID}/>
            </div>
        )
       }
    
};

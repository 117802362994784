import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import car1 from '../../images/s1.jpg';
import car2 from '../../images/s2.jpg';
import car3 from '../../images/s3.jpg';


function CarouselComponent(){
    return(
        <OwlCarousel className="owl-carousel hero-slider" 
            items={1}  
            margin={3}
            loop={true}
            autoplay={true}
            autoplayTimeout={3000}
            autoplayHoverPause={true}
            animateIn= 'fadeIn'
            animateOut= 'fadeOut'
        >
        <div className="item" style={{backgroundImage: `url(${car1})`}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-md-7">
                        <div className="banner-content">
                            <h2>Online Tokens Recharge</h2>
                            <p>Delivering simple, fast and reliable online bill payments!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="item" style={{backgroundImage:`url(${car2})`}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-md-7">
                        <div className="banner-content">
                            <h2>Utility Bill Payments</h2>
                            <p>Delivering simple, fast and reliable online bill payments!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="item" style={{backgroundImage:`url(${car3})`}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-md-7">
                        <div className="banner-content">
                            <h2>Online Bill Payments</h2>
                            <p>Delivering simple, fast and reliable online bill payments!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </OwlCarousel>
    )
}
export default CarouselComponent
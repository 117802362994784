import React from 'react';
import Button from "@material-ui/core/Button";
import {Modal, Form, FormControl, FormLabel} from 'react-bootstrap';
//import AirtimePurchase from "./AirtimePurchase";
//import BundlePurchase from "./BundlePurchase";

import BundlePurchase from './BulkBundlePurchase';
import AirtimePurchase from './BulkAirtimePurchase';

const ConfirmationModal = ({show, toggle, processPayment,  setPin, renderComponent, data, bundleData}) => {
    return (
        <div>
            <Modal show={show} onHide={() => toggle(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>CONFIRMATION </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div>
                            <p>Confirm the below transaction details</p>
                        </div>
                            {renderComponent ? <AirtimePurchase data={data}/> : <BundlePurchase bundleData={bundleData}/>}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {/* <div className="col-lg-4 col-md-5 m-auto text-left">
                        <FormLabel>Enter EcoCash Pin</FormLabel>
                        <FormControl
                            type="pin"
                            className="form-control"
                            type="password"
                            autoFocus
                            placeholder="Four digit PIN"

                            onChange={e => setPin((s) => ({
                                ...s,
                                mpin: e.target.value
                            }))}
                        />
                    </div> */}
                    <div>
                        <Button variant="secondary" onClick={() => toggle(false)}>
                            CANCEL
                        </Button>
                        <Button variant="primary" type='submit' onClick={(e) => { processPayment(e) }}>
                            CONFIRM
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
};

export default ConfirmationModal;
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
// import car1 from '../../images/s1.jpg';
// import car2 from '../../images/s2.jpg';
// import car3 from '../../images/s3.jpg';

import React from 'react';
import ReactDOM from 'react-dom';
var listOfImages =[];

class AdvertBanner extends React.Component{
    importAll(images) {
        return images.keys().map(images);
    }
    componentWillMount() {
        listOfImages = this.importAll(require.context('../../images/banner/', false, /\.(png|jpe?g|svg)$/));
    }
    render(){
        return(

       

            <div className="container">
                    <div className="row">
                        <div className=" col-md-12 m-auto text-center">
                            <div className="sec-heading mb-4">
                                <span className="tagline">Our Products</span>
                                <h3 className="sec-title"> Advertisement </h3>
                            </div>
                        </div>
                    </div>

            <OwlCarousel className="owl-carousel hero-slider" 
            items={1}  
            margin={3}
            loop={true}
            autoplay={true}
            autoplayTimeout={3000}
            autoplayHoverPause={true}
            animateIn= 'fadeIn'
            animateOut= 'fadeOut'
        >
            
            {listOfImages.map((img, index) => {
                return (

                
                    <div  className="col-md-12 m-auto" >
   
                
                          <img key={index} src={img} alt={img} />
                        
                    </div>
                   
                    
                  
                )
            })}


        </OwlCarousel>

        </div>
       
        
         
        )
    }
}


export default AdvertBanner;
import axios from 'axios';

export class UserService {

    create(userDto,token){
        const options = {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
          };
      
    
        return axios.post( `${process.env.REACT_APP_BACKEND_HOST_URL}/user/register`,{encryptedData:userDto}, options)
    }

    getUser(token, username){
     const getData ="data";
      const options = {
        headers: {
          "Content-Type": "application/json",
           Authorization: `Bearer ${token}`,
        },
      };
  

    return axios.post( `${process.env.REACT_APP_BACKEND_HOST_URL}/user/find-by-username/${username}/`, getData,options)
    }
}
import React, { Component, useState, useContext, useRef } from 'react';
import "aos/dist/aos.css";
//import { AuthService } from '../services/AuthService';
//import vector from '../images/vector.png';
import vector from '../../images/lgimage.jpg';

import { UserService } from '../../services/UserService';
import { Toast } from 'primereact/toast';
import {Link , Navigate, useNavigate} from 'react-router-dom';
// import { Context } from "./Store";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
// import { signInEvent } from './utils/facebookPixelEvent';
// import { SignIn } from './facebookPixelEvent';


const buttoneText ={
    buttonText: 'Login',     
}

function CmsSignIn (){

     const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const [token, setToken] = useState(null);
    const [username, setUsername ] = useState('');
    const [password, setPassword] = useState('');
    const [user, setUser] = useState(null);
    //const [state, dispatch] = useContext(Context);
    const toast = useRef(null);

    const [buttonstate, setButtonstate ] = useState(buttoneText);
    

    // let authService = new AuthService();
    // let userService = new UserService();
    let navigate = useNavigate();




    // const hangleLoggingIn =  () => {

    //     if (username == '' || password == ''){
    //         toast.show({ severity: 'info', summary: 'Invalid credentials', detail: 'Enter username and password', life: 3000 })
    //         return;
    //     }

    //     authService.getTokenWithUsernameAndPassword(username, password)
    //     .then(response => {
    //         console.log(response.data);
    //         if(response.data.status == 200){
    //             console.log( signInEvent() + "test event");
    //             signInEvent();
    //             setToken(response.data.body)
    //             localStorage.setItem('token', response.data.body);
                
    //             userService.getUser(response.data.body, username)
    //             .then(usr => {
    //                 if(usr.data.status == 200){
    //                     localStorage.setItem('user', JSON.stringify(usr.data.body));
    //                     localStorage.setItem('isLoggedIn', true);
    //                     dispatch({
    //                         type: "LOG_IN", // The name of the reducer
    //                         payload: JSON.stringify(usr.data.body), // The new state of the reducer
    //                       });

    //                       setButtonstate((s) => ({...s, buttonText: 'login....'}));
                          

    //                       navigate("/dashboard", { replace: true });

    //                       SignIn();

    //                       setOpen(!open);
    //                 }else{
    //                          toast.current.show({ severity: 'error', summary: 'Log in failed', detail: 'Failed to log you in. please try again later', life: 3000 })
    
    //                 }
                   
    //             })
    //             .catch(error => {
    //                 console.log(error);
    //                  toast.current.show({ severity: 'error', summary: 'Log in failed', detail: 'Failed to log you in. please try again later', life: 3000 })
    //             })
    //         }else{
    //                 toast.current.show({ severity: 'error', summary: 'Log in failed', detail: 'Failed to log you in. please try again later', life: 3000 })

    //         }
            
    //     })
    //     .catch(error => {
    //         console.log(error);
    //         // this.toast.show({ severity: 'error', summary: 'Log in failed', detail: 'Failed to log you in. please try again later', life: 3000 })
    //     });
    // }
       
            return (
                
                <div >
                <Toast ref={toast} />
                     <section className="page-feature py-5">
                        <div className="container text-center">
                            <div className="row">
                                <div className="col-md-6">
                                    <h2 className=" text-left">Login</h2>
                                </div>
                                <div className="col-md-6">
                                    <div className="breadcrumb text-right">
                                        <Link to='/'><a >Home</a></Link>
                                        <span>/ Login</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="banner login-registration">
                        <div className="vector-img">
                            <img src={vector} alt=""/>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="content-box">
                                        <h2>Login Account</h2>
                                        <p>Welcome to EcoCash Online Bill Payments</p>
                                    </div>
                                    {/* <form action="#" className="sl-form"> */}
                                    <div className="sl-form">
                                        <div className="form-group">
                                            <label>Username</label>
                                            <input type="text" id='username' name='username' placeholder="username" required onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                setUsername(e.target.value)
                                                                                                                // this.setState((state) => (state.username= e.target.value))
                                                                                                                }}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input type="password" id='password' name='password' placeholder="Password" required onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                setPassword(e.target.value)
                                                                                                                // this.setState((state) => (state.password= e.target.value))
                                                                                                                }}/>
                                        </div>
                                        
                                        <button className="btn btn-filled btn-round" ><span className="bh"></span> <span>{buttonstate.buttonText}</span></button>

                                        

                                    
                                    <p className="notice"><Link to='/forgot-password'><a >Forgot Password?</a></Link></p>
                                        <p className="notice">Don’t have an account?<Link to='/sign-up'><a >Sign up now</a></Link></p>
                                    </div>
                                    {/* </form> */}
                                </div>
                            </div>
                        </div>
                    </section>
                
                </div>
            );
        }


export default CmsSignIn;
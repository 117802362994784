import React, { Component } from 'react';

class FAQs extends Component {
    render() {
        return (
            <div>
                <h1>Frequently Asked Questions</h1>
            </div>
        );
    }
}

export default FAQs;
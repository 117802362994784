import React, {useRef, useState, useContext,useEffect} from 'react';
import {Toast} from "primereact/toast";
//import ConfirmationModal from "./ConfirmationModal";
import ConfirmationModal from './BulkConfirmationModal';
import UserProfileHeader from "./utils/UserProfileHeader";
import UserAsidePane from "./utils/UserAsidePane";
import Switch from "react-switch";
import axios from "axios";
import Airtime from "./Airtime";
import Bundles from "./Bundles";

import BulkAirtime from './BulkAirtime';
import BulkBundles from './BulkBundles';


//import  {pasreDataContext} from './BulkAirtime';

import Papa from 'papaparse';


import BundlesCarousel from "./BundlesCarousel";
import {BundleContext} from "../config/contexts";
import BundlesHome from "./BundlesHome";
import {useNavigate} from "react-router-dom";
import AirtimeBundleSwitch from "./utils/AirtimeBundleSwitch";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import ReactPolling from "react-polling";
import {Dialog} from 'primereact/dialog';
import {ProgressSpinner} from 'primereact/progressspinner';
import { Modal, Button, Form } from "react-bootstrap";
import { AuthService } from '../services/AuthService';


import imgAirtime from '../../src/buyairtime.png'
import imgBundles from '../../src/buybundles.png'
import BulkBundlesHome from './BulkBundlesHome';
import ChanelUserBulkAirtime from './ChanelUserBulkAirtime';



const astate = {
    msisdn: '',
    payingMsisdn: '',
    //receivingMsisdn: '',
    currencyCode: '',
    pricePlanCode: 'VOICE',
    //amount: '',
    source: 'WEB',
    mpin: '',
    buttonText: 'Buy Airtime',
};

const aaData = {
    payingMsisdn: '',
    receivingMsisdn: '',
    currencyCode: '',
    pricePlanCode: '',
    amount: 0,
    source: '',
    mpin: '',
}
const csvDatalist = [
    ["PhoneNumber"],
    ["07XXXXXXXX"],
    ["07XXXXXXXX"]
  ];




  


const ChannelUsersBulkAirtimeBundles = () => {

    let navigate = useNavigate();
    const ref = useRef();

   
    
    const [airtimeState, setAirtimeState] = useState(astate);
    const [receivingPhoneNumber, setreceivingPhoneNumber] = useState([]);
    const [responseBundleList,  setResponseBundleList] = useState([]);
    const [receivingAmount, setreceivingAmount] = useState([]);
    const [receivingBulkPhoneNumber, setreceivingBulkPhoneNumber] = useState([]);

    const [recesponseDto, setrecesponseDto] = useState(null);
    //const [numberOption, setNumberOption] = useState(numberOptions.myNumber);
    const [isPaymentConfirmation, setIsPaymentConfirmation] = useState(false);
    const [responseBundleCategory, setResponseBundleCategory] = useState([])
    const [renderComponent, setRenderComponent] = useState(true)
    const [selectedBundleCategory, setSelectedBundleCategory] = useState([])
    const toast = useRef(null);
    const carousel = useRef(null);
    const [sourceReference, setSourceReference] = useState(null);
    const [bulkbundleDto,, setbulkbundleDto] = useState(null);
    // const parsedData = localStorage.getItem('parsedData');
    // console.log(parsedData);

    const [responseMisdnAmount, setresponseMisdnAmount] = useState([]);
    const [showUpload, setShowUpload] = useState(false);
    const authService = new AuthService();


  
    


    const [csvData, setcsvData] = useState([]);




    const [isProcessingPayment, setProcessingPayment] = useState(false);


    const [toggleState, setToggleState] = useState(false)
    const [ token, setToken ] = useState('');

     // State to store parsed data
  const [parsedData, setParsedData] = useState([]);

  //State to store table Column name
  const [tableRows, setTableRows] = useState([]);

  //State to store the values
  const [values, setValues] = useState([]);

  const [parsedDataBulkBundle, setParsedDataBulkBundle] = useState([]);

  //State to store table Column name
  const [tableRowsBulkBundle, setTableRowsBulkBundle] = useState([]);

  //State to store the values
  const [valuesBulkBundle, setValuesBulkBundle] = useState([]);

  //const [ref, setref] = useState([]);






        const changeHandlerBulkAirtime = (event) => {
            // Passing file data (event.target.files[0]) to parse using Papa.parse
            Papa.parse(event.target.files[0], {
              header: true,
              skipEmptyLines: true,
              complete: function (results) {
                const rowsArray = [];
                const valuesArray = [];




        
                // Iterating data to get column name and their values
                results.data.map((d) => {
                  rowsArray.push(Object.keys(d));
                  valuesArray.push(Object.values(d));
                });

                const receivingNumberAmount = results.data.map((student, index) =>
                 { return {
                           receivingMsisdn:student.PhoneNumber,
                           amount:student.Amount,
                           payingMsisdn: airtimeState.payingMsisdn,
                           currencyCode: airtimeState.currencyCode,
                           pricePlanCode: 'VOICE',
                           source: 'WEB',
                           mpin: airtimeState.mpin,
                           pin : airtimeState.mpin

                } });

                        setreceivingAmount(() => ([...receivingNumberAmount,]));
                        
                        // Parsed Data Response in array format
                        setParsedData(results.data);
                        //console.log(results.data);
        
                        // Filtered Column Names
                        setTableRows(rowsArray[0]);
                       //console.log(tableRows);
                
                        // Filtered Values
                        setValues(valuesArray);
                       //console.l og(values);
        
                      },
                    });
                  };


                 // console.log(receivingAmount); 

                  const nuevo = values.map((i,value) => Number(value.Amount));
                  let iyear = parseInt(values, 10);
                  //const arrSum = parsedData.reduce(parseInt(a,b) => a + b.Amount, 0)
                  const totalAmountAirtime = (parsedData.reduce((total,currentItem) =>  total = total + parseInt(currentItem.Amount,10) , 0 ));
                  //console.log(totalAmountAirtime); 

                 // console.log(parsedData);
                //   status: 200,
                //   message: 'Successful Recharge', 
                //   responseCode: '000', 
                //   reference: 'CTR.190722.084717.904.ECO.WH5K'
                //   public String receiving_msisdn;
    
                useEffect(() => {
                //     const responsevalues = responseBundleList.map((response, index) =>
                //         { return {
                //         receiving_msisdn : '0777222904',
                //         message: response.message,
                //         reference: response.reference
                //     } });

                 setreceivingPhoneNumber(selectedBundleCategory);
                setrecesponseDto(responseBundleList);
                //setbulkbundleDto(BundlesAndreceivingNumber);
                // console.log('response',recesponseDto)
                
                });
                //console.log('response',recesponseDto)


                 const changeHandlerBulkBundlesAirtime = (event) => {
                    // Passing file data (event.target.files[0]) to parse using Papa.parse
                    Papa.parse(event.target.files[0], {
                      header: true,
                      skipEmptyLines: true,
                      complete: function (results) {
                        const rowsArray = [];
                        const valuesArray = [];
                        const test =[];
                        
                        // Iterating data to get column name and their values
                        results.data.map((d) => {
                          rowsArray.push(Object.keys(d));
                          valuesArray.push(Object.values(d));
                        });
                        
        
                        const receivingBulkNumber = results.data.map((student, index) =>
                         { 
                            return{

                                
                            receivingMsisdn:student.PhoneNumber
                                  

                            }
                            

                                //receivingMsisdn:student.PhoneNumbe
         
                                //[ selectedBundleCategory,{receivingMsisdn:student.PhoneNumber}]

                              
                      });
                             
                              setreceivingBulkPhoneNumber(() => ([...receivingBulkNumber]));
                                // Parsed Data Response in array format
                                setParsedDataBulkBundle(results.data);
                                //console.log(results.data);
                
                                // Filtered Column Names
                                setTableRowsBulkBundle(rowsArray[0]);
                               //console.log(tableRows);
                        
                                // Filtered Values
                                setValuesBulkBundle(valuesArray);
                               //console.l og(values);
                               
                
                              },
                            });
                          };


                          //localStorage.setItem("parseddataBulkBundle", JSON.stringify(parsedDataBulkBundle));

                        //   bundleCategory: "Facebook"
                        //   countryCode: "ZWE"
                        //   currencyCode: "ZWG"
                        //   displayText: "Facebook (20MB) ZWG 50.00"
                        //   id: 2853
                        //   imageUrl: "http://192.168.84.162/online-payments/images/econetfooter.png"
                        //   mpin: 4827
                        //   payingMsisdn: "0777222904"
                        //   price: 50
                        //   pricePlanCode: "fbsnap_daily"


                        //   payingMsisdn": "774222642",
                        // "receivingMsisdn": "785923101",
                        // "pricePlanCode": "VOICE",
                        // "amount": 200,
                        // "mPin": "4827",
                        //     "pin": "4827",
                        // "currencyCode": "ZWG",
                        // "source":"USSD"
                    const selectedBundleCategoryArray = [{...selectedBundleCategory}]
                    //const a = [{...receivingBulkPhoneNumber}]
                    const [BundlesAndreceivingNumber] = selectedBundleCategoryArray.map((r)=>{
                        return (receivingBulkPhoneNumber.map((d)=>{
                            return {
                                
                                payingMsisdn: r.payingMsisdn,
                                receivingMsisdn : d.receivingMsisdn,
                                pricePlanCode : r.pricePlanCode,
                                amount : r.price,
                                mPin : airtimeState.mpin,
                                pin : airtimeState.mpin,
                                currencyCode : r.currencyCode,
                                source:'WEB',
                                bundleCategory: r.bundleCategory

                            }
    
                            }))
                        });

                        
                    //const arr3 = [a,b];
                    
                    //console.log(BundlesAndreceivingNumber);
                    

    

        ///console.log(responseBundleList);
     
 
    const data = {
        payingMsisdn:  airtimeState.payingMsisdn,
        currencyCode: airtimeState.currencyCode,
        pricePlanCode: 'VOICE',
        source: 'WEB', 
        mpin: airtimeState.mpin,
        totalRecevingNumbersBulkBundles: valuesBulkBundle.length,
        totalRecevingNumbers: values.length,
        totalAmountAirtime : totalAmountAirtime,
    }

    //console.log('data',data);

    const bundleD = {
                    
        ...selectedBundleCategory,
        //...receivingBulkPhoneNumber,
       
    }

    

  

    const econetOptions = [{
        value: 'airtime', img: imgAirtime
    },{
        value: 'bundles', img: imgBundles
    }
    ];

    
         

    const buttonHandler = async (e) => {
        e.preventDefault()

        if (!airtimeState.payingMsisdn) {
            show({
                severity: 'error',
                summary: 'INPUT MY NUMBER',
                detail: 'Kindly input paying number!',
                life: 3000
            });
        } 
        // else if (!airtimeState.receivingMsisdn ) {
        //     show({
        //         severity: 'error',
        //         summary: 'INPUT OTHER NUMBER',
        //         detail: 'Kindly input receiving number!',
        //         life: 3000
        //     });
        // } 
        
        else if (!airtimeState.currencyCode) {
            show({
                severity: 'error',
                summary: 'Select currency',
                detail: 'Kindly choose the currency option',
                life: 3000
            });
        } 
        // else if (!airtimeState.amount) {
        //     show({
        //         severity: 'error',
        //         summary: 'INPUT AMOUNT',
        //         detail: 'Enter Airtime Amount!',
        //         life: 3000
        //     });
        // }
         else {
            setIsPaymentConfirmation(true)
        }
    };

const formSubmit = async (e) => {
    e.preventDefault()

   
        setAirtimeState((s) => ({...s, buttonText: renderComponent ? '...buying airtime' : '...buying bundles'}))
        const airtimeUrl = `${process.env.REACT_APP_BACKEND_HOST_URL}/bundles/purchase/bulk/partner`;
        const bundleUrl = `${process.env.REACT_APP_BACKEND_HOST_URL}/bundles/purchase/bulk/partner`;
        // console.log(bundleUrl,'bundelurl');
        // console.log(airtimeUrl,'bundelurl');

        
        authService.getToken().then((response)=>{
            setToken(response.data.body)
        axios.post(renderComponent ? airtimeUrl : bundleUrl, renderComponent ? receivingAmount : BundlesAndreceivingNumber,
            {
                headers: {
                    'Authorization': `Bearer ${response.data.body}`
                }
                }
            )
            .then((res) => {
                setResponseBundleList(res.data.body);
                // console.log('testeresponse',res);
               
               if(res.data.body.length !== 0 ){
                    //setSourceReference(res.data.body.referenceNumber);
                    //setProcessingPayment(true);

        
                
                //console.log(res)
 
                    show({
                        severity: 'success',
                        summary: 'Payment success',
                        detail: `Recharged successfully ${res.data.body.length} Phone numbers`,
                        life: 5000
                    });
                    resetForm();
                    //console.log(res)
                    //navigate("/", { replace: true });
               }
                else{
                    //setSourceReference(res.data.body.referenceNumber);
                    show({
                        severity: 'error',
                        summary: 'Payment was not successfull ',
                        detail: `Your line is not registered as partner or insufficient funds`,
                        life: 5000
                    });
                    resetForm();

               }

            })
            .catch((response) => {
                showError(response);
                // console.log('catherror',response);
                show({
                    severity: 'error',
                    summary: 'Payment failed',
                    detail: `Your line is not `,
                    life: 5000
                });
                resetForm();
            })
        })

}

    const showError = (e) => {
        show({
            severity: 'error',
            summary: 'Failed',
            detail: renderComponent ? 'Airtime' : 'Bundle' + ' could not be credited!',
            life: 3000
        })
        resetForm();
    }

    const show = (settings) => {
        toast.current.show(settings);
    }

   

    const resetForm = (buttonText = 'Buy Airtime') => {
        setAirtimeState({
            msisdn: '',
            payingMsisdn: '',
            receivingMsisdn: '',
            currencyCode: '',
            pricePlanCode: 'VOICE',
            amount: '',
            source: 'WEB',
            mpin: '',
            buttonText: 'Buy Airtime',
          

        })
        setSelectedBundleCategory({})
        setIsPaymentConfirmation(false)
        //ref.current.value = ""
    }


    const handlePaymentConfirmation = (value) => {
        if (!airtimeState.mpin) {
            setIsPaymentConfirmation(true)
        } else {
            setIsPaymentConfirmation(false)
        }
    //   setIsPaymentConfirmation(true)

        setShowUpload(false)
        formSubmit(value)
    }


    





   
   

    return (
        <BundleContext.Provider value={{
            airtimeState, setAirtimeState,
            selectedBundleCategory, setSelectedBundleCategory, receivingBulkPhoneNumber,parsedDataBulkBundle, setParsedDataBulkBundle,
            isPaymentConfirmation, setIsPaymentConfirmation, receivingAmount, carousel,bulkbundleDto, setbulkbundleDto,
            recesponseDto, setrecesponseDto,ref,showUpload, setShowUpload,
            responseBundleCategory, setResponseBundleCategory, renderComponent, setRenderComponent,
            toast, buttonHandler, formSubmit, show, resetForm, handlePaymentConfirmation, changeHandlerBulkAirtime , changeHandlerBulkBundlesAirtime,
            parsedData, setParsedData, tableRows, setTableRows, values, setValues, data,tableRowsBulkBundle, setTableRowsBulkBundle,valuesBulkBundle, setValuesBulkBundle,responseBundleList,  setResponseBundleList
        }}>
            <div>

            <Toast ref={toast}/>
                <ConfirmationModal
                    show={isPaymentConfirmation}
                    toggle={setIsPaymentConfirmation}
                    processPayment={handlePaymentConfirmation}
                    setPin={setAirtimeState}
                    data={data}
                    bundleData={selectedBundleCategory}
                    renderComponent={renderComponent}
                />
             

                <section className="Airtime">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <aside className="sidebar">
                                    <UserAsidePane/>
                                </aside>
                            </div>
                            <div className="col-lg-9">
                                <div>
                                    <div className='justify-content-around flex-row-auto'>
                                        <div className="sec-heading">


                                            <div className="row">
                                                <div className="col-md-12 form-area pt-5">
                                                        <FormLabel id="airtime-radio-buttons-group-label" className="text-4 mb-3"><h5
                                                            className="text-4 mb-3">Select purchase item</h5></FormLabel>
                                                    <div className="mb-3">
                                                        {econetOptions.map((c, i) => {
                                                            return (
                                                                <div className="custom-control custom-radio custom-control-inline"
                                                                     key={i}>
                                                                    <label>

                                                                        <input type="radio"
                                                                               name="biller"
                                                                               defaultChecked={c.value === econetOptions[0].value}
                                                                               value={c.value}
                                                                               onChange={(e) => {
                                                                                   e.target.value === 'airtime' ? setToggleState(false) : setToggleState(true)
                                                                               }}
                                                                        />
                                                                        <div className="card align-items-center" style={{
                                                                            borderRadius: "15px",
                                                                            boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
                                                                        }}>
                                                                            <div className="card-body ">
                                                                                <img src={c.img} alt={c.value}/>
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>


                                            {/*<FormControl>*/}
                                            {/*    <FormLabel id="airtime-radio-buttons-group-label" className="text-4 mb-3"><h5*/}
                                            {/*        className="text-4 mb-3">Select purchase item</h5></FormLabel>*/}
                                            {/*    <RadioGroup*/}
                                            {/*        aria-labelledby="airtime-radio-buttons-group-label"*/}
                                            {/*        value={toggleState ? 'bundles' : 'airtime'}*/}
                                            {/*        defaultValue="airtime"*/}
                                            {/*        row*/}
                                            {/*        name="radio-buttons-group"*/}
                                            {/*        // onChange={(e) => setSelectAirtimeOrBundle(e.target.value)}*/}

                                            {/*    >*/}
                                            {/*        <FormControlLabel  control={<Radio/>} label="Econet Airtime"/>*/}
                                            {/*        <FormControlLabel value="bundles" control={<Radio/>} label=""/>*/}
                                            {/*    </RadioGroup>*/}
                                            {/*</FormControl>*/}
                                        </div>
                                    </div>
                                    <div className="col-lg-10 col-md-9 m-auto text-left">

                                        <h1 className="sec-title">{!toggleState ? 'ECONET BULK AIRTIME PURCHASE' : 'ECONET BULK BUNDLES PURCHASE'}</h1>
                                    </div>
                                </div>
                                <div className='col-lg-11 col-md-9 m-auto text-'>
                                    {!toggleState ? (<ChanelUserBulkAirtime/>) : (<BulkBundlesHome/>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <Dialog header={<h3 class="admin-heading bg-offwhite">
                    <p>Transaction processing</p>
                    <span>Enter ecocash pin on your phone...</span>
                </h3>
                } visible={isProcessingPayment} style={{width: '20vw'}}
                onHide={() => setProcessingPayment(false)}
                >
                    <ReactPolling
                        url={`${process.env.REACT_APP_BACKEND_HOST_URL}/biller-transaction/find-by-source-ref/${sourceReference}`}
                        interval={3000} // in milliseconds(ms)
                        retryCount={3} // this is optional
                        headers={{
                            //"Content-Type": "application/json",
                            'Authorization': `Bearer ${token}`,
                        }}
                        onSuccess={(data) => {
                            if (data.status == "SUCCESS") {
                                carousel.current?.prev();
                                // const {body} = res.data;
                                // show({
                                //     severity: body.transactionStatus.toLowerCase() !== 'failed' ? 'success' : 'error',
                                //     summary: body.transactionStatus,
                                //     detail: body.comment,
                                //     life: 9000
                                // });
                                resetForm()
                                // this.showSticky('success', 'completed successfully');
                                    show({
                                    severity: 'info',
                                    summary: 'Payment success',
                                    detail: 'Your payment was successful',
                                    life: 5000
                                })

                                // this.setState({
                                //     isProcessingPayment: false,
                                //     msisdn: '',
                                //     accountNumber: '',
                                //     amount: '',
                                //     isShowingPaymentTransactionDetailDialog: true,
                                //     transactionDetails: data
                                // });
                                setProcessingPayment(false)
                                return false;
                            } else if (data.status == "FAILED") {
                                // this.showSticky('error', " failed. Please try again later");
                                show({
                                    severity: 'error',
                                    summary: 'Transaction failed',
                                    detail: 'Your transaction failed. Please try again later',
                                    life: 5000
                                })

                                // this.setState({isProcessingPayment: false});
                                setProcessingPayment(false)
                                return false;
                            }
                            return true;
                        }}
                        onFailure={() => console.log("handle failure")} // this is optional
                        render={({startPolling, stopPolling, isPolling}) => {
                            if (isPolling) {
                                return (
                                    <div className="" style={{align: "centre"}}>
                                        <ProgressSpinner/>

                                    </div>
                                );
                            } else {
                                //this.setState({isProcessingPayment:false})
                                return <></>;
                            }
                        }}
                    />
                </Dialog> */}

  
            </div>
        </BundleContext.Provider>
    )
}

export default ChannelUsersBulkAirtimeBundles;
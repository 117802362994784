import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import './css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Store from './components/Store';
import axios from 'axios';
//import {AuthService} from './services/AuthService';


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

// new AuthService.getToken().then(
//     response =>{
//         window.token = response.data.body
//     }).catch(error => {console.log(error)});

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter >
        {/* basename={baseUrl} */}
            <Store>
                <App/>
            </Store>
        </BrowserRouter>
    </React.StrictMode>,
    rootElement);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

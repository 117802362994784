import React, { Component } from 'react';
import OnlinePayements from './OnlinePayements';

class PayZimra extends Component {
    render() {
        return (
            <div>
                <OnlinePayements category={process.env.REACT_APP_PAY_ZIMRA_ID}/>
            </div>
        );
    } 
}

export default PayZimra;
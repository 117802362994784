import React, {useContext} from "react";
import {Table} from "react-bootstrap";
import {BundleContext} from "../config/contexts";

const BundlePurchase = ({bundleData}) => {
    return(
        <div className="confirm">
            <h1>Bundle Purchase</h1>
            <Table striped variant="secondary">
                <tbody>
                <tr>
                    <td>Choose Bundle Category</td>
                    <td>{bundleData.bundleCategory}</td>
                </tr>
                <tr>
                    <td>Paying Number</td>
                    <td>{bundleData?.payingMsisdn}</td>
                </tr>
                <tr>
                    <td>Receiving Number</td>
                    <td>{bundleData?.receivingMsisdn}</td>
                </tr>
                <tr>
                    <td>Currency</td>
                    <td>{bundleData.currencyCode}</td>
                </tr>
                <tr>
                    <td>Specific Bundle</td>
                    <td>{bundleData.displayText}</td>
                </tr>
                <tr>
                    <td>Amount</td>
                    <td>{bundleData.price}</td>
                </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default BundlePurchase;
import React, { Component,useRef } from 'react';
import "aos/dist/aos.css";
import { AuthService } from '../services/AuthService';
import { Link } from 'react-router-dom'
import vector from '../images/vector.png';
import { UserService } from '../services/UserService';
import { Toast } from 'primereact/toast';
import {Navigate, useNavigate} from 'react-router-dom';
import axios from 'axios'
import { SocialIcon } from 'react-social-icons';
import { ContactUs } from './facebookPixelEvent';
import UserAsidePane from './utils/UserAsidePane';
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import {DataTable} from 'primereact/datatable';
import {Dialog} from 'primereact/dialog';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {confirmPopup} from 'primereact/confirmpopup';
import {ProgressSpinner} from 'primereact/progressspinner';
import ReactPolling from "react-polling";
//import TransactionDetails from './TransactionDetails';
//import TransactionDetails from './TelonepredaidTransactionDetails';

import TransactionDetails from './ZbcPrepaidTransactionDetails';
import _ from 'lodash';




export const  withNavigation = (Component) => {
    return props => <Component {...props} navigate={useNavigate()} />;

  } 


  


        
  
class MoovahPackage extends Component{

    constructor(props) {
        super(props);
        this.state = {
            Categories:null,
            SelectedPackage:null,
            body:[],
            //numberOptions:null,
            numberOption: 'self',
            setNumberOption:null,
            BroadbandState:null,
            payingMsisdn:'',
            receivingMsisdn:null,
            accountNumber:'',
            mpin:'',
            currency: '',
            price:'',
            annualPrice:'',
            quartelyPrice:'',
            termPrice:'',
            selectedPriceType:'',
            priceList:[],
            duration:'',
            disableProcessPayment: false,
            accountNumber:'',
            isShowingPaymentDetailsDialog:false,
            token:'',
            position: 'bottom',
            details: [],
            sourceReference:'',
            isProcessingPayment: false,
            isShowingPaymentTransactionDetailDialog: false,
            transactionDetails: null,
            isShowingTermsAndConditionsDialog: false,
            accountName:'',
            productId:'',
            packageName:'',
            productId:'',
            Buyoption:'',
            Billercode:'01266',
            token:null,
            transactionDetailsList:null,
            office:'',
            userLookupId:'',
            ZBC_ID_number:'',
            MoovahgetDevices:'',
            imeiCode:[],
            officeRegionr:[],
            selectedImeiCode:'',
            packageCover:''


        };
        this.textInput = React.createRef();
    
        this.handleDisplayPaymentConfirmation = this.handleDisplayPaymentConfirmation.bind(this);
        this.renderFooter = this.renderFooter.bind(this);
        this.paymentConfirmation =  this.paymentConfirmation.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onClick = this.onClick.bind(this);
        this.accept = this.accept.bind(this);
        this.reject = this.reject.bind(this);
        this.MoovahgetDevices = this.MoovahgetDevices.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.authService = new AuthService();
        // Create a debounced version of the MoovahgetDevices method
          this.debouncedMoovahgetDevices = _.debounce(this.MoovahgetDevices, 300);

    };

    selectedPrice(){
        // <option>termPrice</option>
        //                                     <option>annualprice</option>
        //                                     <option>quartelyPrice</option>

        if(this.state.selectedPriceType === "termPrice"){
            this.setState({price:this.state.termPrice});
        }
        if(this.state.selectedPriceType === "annualPrice"){
            this.setState({price:this.state.annualPrice});
        }
        if(this.state.selectedPriceType === "quartelyPrice"){
            this.setState({price:this.state.quartelyPrice});
        }

    }


    onClick(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }

        this.setState(state);
    }

    onHide(name) {
        this.setState({
            [`${name}`]: false,
        });
    }


    


    renderFooter(name) {
        return (

            
            
            <div>
                

                <div style={{ flexDirection: "row" , justifyContent: 'space-between' }}>
{/*                                
                                <button disabled={this.state.disableProcessPayment} className="btn btn-filled btn-round" 
                                        onClick={this.paymentConfirmation}><span className="bh"  ></span> <span>Process payment</span>
                                </button> */}

                                <Button label="Cancel" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-text"/>

                </div>
            </div>
        );
    }

    paymentConfirmation(event){

        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            accept: this.accept,
            reject: this.reject
        });
        
    }

    reject() {
        // this.toast.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };


    accept() {

        // if(this.state.currency === 'ZWG'){
        //     this.state.currency =  'ZWG'
        // }
        let paymentDto = {
            endUserId: this.state.payingMsisdn,
            billerCode: this.state.Billercode,
            billerName: 'ZBC',
            amount: this.state.selectedPriceType,
            accountNumber: this.state.accountNumber,
            accountName: this.state.accountName,
            packageCode: this.state.productId,
            office:this.state.userLookupId,
           // isPinless: false,
            currency: this.state.currency === 'ZWG' ? "ZWG" : this.state.currency,
            //productId:this.state.productId,
            receiving:  this.state.numberOption === 'other' ? this.state.payingMsisdn : null,
            zbcVehicleReg: this.state.ZBC_ID_number,

        };
        
        // console.log('paymentDto',paymentDto);


        // axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/auth/generatetoken`, {
        //     username: `${process.env.REACT_APP_USERNAME}`,
        //     password: `${process.env.REACT_APP_PASSWORD}`,
        //   }).then((response)=>{
        //     this.setState({token:response.data.body});
        //     console.log('token',this.state.token);

              
        axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/payment/amount`, paymentDto,
            {
            headers: {
                'Authorization': `Bearer ${this.state.token}`
            }
            }
        )
            .then((response) => {
                // console.log('acccept postpayment',response.data)
                this.setState({
                    sourceReference: response.data.body.field3,
                    isProcessingPayment: true,
                    isShowingPaymentDetailsDialog: false
                });
            })
            .catch((e) => {
              
                // console.log(e,'errrrrror')
            })
     //   })


    }

    
    componentDidMount() {


     
        // console.log(this.state.currency,'currencyontactus');


        this.authService.getToken().then(
    
            response => this.setState({token: response.data.body}, () => {

                // console.log(this.state.token);
                const getData = "data";

                axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/bundles/zbc/find-by-currency/USD`,getData,
                {
                headers: {
                    'Authorization': `Bearer ${this.state.token}`
                }
                }
            )
                .then((res) => {
                    const {body} = res.data;
                    this.setState({Categories:body});
                    // console.log(this.state.Categories,'catttttt');
                    // const price = this.state.Categories.map((cat)=>{
        
                    //     return {
                    //     price:cat.price,
                    //     }
                    // });
                    // console.log(price,'price');
        
                //     this.setState({price:this.state.Categories.map((cat)=>{
        
                //         return {
                //         cat.price,
                //         }
                //     })
                // });
        
                    this.setState({currency:body.currency});
                })
                .catch((e) => {
                    // show({
                    //     severity: 'error',
                    //     summary: 'Failed',
                    //     detail: 'Bundle categories could not be fetched!',
                    //     life: 3000
                    // })
                    // console.log(e,'errrrrror')
                })
            }));




    //   axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/auth/generatetoken`, {
    //     username: `${process.env.REACT_APP_USERNAME}`,
    //     password: `${process.env.REACT_APP_PASSWORD}`,
    //   }).then((response)=>{
          
    // axios.get(`${process.env.REACT_APP_BACKEND_HOST_URL}/bundles/zbc/find-by-currency/USD`,
    //     {
    //     headers: {
    //         'Authorization': `Bearer ${response.data.body}`
    //     }
    //     }
    // )
    //     .then((res) => {
    //         const {body} = res.data;
    //         this.setState({Categories:body});
    //         console.log(this.state.Categories,'catttttt');
            

    //         this.setState({currency:body.currency});
    //     })
    //     .catch((e) => {
           
    //         console.log(e,'errrrrror')
    //     })
    // })
}


MoovahgetDevices(value){
              console.log("value",value);

        axios.get(`${process.env.REACT_APP_BACKEND_HOST_URL}/moovah/device/find-by-msisdn?msisdn=${value}`, {
            headers: {
             'Content-Type':  'application/json',
            },
          }).then( res => {  
            console.log('resp',res.data.body);

            this.setState({MoovahgetDevices: res.data.body});
           this.setState({imeiCode: res.data.body.map((imeiId, index) =>
            { return {
                imeiCode:imeiId.imeiCode,
                DeviceName:imeiId.model
           } })
        });

         console.log('MoovahgetDevices',this.state.MoovahgetDevices); 
         console.log('MoovahgetDevices',this.state.imeiCode); 

        })
        .catch( (e) => {
        console.log('Message not sent',e);
        
        })
      }



       // Method to handle input change
       handleChange(event) {
        this.setState({ payingMsisdn: event.target.value });
        const msisdn = event.target.value;
        console.log("check",msisdn);
        console.log("checkeeeeeeeeeeee",this.state.payingMsisdn);
        this.debouncedMoovahgetDevices(msisdn);

    }



handleDisplayPaymentConfirmation() {


    this.authService.getGuestUserTokenWithMsisdn(this.state.payingMsisdn).then((response)=>{
        const getData = "data";
          
    axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/biller/find-biller-account/${this.state.Billercode}/${this.state.accountNumber}/${this.state.payingMsisdn}`,getData,
        {
        headers: {
            'Authorization': `Bearer ${response.data.body}`
        }
        }
    )
        .then((res) => {
            // console.log('lookup',res.data.body );
            // console.log('reference' ,res.data.body.description);

            this.setState({userLookupId:res.data.body.description});
            // console.log('reference+++++++++' ,this.state.userLookupId);

            this.setState({accountName:res.data.body.name});
          

            
            this.setState({
                        //  disableProcessPayment: disableProcessPayment,
                         details: [
                            //this.state.SelectedPackage === 'Vehicle Radio Personal' ? {'name': 'National ID', 'value': this.state.accountNumber}: {'name': 'Company Registration', 'value': this.state.accountNumber},
                             
                            {'name': 'National ID or Company Registration Number', 'value': this.state.accountName},
                            this.state.receivingMsisdn != null ? {'name': 'Receivinging Msisdn', 'value': this.state.receivingMsisdn}: '',
                           
                             {'name': 'Account Name', 'value': this.state.accountName },
                             {'name': 'Biller Code', 'value': this.state.Billercode },
                             {'name': 'Package', 'value':  this.state.SelectedPackage},
                             { 'name': 'Vehicle Registration Number*', 'value': this.state.ZBC_ID_number},
                             {'name': 'Duration', 'value':  this.state.duration},
                             {'name': 'Currency', 'value':  this.state.currency},
                             {'name': 'Price', 'value':  this.state.selectedPriceType},
                            
                         ]
                     });
            this.setState({isShowingPaymentDetailsDialog: true})
        })
        .catch((e) => {
            // show({
            //     severity: 'error',
            //     summary: 'Failed',
            //     detail: 'Bundle categories could not be fetched!',
            //     life: 3000
            // })
            // console.log(e,'errrrrror')
        })
    })
}

      



    


    render(){

        
        return(
            <div>
                <Toast ref={(el) => this.toast = el}/>

               <div className="row">
                <div className="col-md-12 form-area pt-5">
                {/* <img
                 src={`${process.env.PUBLIC_URL}/images/moovah.png`}
                 alt="Example"
                 style={{ width: '225px', height: '225px' }}
                 /> */}
                   
             </div>
             </div>

            
               <FormControl>
                <FormLabel id="airtime-radio-buttons-group-label" className="text-4 mb-3"><h5
                    className="text-4 mb-3">Pay Device Insurance for</h5></FormLabel>
                <RadioGroup
                    aria-labelledby="airtime-radio-buttons-group-label"
                    defaultValue={'self'}
                    row
                    name="radio-buttons-group"
                    onChange={(e) => {this.setState({numberOption:e.target.value})}}
                >
                    <FormControlLabel value={'self'} control={<Radio/>}   label="My Number"/>
                    <FormControlLabel value={'other'} control={<Radio/>} label="Other Mobile"/>
                </RadioGroup>
              </FormControl>



              <div className="row">
                <div className="m-auto">
                    <form>
                        <div className="row">


                        <div className="form-group col-sm-6">
                                <label htmlFor="payingMsisdn">My Mobile
                                    Number<small style={{color: "red"}}>*</small></label>
                                <input name="payingMsisdn"
                                       className="form-control" type="text"
                                       required="required"
                                       placeholder="Enter Mobile Number (07********)"
                                       value={this.state.payingMsisdn}
                                       onChange={this.handleChange}
                                    
                                />
                            </div>


                        {/* <div className="form-group col-sm-6">
                                <label htmlFor="pin">National ID or Company Registration Number<small style={{color: "red"}}>*</small></label>
                                <input name="pin"
                                       className="form-control" type="text" ref={this.textInput}
                                       required="required"
                                       placeholder="Enter your National ID or Company Registration Number"
                                       value={this.state.accountNumber}
                                       onChange={(e) => {
                                        e.persist();
                                        this.setState((state) => (state.accountNumber = e.target.value))
                                    }}
                                />
                            </div> */}

                               <div class="form-group col-sm-6">
                               <label >Select Device ImeiCode<small style={{color: "red"}}>*</small></label>
                                        <select onChange={(e) => {
                                                             e.persist();
                                                             this.setState((state) => (state.selectedImeiCode = e.target.value));
                                                        }}
                                                      className="form-control"
                                                     >
                                                    <option>Select Device ImeiCode</option>
                                                    { this.state.imeiCode.map((officeList) => (
                                                            <option key={officeList.id}>{officeList.imeiCode}</option>
                                                       
                                                    ))}
                                                    </select>
    
                                </div>



                                <div class="form-group col-sm-6">
                                <label >Select Device ImeiCode<small style={{color: "red"}}>*</small></label>
                                        <select 
                                           InputValue={this.state.packageCover}
                                           onChange={(e) => {
                                                e.persist();
                                               this.setState((state) => (state.packageCover = e.target.value));
                                           }} 
                                           
                                                   className="form-control"
                                                   >
                                               <option>Select PackageCover</option>
                                               <option>COMPREHENSIVE</option>
                                               <option>ACCIDENTAL_DAMAGE</option>
                                               <option>THEFT</option>
                                       </select> 

                                     </div>



                            
                            {/* <div className="form-group col-sm-6">
                                <label htmlFor="UserId">Vehicle Registration Number<small style={{color: "red"}}>*</small></label>
                                <input name="msisdn"
                                       className="form-control" type="text"
                                       required="required"
                                       placeholder="Enter Vehicle Registration Number"
                                       value={this.state.ZBC_ID_number}
                                       onChange={(e) => {
                                        e.persist();
                                        this.setState((state) => (state.ZBC_ID_number = e.target.value))
                                    }}
                                />
                            </div> */}
                        

                            {/* <div class="form-group col-sm-6">
                                                 <select 
                                                    InputValue={this.state.selectedPriceType}
                                                    onChange={(e) => {
                                                        e.persist();
                                                       this.setState((state) => (state.selectedPriceType = e.target.value));
                                                   }} 
                                                    
                                                            className="form-control"
                                                            >
                                                        <option>termPrice</option>
                                                        <option>annualprice</option>
                                                        <option>quartelyPrice</option>
                                                </select> 

                             </div> */}

                            
                                
                               
                                
    
                            {
                                this.state.numberOption  !== 'self' && (
                                    <div className="form-group col-sm-6">
                                        <label htmlFor="receivingMsisdn">Receiving Mobile
                                            Number<small>(required)</small></label>
                                        <input name="receivingMsisdn"
                                               className="form-control" type="text"
                                               required="required"
                                               placeholder="Enter Mobile Number (07********)"
                                               value={this.state.receivingMsisdn}
                                               onChange={(e) => {
                                                e.persist();
                                                this.setState((state) => (state.receivingMsisdn = e.target.value))
                                            }}
                                        />
                                    </div>
                                )
                            }
                          

                            

                           
                            {/* <div className="form-group col-sm-6">
                                <label htmlFor="amount">Amount<small>(required)</small></label>
                                <input name="msisdn"
                                       className="form-control" type="text"
                                       required="required"
                                       placeholder="Enter Amount"
                                       value={airtimeState.amount}
                                       onChange={e => setAirtimeState((s) => ({
                                           ...s,
                                           amount: e.target.value
                                       }))}
                                />
                            </div> */}
                            <div>
                            </div>
                        </div>
                    </form>
                    {/* {
                                this.state.numberOption  !== 'self' && (

                                    <div className="col-md-6 col-lg-2 form-group">
                                                        <button className="btn btn-default rounded-2"
                                                                onClick={this.handleDisplayPaymentConfirmation}>Continue
                                                        </button>
                                    </div>
                                )
                    } */}

              

                                    <div className="col-md-6 col-lg-2 form-group">
                                                        <button className="btn btn-default rounded-2"
                                                                onClick={this.handleDisplayPaymentConfirmation}>Continue
                                                        </button>
                                    </div>
                            
                </div>

            </div>

            <Dialog header={<h3 class="admin-heading bg-offwhite">
                    <p>Payment Details</p>
                    <span>Verify payment details</span>
                </h3>
                } visible={this.state.isShowingPaymentDetailsDialog} position={this.state.position} modal
                        style={{width: '70%', height: '80%'}}
                        footer={this.renderFooter('isShowingPaymentDetailsDialog')}
                        onHide={() => this.onHide('isShowingPaymentDetailsDialog')} breakpoints={{'960px': '75vw'}}
                        >
                    <div className='row' style={{paddingLeft: '10%', paddingRight: '10%', marginTop: '0%'}}>
                    
                        <div className='col-sm-10'style={{display: 'flex', justifyContent: 'space-between'}}>
                                <h3>Account Details</h3>
                                <button disabled={this.state.disableProcessPayment} className="btn btn-filled btn-round"
                                        onClick={this.paymentConfirmation}><span className="bh"></span> <span>Process payment</span>
                                </button>

                             </div>
                        <br/>

                        <div className='row'>
                            <DataTable value={this.state.details} stripedRows responsiveLayout="scroll">
                                <Column field="name" header="Name"></Column>
                                <Column field="value" header="Value"></Column>
                            </DataTable>

                        </div>

                        <div className='row' style={{marginTop: '20px'}}>

                            <div className='col-sm-12'>
                                <button disabled={this.state.disableProcessPayment} className="btn btn-filled btn-round"
                                        onClick={this.paymentConfirmation}><span className="bh"></span> <span>Process payment</span>
                                </button>

                            </div>

                        </div>

                    </div>
                </Dialog>


                <Dialog header={<h3 class="admin-heading bg-offwhite">
                    <p>Transaction processing</p>
                    <span>Enter your ecocash pin on your phone...</span>
                </h3>
                } visible={this.state.isProcessingPayment} position={this.state.position} style={{width: '20vw'}}
                       modal
               onHide={() => this.onHide('isProcessingPayment')} breakpoints={{'960px': '75vw'}}
                >
                    
                    <ReactPolling
                        url={`${process.env.REACT_APP_BACKEND_HOST_URL}/biller-transaction/find-by-source-ref/${this.state.sourceReference}`}
                        interval={3000} // in milliseconds(ms)
                        retryCount={3} // this is optional
                        headers={{
                            //"Content-Type": "application/json",
                            'Authorization': `Bearer ${this.state.token}`,
                        }}
                        onSuccess={(data) => {
                            console.log('successdata',data);
                            if (data.status == "SUCCESS") {
                                // this.showSticky('success', 'completed successfully');
                                this.toast.show({
                                    severity: 'info',
                                    summary: 'Payment success',
                                    detail: 'Your payment was successful',
                                    life: 5000
                                })

                                this.setState({
                                    isProcessingPayment: false,
                                    msisdn: '',
                                    accountNumber: '',
                                    amount: '',
                                    isShowingPaymentTransactionDetailDialog: true,
                                    transactionDetails: data

                                });

                                return false;
                            } else if (data.status == "FAILED") {
                                console.log('faileddddddd',data);
                                // this.showSticky('error', " failed. Please try again later");
                                if(this.state.selectedBiller.name == 'ZINWA Satewave'){

                                    this.toast.show({
                                        severity: 'error',
                                        summary: 'Transaction failed',
                                        detail: 'payer transaction amount less than defined value',
                                        life: 7000
                                    })
    

                                }

            
                                this.toast.show({
                                    severity: 'error',
                                    summary: 'Transaction failed',
                                    detail: 'Your transaction failed. Please try again later',
                                    life: 5000
                                })

                                this.setState({isProcessingPayment: false});
                                return false;
                            }
                            return true;
                        }}
                        onFailure={(data) => console.log("handle failure",data)} // this is optional
                        render={({startPolling, stopPolling, isPolling}) => {
                            if (isPolling) {
                                return (
                                    <div className="" style={{align: "centre"}}>
                                        <ProgressSpinner/>

                                    </div>
                                );
                            } else {
                                return <></>;
                            }
                        }}
                    />
                </Dialog>


                <Dialog header={<h3 class="admin-heading bg-offwhite">
                    <p>Transaction Details</p>
                    <span>Payment details made</span>
                </h3>
                }
                        visible={this.state.isShowingPaymentTransactionDetailDialog} position={this.state.position}
                        modal style={{width: '70%', height: '70%'}}
                        footer={this.renderFooter('isShowingPaymentTransactionDetailDialog')}
                        onHide={() => this.onHide('isShowingPaymentTransactionDetailDialog')}
                        breakpoints={{'960px': '75vw'}}>
                    <TransactionDetails tDetails={this.state.transactionDetails} tBillerName={'ZBC'} tBroadBandPackage={this.state.SelectedPackage} tDuration={this.state.duration} />
                </Dialog>




            </div>

        );

    }




}

export default withNavigation(MoovahPackage);
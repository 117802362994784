import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation,
    useNavigate
  } from "react-router-dom";
// import vector from '../images/vector.png';
import vector from '../images/lgimage.jpg';

import { AuthService } from '../services/AuthService';
import { UserService } from '../services/UserService';
import { Toast } from 'primereact/toast';

import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { Checkbox } from '@material-ui/core';
import { Register } from './facebookPixelEvent';
import validator from 'validator';
import CryptoJS from 'crypto-js';
import { DecryptionService } from '../services/DecryptionService';
import ReCAPTCHA from 'react-google-recaptcha';
import config from './utils/config';




export const  withNavigation = (Component) => {
    return props => <Component {...props} navigate={useNavigate()} />;
  } 
  const key2 = config.encryptionkey2;
  const iv = config.encryptioniv;
  const apiKey = config.google_key;
class SignUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username:'',
            password:'',
            cpassword:'',
            firstName:'',
            lastName:'',
            email:'',
            cmsisdn:'',
            isChecked:false,
            isValid:true,
            Error:'',
            emailError:'',
            CaptchaVerified:false,
            LockLoginButton:false
        };
        this.authService = new AuthService();
        this.userService = new UserService();
        this.decryptionservice = new DecryptionService(); 
        this.handleCreateAccount = this.handleCreateAccount.bind(this);


    }


     handleVerifyCaptcha = (response) => {
        // This callback function will be called when the user verifies the captcha.
        //console.log("Captcha verified:", response);
        // Set the state to indicate that the captcha has been verified.
        this.setState({CaptchaVerified:true});
        this.setState({LockLoginButton:true});
        // setCaptchaVerified(true);
        // setLockLoginButton(true);
        // setButtonstate((s) => ({...s, buttonText: 'Login'}));
      };

 hanglePleaseverify =()=>{
    this.toast.show({ severity: 'info', summary: 'Error Captcha', detail: 'Please verify Captcha', life: 3000 })

    }

    handleCheckboxChange = (e)=>{
        
        e.target.checked ? this.setState({isChecked:true}):this.setState({isChecked:false});
       
    }

// isValidEmail(email) {
//         return /\S+@\S+\.\S+/.test(email);
//       }


//       handleChange = (e) => {
//         if (!this.isValidEmail(e.target.value)) {
//             this.setState({Error:'Email is invalid'});
         
//         } else {
//             this.setState({Error:null});
//         }

//         this.setState({email:e.target.value});
    
//         //this.email(e.target.value);
//       };


 validateEmail = (e) => { 
        var email = e.target.value 
      
        if (validator.isEmail(email)) { 
            this.setState({emailError:''});
          //this.setEmailError('Valid Email :)') 
          this.setState({email:email});
        } else { 
          //this.setEmailError('Enter valid Email!') 
          this.setState({emailError:'Enter Valid Email '});
        } 
      } 


// handleChange = (e) => {
//         const email = e.target.value;
//         this.setState({
//             email,
//             isValid: this.validateEmail(email),
//         });
//     };


    // validateEmail = (email) => {
    //     // A simple email validation using a regular expression
    //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //     return emailRegex.test(email);
    //   };


    handleCreateAccount(){
          


        //const newEmail = this.state.email;

        
        let userDto = {
            username: this.state.username,
            password: this.state.password,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email:this.state.email,
            msisdn:this.state.cmsisdn,
            cpassword:this.state.cpassword
        }

        //console.log("text",userDto);

        
        
        // Convert object to JSON string
        const jsonString = JSON.stringify(userDto);
        // console.log('concatenatedString:', user);
        let keyHex = CryptoJS.enc.Utf8.parse(key2);
        let ivHex = CryptoJS.enc.Utf8.parse(iv);
        let messageHex = CryptoJS.enc.Utf8.parse(jsonString);
        
        let encrypted = CryptoJS.AES.encrypt(messageHex, keyHex, {
            iv: ivHex,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
    
       const finalText  = encrypted.toString();

       //console.log("text",finalText);


        

        if(userDto.username === '' || 
            userDto.password === '' || 
            userDto.cpassword === '' ||  
            userDto.firstName === '' ||  
            userDto.lastName === '' ||  
            userDto.email === '' || 
            userDto.msisdn === ''){
            this.toast.show({ severity: 'info', summary: 'Invalid data', detail: 'Please enter all fields', life: 3000 });
            return;
        }
        if(userDto.cpassword !== userDto.password){
            this.toast.show({ severity: 'info', summary: 'Passwords do not match', detail: 'Please enter the same password', life: 3000 });
            return;
        }

        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

        if(!userDto.password.match(strongRegex)){
            this.toast.show({ severity: 'info', summary: 'Password is too weak', detail: 'Please enter a strong password', life: 3000 });
            return;
        }

        if(!this.state.isChecked){
            this.toast.show({ severity: 'info', summary: 'Accept terms and conditions', detail: 'Please accept the terms and conditions before proceeding', life: 5000 });
            return; 
        }



        this.authService.getToken().then(
            response => this.setState({token: response.data.body}, () => {
              console.log("token",response.data.body);
                this.userService.create(finalText,response.data.body)
                .then(response => {
                console.log(response.data);
                //     this.toast.show({ severity: 'info', summary: 'Account Created', detail: 'Your account has been created.', life: 5000 });
                //    this.setState({
                //                     username:'',
                //                     password:'',
                //                     cpassword:'',
                //                     firstName:'',
                //                     lastName:'',
                //                     email:'',
                //                     cmsisdn:''
                //     });
        
                if (response.data.status === 500) {
                    this.toast.show({ severity: 'error', summary: 'Error creating account', detail: 'User already exists', life: 3000 });
                    this.props.navigate('/sign-up');
                } else if (response.data.status === 423) {
                    this.toast.show({ severity: 'error', summary: 'Error creating account', detail: `${response.data.message}`, life: 3000 });
                    this.props.navigate('/sign-up');
                } else {
                    this.toast.show({ severity: 'info', summary: 'Account Created', detail: 'Your account has been created.', life: 5000 });
                    this.setState({
                        username: '',
                        password: '',
                        cpassword: '',
                        firstName: '',
                        lastName: '',
                        email: '',
                        cmsisdn: ''
                    });
                    this.props.navigate('/welcome');
                }
                    
        
                    //this.props.navigate('/welcome');
                    Register();
                })
                .catch(error => {
                    // console.log('error',error);
                     if(error.status == "500"){
                        this.toast.show({ severity: 'info', summary: 'Error creating account', detail: 'User arleady exist', life: 3000 });
                        return;
                    }
                    this.props.navigate('/sign-up');
            
        
                this.toast.show({ severity: 'error', summary: 'Error creating account', detail: 'The account could not be created. Please try again later', life: 3000 });
                });



            }));
       

       
    }

    render() {

        const header = <h6>Password strength</h6>;
        const footer = (
            <React.Fragment>
                <Divider />
                <ul className="pl-2 ml-2 mt-0" style={{lineHeight: '1.5'}}>
                    <li>At least one lowercase</li>
                    <li>At least one uppercase</li>
                    <li>At least one numeric</li>
                    <li>At least one special character (!, @,#,$,%,^,&,*)</li>
                    <li>Minimum 8 characters</li>
                </ul>
            </React.Fragment>
        );
        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
               <section className="page-feature py-5">
                    <div className="container text-center">
                        <div className="row">
                            <div className="col-md-6">
                                <h2 className=" text-left">Signup</h2>
                            </div>
                            <div className="col-md-6">
                                <div className="breadcrumb text-right">
                                   <Link to='/'><a >Home</a></Link>
                                    <span>/ Signup</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="banner login-registration">
                    <div className="vector-img">
                        <img src={vector} alt=""/>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="content-box">
                                    <h2>Create Account</h2>
                                    <p>Welcome to EcoCash Online Bill Payments</p>
                                </div>
                                <div  className="sl-form">
                                    <div className="form-group">
                                        <label>First Name</label>
                                        <input type="text" id='firstName' name='firstName' value={this.state.firstName} placeholder="Enter your name" required onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.firstName= e.target.value))
                                                                                                                }}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Last Name</label>
                                        <input type="text" id='lastName' name='lastName' value={this.state.lastName} placeholder="Enter last name" required onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.lastName= e.target.value))
                                                                                                                }}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="text" 
                                        id="userEmail" 
                                        placeholder="Enter EmaiL Address"
                                       onChange={(e) =>{this.validateEmail(e); e.persist()}}></input>
                                    
                                            <span style={{ 
                                                    fontWeight: 'bold', 
                                                    color: 'red', 
                                                    }}>{this.state.emailError}</span>
                                

                    

                                    </div>
                                    <div className="form-group">
                                        <label>Mobile Number</label>
                                        <input type="number" placeholder="Enter phone number" id='cmsisdn' name='cmsisdn'value={this.state.cmsisdn}  required onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.cmsisdn= e.target.value))
                                                                                                                }}/>
                                    </div>

                                    <div className="form-group">
                                        <label>Username</label>
                                        <input type="text" id="username" name='username' placeholder="Enter Username" value={this.state.username} required  onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.username= e.target.value))
                                                                                                                }}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <Password onChange={(e) => this.setState({ password: e.target.value })} header={header} footer={footer} style={{width:'100%'}}/>

                                        {/* <input type="password" placeholder="Password" id='password' name='password' value={this.state.password} required onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.password= e.target.value))
                                                                                                                }}/> */}
                                    </div>
                                    <div className="form-group">
                                        <label>Re-enter Password</label>
                                        <Password onChange={(e) => this.setState({ cpassword: e.target.value })} header={header} footer={footer} style={{width:'100%'}}/>

                                        {/* <input type="password" placeholder="Password" id='cpassword' name='cpassword' value={this.state.cpassword} required onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                this.setState((state) => (state.cpassword= e.target.value))
                                                                                                                }}/> */}
                                    </div>
                                    <div style={{ fontFamily: 'system-ui' }}>
                                    <label>
                                        <Checkbox
                                        checked={this.state.checked}
                                        onChange={this.handleCheckboxChange}
                                        />
                                        <span style={{ marginLeft: 8 }}>I accept the <a href='https://www.ecocash.co.zw/website-terms-and-conditions' target='_blank'>Terms And Conditions</a></span>
                                    </label>
                                    </div>
                                    
                                    {
                                        this.state.LockLoginButton==true ?
                                         <button className="btn btn-filled btn-round" type="submit" onClick={this.handleCreateAccount}>Signup</button>
                                       :
                                       <button className="btn btn-filled btn-round" type="submit" onClick={this.hanglePleaseverify}>Signup</button>

                                    }

                                    
                                    {/* <button className="btn btn-filled btn-round" type="submit" onClick={this.handleCreateAccount}>Signup</button> */}

                                    <ReCAPTCHA

                                        sitekey = {`${apiKey}`}
                                        onChange={this.handleVerifyCaptcha}
                                    />

                                    {this.captchaVerified && <p>Captcha verified!</p>}
                                  
                                    <p className="notice">Already have an account? <Link to='/sign-in'><a >Login Account</a></Link></p>
                                    </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default withNavigation(SignUp);
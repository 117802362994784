import React, { Component} from 'react';
import OnlinePayements from './OnlinePayements';


export default class HealthInsurance extends Component {
    render(){ return (
        <div>
            <OnlinePayements category={process.env.REACT_APP_HEALTH_INSURANCE_ID}/>
        </div>
    )
   }
    
};

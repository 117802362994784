import React, {Component, useContext, useEffect, useRef, useState} from 'react';
import "aos/dist/aos.css";
import {AuthService} from '../services/AuthService';
import {Link} from 'react-router-dom'
import {Toast} from 'primereact/toast';
import axios from 'axios';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Dropdown} from "primereact/dropdown";
import MyDatePicker from './MyDatePicker/MyDatePicker'
import CarbonDate from "react-carbon-datepicker";
import UserAsidePane from './utils/UserAsidePane';
// import {carbonDateConfig, axios} from "../config";

import UserProfileHeader from "./utils/UserProfileHeader";
import moment from "moment";
import {Context} from "./Store";
import { DownloadedWalletStatement, WalletStatement,FailedToDownloadWalletStatement } from './facebookPixelEvent';

const currency = [
    {label: "ZWG", value: "ZWG"},
    {label: "USD", value: "USD"},
];


const wstate = {
    msisdn: '',
    currency: '',
    mime: '',
    statementType: '',
    startDate: '',
    endDate: '',
    buttonText: 'Download PDF Statement',
};


export default function () {


    const [walletState, setWalletState] = useState(wstate);
    const [token, setToken] = useState("");
    const [state] = useContext(Context)
    const toast = useRef(null)

    useEffect(() => {
        WalletStatement();
        const user = JSON.parse(state.user)
        setWalletState((current) => ({...current, msisdn: user?.msisdn || '0772647555'}))

    }, [])

    const data = {
        msisdn: walletState.msisdn,
        currency: walletState.currency,
        startDate: walletState.startDate,
        endDate: walletState.endDate,
        action: 'periodic'
    }

    const formSubmit = async (e) => {
        e.preventDefault()

        const currentTime = moment().format('YYYY-MM-DD');

        if (!walletState.currency) {
            show({
                severity: 'error',
                summary: 'Select currency',
                detail: 'Kindly choose the currency option',
                life: 3000
            });
        } else if (walletState.startDate > walletState.endDate) {
            show({
                severity: 'error',
                summary: 'Invalid date range',
                detail: 'Start date cannot be greater than end date!',
                life: 3000
            });
        } else if (walletState.startDate > currentTime || walletState.endDate > currentTime) {
            show({
                severity: 'error',
                summary: 'Invalid date range',
                detail: 'Start date and/or end date cannot be greater than current date!',
                life: 3000
            });
        } else {
            setWalletState((s) => ({...s, buttonText: '...downloading'}))

            // axios.post('/statement/periodic', data, {
            //     responseType: "blob"
            // })https://pay.ecocash.co.zw
            

            const getExtensionFromContentType = (contentType) => {
                if (contentType.startsWith('image/')) {
                  return contentType.split('/')[1];
                } else if (contentType === 'application/pdf') {
                  return 'pdf';
                } else {
                  return 'file';
                }
              };
              
            var token = localStorage.getItem('token');
            //console.log("token",token);

            // axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/statement/periodic`, {
            //         msisdn: walletState.msisdn,
            //         currency: walletState.currency,
            //         startDate: walletState.startDate,
            //         endDate: walletState.endDate,
            //         action: 'periodic'
            // },
           
            //    {
            //     headers: {
            //         Authorization:
            //         `Bearer ${token}`,
            //     },
            //     }
            
            // )
            //     .then((response) => {
            //         // DownloadedWalletStatement();
            //         // const url = window.URL.createObjectURL(new Blob([res.data]));
            //         // const link = document.createElement('a');
            //         // link.href = url;
            //         // const filename = res.headers['content-disposition']?.split('filename=')[1];
            //         // link.setAttribute('download', filename || 'Ecocash Statement.pdf'); //or any other extension
            //         // document.body.appendChild(link);
            //         // link.click();








            // const contentType = response.headers.get('content-type') || 'application/octet-stream';
            // const extension = getExtensionFromContentType(contentType);
            // const blob = response.blob();
      
            // // Create a temporary URL for the file
            // const fileUrl = URL.createObjectURL(blob);
      
            // // Create a temporary <a> element to initiate the download
            // const link = document.createElement('a');
            // link.href = fileUrl;
            // link.download = `document.${extension}`;
      
            // // Programmatically click the link to trigger the download
            // link.click();
      
            // // Clean up the temporary URL and <a> element
            // URL.revokeObjectURL(fileUrl);
            // link.remove();
            //         show({
            //             severity: 'success',
            //             summary: 'Success',
            //             detail: 'Wallet statement downloaded',
            //             life: 3000
            //         });
            //         resetForm();
                    
            //     })
            //     .catch((e) => {
                    
            //         e.toString().includes('Error: Network Error') ? (
            //             show({
            //                 severity: 'error',
            //                 summary: 'Success',
            //                 detail: 'Fialed to download Wallet statement',
            //                 life: 6000
            //             })
            //         ) : (
            //             show({
            //                 severity: 'error',
            //                 summary: 'Failed',
            //                 detail: 'Failed to download statement!',
            //                 life: 6000
            //             })
            //         );
            //         FailedToDownloadWalletStatement();
            //         resetForm();
                    
            //     })

            const options = {    
                 method: 'POST',     
                 url: `${process.env.REACT_APP_BACKEND_HOST_URL}/statement/periodic`,     
                 params: { '': '' },    
                 headers: {       'Content-Type': 'application/json',    
                      
                 Authorization:
                 `Bearer ${token}`,     },     
                 responseType: 'arraybuffer', 
                data: 
                {      
                    msisdn: walletState.msisdn,
                    currency: walletState.currency,
                    startDate: walletState.startDate,
                    endDate: walletState.endDate,
                    action: 'periodic'    
            },   };   
        axios.request(options)     
        .then(function (response)
         {       
            const blob = new Blob([response.data], { type: 'application/octet-stream' });
            const link = document.createElement('a');      
            link.href = window.URL.createObjectURL(blob);      
            link.download = 'downloadedFile.pdf';    
            link.click();    
         })     
         .catch(function (error) 
         {      
        // console.error(error);   
          });
          

      

        }
    }

    const show = (settings) => {
        // console.log('toast', settings)
        toast.current.show(settings);
    }

    const resetForm = (buttonText = 'Download PDF Statement') => {
        setWalletState({
            // msisdn: '',
            currency: '',
            mime: '',
            statementType: '',
            startDate: '',
            endDate: '',
            buttonText: buttonText
        })
    }

    return (
        <div>

            <Toast ref={toast}/>

            <UserProfileHeader/>
            <section className="Statements">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <aside className="sidebar">
                                <UserAsidePane/>
                            </aside>
                        </div>
                        <div className="col-lg-9">

                            <div className="row">
                                <div className="col-lg-7 col-md-9 m-auto text-center">
                                    <div className="sec-heading">
                                        <span className="tagline">WALLET STATEMENTS</span>
                                        <h3 className="sec-title">Select Wallet, currency <br/>and Date range.</h3>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-lg-9 col-md-9 m-auto">
                                    <form onSubmit={(e) => formSubmit(e)} method="POST">
                                        <div className="row">
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="msisdn">MSISDN <small>(required)</small></label>
                                                <input name="msisdn"
                                                       className="form-control" type="number"
                                                       required="required"
                                                       placeholder="e.g 0771222151"
                                                       disabled
                                                       value={walletState.msisdn}/>
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="currency">Select
                                                    Currency <small>(required)</small></label>
                                                {/*<div className="col-md-12">*/}
                                                <Select options={currency}
                                                        placeholder="ZWG/USD"
                                                        clasName="form-control test-class"
                                                        required="required"
                                                        InputValue={walletState.currency}
                                                        onChange={e => setWalletState((s) => ({
                                                            ...s,
                                                            currency: e.value
                                                        }))}/>
                                                {/*        <Dropdown id='currency' className='form-control row-cols-1' value={walletState.currency} options={walletState.currencies} onChange={e => setWalletState({currency: e.value})}/>*/}
                                                {/*<select id='currency' value={walletState.currency} placeholder="ZWG/USD" clasName="form-control test-class" required="required"*/}
                                                {/*        onChange={e => setWalletState({currency: e.target.value})}>*/}
                                                {/*    {walletState.currencies.map((c, i) => (*/}
                                                {/*        <option key={i} value={c.value} label={c.label} />*/}
                                                {/*    ))}*/}
                                                {/*</select>*/}
                                                {/*</div>*/}
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="startDate">Start
                                                    Date <small>(required)</small></label>
                                                <div>
                                                    <input type="date" className="form-control" name="startDate"
                                                           id="startDate" required="required"
                                                           value={walletState.startDate}
                                                           onChange={(e) => setWalletState((s) => ({
                                                               ...s,
                                                               startDate: e.target.value
                                                           }))}/>
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="endDate">End Date <small>(required)</small></label>
                                                <div>
                                                    <input type="date" className="form-control" name="startDate"
                                                           id="startDate" required="required"
                                                           value={walletState.endDate}
                                                           onChange={(e) => setWalletState((s) => ({
                                                               ...s,
                                                               endDate: e.target.value
                                                           }))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit"
                                                className="btn btn-default">{walletState.buttonText}</button>
                                    </form>
                                    <br/>
                                    <span  style={{fontWeight: '500'}}>You will receive a one-time Password (OTP) on your phone from +263165 to open the statement. </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

// export default WalletStatement;

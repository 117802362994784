import React, { Component } from 'react';

class Merchants extends Component {
    render() {
        return (
            <div>
                <h1>
                    Merchants
                </h1>
            </div>
        );
    }
}

export default Merchants;
import React, {useContext, useEffect, useLayoutEffect, useRef,useState} from "react";
import {Carousel} from "react-bootstrap";
import Bundles from "./Bundles";
import BundleCategory from "./BundleCategory";
import {BundleCategoryContext, BundleContext} from "../config/contexts";
import BulkBundles from "./BulkBundles";
import BulkBundleBundleCategory from "./BulkBundleCategory";
import DataTable, { createTheme } from 'react-data-table-component';
import { CSVLink, CSVDownload } from "react-csv";

const BulkBundlesCarousel = () => {
    const {setRenderComponent, airtimeState, carousel,recesponseDto, setrecesponseDto,showUpload, setShowUpload} = useContext(BundleContext)
    const {handleBundleCategory, carouselIndex, setCarouselIndex, handleBundleConfirmation, proceed} = useContext(BundleCategoryContext)
    const [showResults, setShowResults] = useState(false)
    const onBuyAirtime = (e) => setShowResults(true)

  

      const responseColumns = [
        {
          name: 'PhoneNumber',
          selector: 'phoneNumber',
          sortable: true,
        },
        {
          name: 'message',
          selector: 'message',
          sortable: true,
          right: true,
        },

        // {
        //     name: 'responseCode',
        //     selector: 'responseCode',
        //     sortable: true,
        //     right: true,
        //   },

          {
            name: 'reference',
            selector: 'reference',
            sortable: true,
            right: true,
          },
      ];

    useEffect(() => {
        proceed && carousel.current.next();
    }, [proceed])

    return(
        <div className='row'>
            <Carousel
                className='mb-6'
                interval={null}
                indicators={false}
                controls={false}
                ref={carousel}
                onSlid={(i, e) => setCarouselIndex(i)}
            >
                <Carousel.Item className='p-sm-2 mb-5 pb-5'>
                    <BulkBundles />
                </Carousel.Item>
                <Carousel.Item>
                    <BulkBundleBundleCategory />
                </Carousel.Item>
            </Carousel>
            <div className='justify-content-between'>
                {carouselIndex === 0 ? (
                        <button type="submit" onClick={(e) => { handleBundleCategory(e);}} className="btn btn-default">{'Next'}</button>
                ) : (
                    <>
                        <button type="submit" onClick={() => carousel.current.prev()}
                                className="btn btn-default" style={{width:'200px'}}>{'Prev'}</button>
                                &nbsp;
                                &nbsp;

                        <button type="submit" onClick={(e) => {handleBundleConfirmation(e); setRenderComponent(false);onBuyAirtime(e)}}
                                className="btn btn-default" style={{width:'200px'}}>{airtimeState.buttonText === 'Buy Airtime' ? 'Buy Bundle' : airtimeState.buttonText}</button></>
                    )}
            </div>

                                <br />
                                <br />
                                {
                                 showResults ?
                                    <DataTable
                                    title="Report of  Successful Recharge"
                                    columns={responseColumns}
                                    data={recesponseDto}
                                    pagination
                                    theme="solarized"
                                />
                                : <></>

                                }

                                
                                <br />
                                <br />
                                { 
                                    showResults ?
                                    <CSVLink data={recesponseDto}
                                    filename={"Bulk_Bundles_Recharge_Report.csv"}
                                    className="btn btn-default " style={{width:'200px'}}>Download </CSVLink>
                                : <></>

                                }
                                
        </div>
    )
}

export default BulkBundlesCarousel;
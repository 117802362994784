import axios from 'axios';

export class BillerCategoryService{

    getAllBillerCategories(token){
        return  axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/biller-category/find-all`, {
             headers: {
                 'Authorization': `Bearer ${token}`
             }
         })
     }

     getRecurringBillerCategories(token){
        const getData="data";
        return  axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/biller-category/recurring/all`,getData, {
             headers: {
                 'Authorization': `Bearer ${token}`
             }
         })
     }
}
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComponent from './components/NavbarComponent';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";  


import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 

import "./css/bootstrap.min.css";
import "./css/themify-icons.css";

import "./css/bootstrap-select.min.css";
import "./css/daterangepicker.css";
import "./css/coreNavigation-1.1.3.min.css";
import "./css/style.css";
import Wrapper from './components/Wrapper';
import NWrapper from './components/NWrapper';
import Contact from './components/Contact';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import { alpha } from '@material-ui/core/styles'

function App() {
  return (
    <div className="App">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {/* <NavbarComponent/> */}
        {/* <MetroIndex/> */}
        {/* <OnlinePayements/> */}
        {/* <Wrapper/> */}
        {<NWrapper/>}
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default App;

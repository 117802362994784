import React, { Component, useState, useContext, useRef } from 'react';
import "aos/dist/aos.css";
import { AuthService } from '../services/AuthService';
//import vector from '../images/vector.png';
import vector from '../images/lgimage.jpg';

import { UserService } from '../services/UserService';
import { Toast } from 'primereact/toast';
import {Link , Navigate, useNavigate,useParams} from 'react-router-dom';
import { Context } from "./Store";
import axios from 'axios';
//import PropTypes from 'prop-types';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';



// export function withRouter(Children){
//     return(props)=>{

//        const match  = {params: useParams()};
//        return <Children {...props}  match = {match}/>
//    }
//  }


 export const  withNavigation = (Component) => {
    return props => <Component {...props} navigate={useNavigate()} />;
  } 

 class ChangePassword extends  React.Component {

    constructor(props) {
        super(props);
        this.state = {
          token : '',
          email: '',
          newPassword: '',
          cpassword:'',
        //   updated: false,
        //   isLoading: true,
          //error: false,
        };
      }


      async componentDidMount() {
        
        const queryParam = new URLSearchParams(window.location.search)
        const token = queryParam.get("token");
        //console.log(token)
        try {
          const response = await axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/user/changePassword`, {
            params: {
              token: token,
            },
          });
           //console.log(response);
          if (response.data.message === 'password reset link a-ok') {
            this.setState({
              username: response.data.username,
            //   updated: false,
            //   isLoading: false,
            //   error: false,
            });
          }
        } catch (error) {
          //console.log(error.response.data);
        //   this.setState({
        //     // updated: false,
        //     // isLoading: false,
        //     // error: true,
        //   });
        }
      }


      handleChange = name => (event) => {
        this.setState({
          [name]: event.target.value,
        });
      };



      updatePassword = async (e) => {
        e.preventDefault();
        const { email, newPassword,cpassword } = this.state;
        const queryParam = new URLSearchParams(window.location.search)
        const token = queryParam.get("token");

        if( this.state.newPassword === '' || 
        this.state.cpassword === '' 
           ){
            this.toast.show({ severity: 'info', summary: 'Invalid data', detail: 'Please enter all fields', life: 3000 });
            return;
        }
        

        if(this.state.newPassword !== this.state.cpassword){
            //alert('should match');
           this.toast.show({ severity: 'info', summary: 'Passwords do not match', detail: 'Please enter the same password', life: 3000 });
            return;
        }

        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

        if(!this.state.newPassword.match(strongRegex)){
            //alert('should match');
           this.toast.show({ severity: 'info', summary: 'Password is too weak', detail: 'Please enter a strong password', life: 3000 });
            return;
        }




        //const { token } = useParams()
        // const {
        //   match: {
        //     params: { token },
        //   },
        // } = this.props;
        try {
          const response = await axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/user/save-reset-password`,
            {
              newPassword,
              token:token,
            },

          );
          this.toast.show({ severity: 'success', summary: 'Password changed', detail:'Your password successfully changed', life: 3000 });
          this.props.navigate('/sign-in');
          
          

         // console.log(response.data);
        //   if (response.data.message === 'password updated') {
        //     this.setState({
        //       updated: true,
        //       error: false,
        //     });
        //   } else {
        //     this.setState({
        //       updated: false,
        //       error: true,
        //     });
        //   }
        } catch (error) {
          //console.log(error.response.data);
        }
      };
    

    // static ={};

    // handleSubmit = e => {
    //     e.preventDefault();


    //     const data = {
    //         token: this.props.match.params.id,
    //         password: this.password,
    //         password_conf: this.password_conf
    //     }

    //     axios.post('http://192.168.84.197:8089/onlinebillers/password/reset-password', data)
    //     .then( res => {
    //         this.setState({ reset: true })
    //     })
    //     .catch( res => {
    //       console.log(res);
    //     //   alert("Message failed to send.");
    //     })

    // };



    





    render() {
        // if(this.state.reset){
        //     return <Navigate to={'/sign-in'}/>
        // }


        const {
            newPassword,
            cpassword,
           } = this.state;

        const header = <h6>Password strength</h6>;
        const footer = (
            <React.Fragment>
                <Divider />
                <ul className="pl-2 ml-2 mt-0" style={{lineHeight: '1.5'}}>
                    <li>At least one lowercase</li>
                    <li>At least one uppercase</li>
                    <li>At least one numeric</li>
                    <li>At least one special character (!, @,#,$,%,^,&,*)</li>
                    <li>Minimum 8 characters</li>
                </ul>
            </React.Fragment>
        );

            return (

                
                
                <div >
                <Toast ref={(el) => this.toast = el} />
                     <section className="page-feature py-5">
                        <div className="container text-center">
                            <div className="row">
                                <div className="col-md-6">
                                    <h2 className=" text-left">Change Password</h2>
                                </div>
                                <div className="col-md-6">
                                    <div className="breadcrumb text-right">
                                        <Link to='/'><a >Home</a></Link>
                                        <span>/ Change Password</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="banner login-registration">
                        <div className="vector-img">
                            <img src={vector} alt=""/>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="content-box">
                                        <h2>Enter your New Password</h2>
                                        {/* <p>Enter your New Password</p> */}
                                    </div>
                                    {/* <form action="#" className="sl-form"> */}
                                    <div className="sl-form">
                                        <div className="form-group col-sm-6">
                                        <form className="password-form" onSubmit={this.updatePassword}>
                                        <div className="form-group">
                                            <label>Password</label>
                                            <Password  onChange={this.handleChange('newPassword')} value={newPassword} header={header} footer={footer} style={{width:'100%'}}/>
                                            {/* <input type="password" id='password' name='password' placeholder="Password" required 
                                             onChange={this.handleChange('newPassword')}
                                             value={newPassword} 
                                             /> */}
                                        </div>

                                        <div className="form-group">
                                            <label>Confirm Password</label>
                                            <Password onChange={this.handleChange('cpassword')} value={cpassword} header={header} footer={footer} style={{width:'100%'}}/>
                                            {/* <input type="password" id='cpassword' name='cpassword' placeholder="cpassword" required 
                                            onChange={this.handleChange('cpassword')}
                                            value={cpassword} 
                                            /> */}
                                        </div>
                                        
                                        
                                        <button className="btn btn-filled btn-round" ><span className="bh"></span> <span>Reset</span></button>
                                       </form>
                                    </div>
                                    </div>
                                    {/* </form> */}
                                </div>
                            </div>
                        </div>
                    </section>
                
                </div>
            );
    }
        }


        // NewPassword.propTypes = {
        //     // eslint-disable-next-line react/require-default-props
        //     match: PropTypes.shape({
        //       params: PropTypes.shape({
        //         token: PropTypes.string.isRequired,
        //       }),
        //     }),
        //   };

 export default withNavigation(ChangePassword);
import React, { Component, useContext,useState, useEffect,useRef } from 'react';
import { Context } from "./Store";
import axios from 'axios'
import {Route, Link, Routes, useParams,useNavigate} from 'react-router-dom';
import UserAsidePane from './utils/UserAsidePane';
import { Toast } from 'primereact/toast';
import { TransactionsPage } from './facebookPixelEvent';
import { format } from 'date-fns';
import md5 from 'crypto-js/md5';
import CryptoJS from 'crypto-js';

const key = 'b!5Tb+EjdKyaYj6{M/atWSdkghjd8945';
const iv = '1234567890000000';

// const  withNavigation = (Component) => {
//     return props => <Component {...props} navigate={useNavigate()} />;
//  }

function Profile() {
    // render() {
        // const user = localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')): null;
        const [state, dispatch] = useContext(Context);
       const [token, setToken] = useState('');
        let user = JSON.parse(state.user);
        const params = useParams();
        let navigate = useNavigate();
        const toast = useRef(null)
        
        const [id, setid] = useState(user.id || '');
        const [username, setusername] = useState(user.username || '');
        const [firstName, setfirstName] = useState(user.firstName  || '');
        const [lastName, setlastName] = useState(user.lastName  || '');
        const [email, setemail] = useState(user.email  || '');
        const [msisdn, setmsisdn] = useState(user.msisdn  || '');
        const [password, setPassword] = useState('');

       
        // const [password, setpassword] = useState(user.password  || '');
        // const [group, setgroup] = useState(user.group  || '');
        // const [enabled, setenabled] = useState(user.enabled  || '');
        // const [firstLogIn, setfirstLogIn] = useState(user.firstLogIn  || '');

        // const [showSuccessMessage, setShowSuccessMessage] = useState(false);
        // const [showErrorMessage, setShowErrorMessage] = useState(false);


        // useEffect(() => {
        //     if (showSuccessMessage || showErrorMessage) {
        //         setTimeout(() => {
        //             setShowSuccessMessage(false);
        //             setShowErrorMessage(false);
        //         }, 3000);
        //     }
        // }, [showSuccessMessage, showErrorMessage]);

        

        useEffect(() => {
            TransactionsPage();
          },[]);

       
        const show = (settings) => {
            // console.log('toast', settings)
            toast.current.show(settings);
        }

        const hanglebutton = () =>{
            dispatch({
                type: "LOG_IN", // The name of the reducer
                payload: null, // The new state of the reducer
            });
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            localStorage.removeItem('isLoggedIn');
            navigate("/sign-in", { replace: true });
        }






        const saveChanges=(e)=>{
            
            const accessString = localStorage.getItem('token');
            let user1 = JSON.parse(state.user);

            e.preventDefault();
            const user={
                        username,
                        firstName,
                        lastName,
                        email,
                        msisdn,
                        password
                }


                 
                
                // Convert object to JSON string
                const jsonString = JSON.stringify(user);
                // console.log('concatenatedString:', user);
                let keyHex = CryptoJS.enc.Utf8.parse(key);
                let ivHex = CryptoJS.enc.Utf8.parse(iv);
                let messageHex = CryptoJS.enc.Utf8.parse(jsonString);
                
                let encrypted = CryptoJS.AES.encrypt(messageHex, keyHex, {
                    iv: ivHex,
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7,
                });

               const finalText  = encrypted.toString();

 
                // console.log('concatenatedString:', finalText);


            
                       
                
                if(password !== ''){
                    // axios.post(
                    //     `${process.env.REACT_APP_BACKEND_HOST_URL}/user/${user1.id}/confirm-update`,
                    //     {
                    //         body:finalText,
                    //     },
                    //     {
                    //         headers: {
                    //             'Authorization': `Bearer ${accessString}`
                    //         }
                    //     }
                    // ).then((res)=>{
                    //   //localStorage.setItem('user',);
                    //   toast.current.show({ severity: 'success', summary: 'update', detail: 'update', life: 10000 })
          
                    //   //navigate("/sign-in", { replace: true });
                      
                    //  console.log('res',res);
                      
                      
                    // })

                          fetch(`${process.env.REACT_APP_BACKEND_HOST_URL}/user/${user1.id}/confirm-update`,{
                                method:"POST",
                                headers:{"Content-Type":"application/json",
                                //Authorization: `token ${accessString}`,
                                Authorization: `Bearer ${accessString}`,
                              },
                                body:finalText,
                                
                            }).then((response)=>{
                                response.json().then((body) => {
                                if(body.status === 401)
                                {
                                    show({
                                        severity: 'error',
                                        summary: 'Failed Updated',
                                        detail: `${body.message}`,
                                        life: 3000
                                    });

                                }
                                else{

                                    if(body.status === 200)
                                    {
                                        show({
                                            severity: 'success',
                                            summary: 'Successfully Updated',
                                            detail: `${body.message}`,
                                            life: 3000
                                        });
    
                                    }

                                }
                            
                            }
                                
                                
                                );
                          
                            //   show({
                            //       severity: 'success',
                            //       summary: 'Successfully Updated',
                            //       detail: 'Please Check your email to accept the changes.',
                            //       life: 3000
                            //   });
                  
                              //navigate("/sign-in", { replace: true });
                              
                              // console.log(res);
                              
                              
                            }
                            
                            
                            )
                        //     show({
                        //       severity: 'error',
                        //       summary: 'Failed to Update',
                        //       detail: 'Please input correct password .',
                        //       life: 3000
                        //   });



                        }
                        else{

                            show({
                                severity: 'error',
                                summary: 'Failed to Update',
                                detail: 'Please input password .',
                                life: 3000
                            });
                        }
             

            


        }
        


        // const saveChanges = async () => {
        //     try {
        //         const response = await axios.put('http://192.168.84.197:8089/onlinebillers/user/update', {
        //             username,
        //             firstName,
        //             lastName,
        //             email,
        //             msisdn,

        //         }, {
        //             headers: { 
    
        //                  Authorization: `Bearer ${token}`,
        //             }
        //         });
    

        //         const { token: newToken } = response.data;
        //         setToken(newToken);
        //         // setShowSuccessMessage(true);
        //     } catch (error) {
        //         console.log(error);
        //         // setShowErrorMessage(true);
        //     }
        // }


        // function saveChanges() {
        //     axios
        //       .put('http://192.168.84.197:8089/onlinebillers/user/update', {
        //         username,
        //         firstName,
        //         lastName,
        //         email,
        //         msisdn,
        //       })
        //       .then((response) => {
        //         headers: {
        //             'Authorization': `Bearer ${response.data}`
        //         }
        //       });
        //   }
        
        
        
        // const resetValues = () => {
        //     setfirstName(info.firstName);
        //     setfirstName(info.firstName);
        //     setlastName(info.lastName);
        //     setfirstName(info.email);
        //     setlastName(info.msisdn);
        // }





        // var today = new Date();
        // var curTime = today.getHours() + ':' + today.getMinutes();


        // private String username;
        // private String firstName;
        // private String lastName;
        // private String password;
        // private boolean enabled;
        // private boolean firstLogIn;
        // private String email;
        // private String msisdn;
        // private UserGroup group;
     
        return (
            <div>
                <Toast ref={toast}/>
                { 
                    
                    (state.user != null) ? 
                    


                    <div id="content" className="py-4">
                            <div className="container">
                                <div className="row">

                                    {/* <!-- Left sidebar --> */}
                                    <aside className="col-lg-3 sidebar">
                                        <Routes>
                                            <Route path="/" element={<UserAsidePane />} />

                                        </Routes>


                                    </aside>
                                    {/* <!-- Left Panel End --> */}

                                    <div className="col-lg-9">
                                        {/* <!-- Middle Panel --> */}
                                        <div className="col-lg-9 profile-area">
                                            <h3 className="admin-heading bg-offwhite">
                                                <a href="#" data-toggle="modal" data-target="#supportpin"><i className="fas fa-edit mr-1"></i>Update</a>
                                                <p>Personal Profile</p>
                                                <span>Your Personal information</span>
                                                {/* {showSuccessMessage && <div className="success">Successfully saved user data!</div>}
    {showErrorMessage && <div className="fail">Uh oh... something went wrong and we couldn't save changes</div>} */}
                                            </h3>
                                            <div className="infoItems shadow">

                                                <div className="tab-content">
                                                    <div id="menu1" class="tab-pane fade in active">
                                                    <div className="row">
                                                            <p className="col-sm-3"><b>Username</b></p>
                                                            <p className="col-sm-9">{user.username}</p>
                                                        </div>
                                                        <hr />
                                                        <div className="row">
                                                            <p className="col-sm-3"><b>First Name</b></p>
                                                            <p className="col-sm-9">{user.firstName}</p>
                                                        </div>
                                                        <hr />
                                                        <div className="row">
                                                            <p className="col-sm-3"><b>Last Name</b></p>
                                                            <p className="col-sm-9">{user.lastName}</p>
                                                        </div>
                                                        <hr />

                                                        <div className="row">

                                                            <p className="col-sm-3"><b>Email</b></p>
                                                            <p className="col-sm-9">{user.email}</p>
                                                        </div>
                                                        <hr />
                                                        <div className="row">
                                                            <p className="col-sm-3"><b>Mobile</b></p>
                                                            <p className="col-sm-9">{user.msisdn}</p>

                                                        </div>
                                                        <hr />


                                                        {/* <!-- Modal --> */}
                                                        <div className="modal fade step-secourity" id="supportpin" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog modal-dialog-centered " role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="exampleModalLabel">
                                                                            <p>Update Personal Information</p></h5>

                                                                        <button onClick={hanglebutton} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                         <span aria-hidden="true">&times;</span>
                                                                        </button>

                                                                        {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                            <span aria-hidden="true">&times;</span>
                                                                        </button> */}
                                                                    </div>
                                                                    <div className="modal-body">

                                                                        <div className="content-edit-area">
                                                                            <div className="edit-content">
                                                                                <form id="change-password" method="post">
                                                                                    <div class="col">
                                                                                        <div class="form-group">
                                                                                            <label for="firstName">First Name</label>
                                                                                            <input type="text" className="form-control" data-pr-form="UserName" id="firstName" required placeholder="First Name" onChange={e => setusername(e.target.value)}
                                                                                                value={firstName} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col">
                                                                                        <div class="form-group">
                                                                                            <label for="fullName">Last Name</label>
                                                                                            <input type="text" className="form-control" data-pr-form="fullName" id="fullName" required placeholder="Last Name" onChange={e => setlastName(e.target.value)}
                                                                                                value={lastName} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col">
                                                                                        <div className="form-group">
                                                                                            <label for="mobile-number">Mobile</label>
                                                                                            <input type="text" className="form-control" data-pr-form="mobile-number" id="mobile-number" required placeholder="Mobile Number" onChange={e => setmsisdn(e.target.value)}
                                                                                                value={msisdn} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col">
                                                                                        <div className="form-group">
                                                                                            <label for="emailID">Email ID</label>
                                                                                            <input type="email" className="form-control" data-pr-form="emailid" id="emailID" required placeholder="Email ID" onChange={e => setemail(e.target.value)}
                                                                                                value={email} />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col">
                                                                                        <div className="form-group">
                                                                                            <label for="password">Password</label>
                                                                                            <input type="password" className="form-control" data-pr-form="password" id="password" required placeholder="Enter Current Password" onChange={e => setPassword(e.target.value)}
                                                                                                value={password} />
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <div class="col">
        <div className="form-group">
            <label for="Current-pass">Confirm Current Password</label>
            <input type="text" className="form-control" data-pr-form="Current-pass" id="Current-pass" required placeholder="Enter Current Password"  onChange={e => setFavoriteFood(e.target.value)}
                                                                                                                                                        value={favoriteFood}/>
        </div>
    </div> */}
                                                                                    {/* <div class="col">
        <div className="form-group">
            <label for="new-password">New Password</label>
            <input type="text" className="form-control" data-pr-form="new-password" id="new-Password" required placeholder="Enter New Password"/>
        </div>
    </div>
    <div class="col">
        <div className="form-group">
            <label for="confirmPassword">Confirm New Password</label>
            <input type="text" className="form-control" data-pr-form="confirmg-password" id="confirm-Password" required placeholder="Enter Confirm New Password"/>
        </div>
    </div> */}

                                                                                    <button onClick={saveChanges} className="btn btn-default" type="submit">Save Changes</button>
                                                                                </form>


                                                                                <Routes>
                                                                                    <Route path="/profile" element={<Profile />} />

                                                                                </Routes>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>



                                        </div>
                                        {/* <!-- Middle Panel End --> */}


                                    </div>
                                </div>
                            </div>
                        </div>

                    
                        
                    :<></>
                }
            </div>
        );
    // }
}

export default Profile;
import React, { Component } from 'react';
import "aos/dist/aos.css";
import { AuthService } from '../services/AuthService';
import { Link } from 'react-router-dom'
import vector from '../images/vector.png';
import { UserService } from '../services/UserService';
import { Toast } from 'primereact/toast';
import {Navigate, useNavigate} from 'react-router-dom';
import axios from 'axios'
import { SocialIcon } from 'react-social-icons';
import { ContactUs } from './facebookPixelEvent';


export const  withNavigation = (Component) => {
    return props => <Component {...props} navigate={useNavigate()} />;

  } 

        

class Contact extends Component{

    
  
    constructor(props) {
        super(props); 
       this.state = {
          firstName: '',
          lastName:'',
          phonenumber:'',
          email: '',
          phonenumber:'',
          message:'',
        sent: false,
        buttonText: 'Send Message'
    }

    this.authService = new AuthService();
}


    componentDidMount() {

        ContactUs();

        // console.log(ContactUs(),'contactus');
    }


      
    // {

    //     "firstName": "Bongani",
    
    //     "lastName": "Masuku",
    
    //     "email": "Bongani.Masuku@ecocashholdings.co.zw",
    
    //     "phoneNumber": "0772222222",
    
    //     "message": "Test"
    
    // }

    formSubmit = (e) => {
        e.preventDefault()
      
        this.setState({
            buttonText: '...sending'
        })
      
        let data = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phoneNumber: this.state.phoneNumber,
            message: this.state.message
        }
        
        this.authService.getToken().then(
            response => this.setState({token: response.data.body}, () => {
                console.log('token',this.state.token)

                axios.post(
                    `${process.env.REACT_APP_BACKEND_HOST_URL}/email/send-contact-us-query`,
                    {
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        email: this.state.email,
                        phoneNumber: this.state.phoneNumber,
                        message: this.state.message
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${response.data.body}`
                        }

                    }
                )
                .then( res => {
        
        
        
                    console.log('response',res);
                   
        
                    if (res.data.status === 500) {
                        this.toast.show({ severity: 'error', summary: 'Error creating account', detail: 'User already exists', life: 3000 });
                     
                    } else if (res.data.status === 423) {
                        this.toast.show({ severity: 'error', summary: 'Error creating account', detail: `${res.data.message}`, life: 3000 });
                  
                    } else {
                        this.toast.show({ severity: 'success', summary: 'Equiry Sent', detail: 'Your message was sent successfully.', life: 5000 });
                        this.setState({ sent: true }, this.resetForm())
                    }
                })
                .catch( () => {
                //   console.log('Message not sent');
                //   alert("Message failed to send.");
                })



            }))
      
      }

      resetForm = () => {
        this.setState({
            firstName: '  ',
            lastName:'',
            email: '',
            phoneNumber: '',
            message: '',
            buttonText: 'Message Sent'
        })
    }


    // handleSubmit(e){
    //     e.preventDefault();
    //     axios({
    //       method: "POST",
    //       url:"http://192.168.84.197:8089/onlinebillers/email/send-contact-us-query",
    //       data:  this.state
    //     }).then((response)=>{
    //         console.log(response);
    //       if (response.data.status === 'success') {
    //         alert("Message Sent.");
    //         this.resetForm()
    //       } else if (response.data.status === 'fail') {
    //         alert("Message failed to send.")
    //       }
    //     })
    //   }

    // resetForm(){
    //     this.setState({firstName: '', lastName:'', email: '', phoneNumber:'', message: ''})
    //   }


    


    render(){

        
        return(
            <div>

                 <Toast ref={(el) => this.toast = el} />

                    <section className="page-feature py-5">
                        <div className="container text-center">
                            <div className="row">
                                <div className="col-md-6">
                                    <h2 className=" text-left">Contact</h2>
                                </div>
                                <div className="col-md-6">
                                    <div className="breadcrumb text-right">
                                    <Link to='/'><a >Home</a></Link>
                                        <span>/ Contact</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="contact">
        <div className="container">
            <div className="row">
                <div className="col-lg-7 col-md-9 m-auto text-center">
                    <div className="sec-heading">
                        <span className="tagline">Contact us</span>
                        <h3 className="sec-title">If You Have Any Query, <br/>Drop a Message.</h3>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-8">
                    <form onSubmit={ (e) => this.formSubmit(e)} method="POST">
                        <div className="row">
                            <div className="form-group col-sm-6">
                                <label  htmlFor="name">Full name</label>
                                <input onChange={e => this.setState({ firstName: e.target.value})} name="name" className="form-control" type="text" placeholder="Your Name" value={this.state.firstName}/>
                            </div>
                            <div className="form-group col-sm-6">
                                <label htmlFor="last_name">Last name</label>
                                <input onChange={e => this.setState({ lastName: e.target.value})} name="last_name" className="form-control" type="text" placeholder="Last Name" value={this.state.lastName}/>
                            </div>
                            <div className="form-group col-sm-6">
                                <label htmlFor="email">email</label>
                                <input onChange={(e) => this.setState({ email: e.target.value})} name="email" className="form-control" type="email" placeholder="your@email.com" required value={this.state.email} />
                            </div>
                            <div className="form-group col-sm-6">
                                <label htmlFor="phone">Phone number</label>
                                <input onChange={(e) => this.setState({ phoneNumber: e.target.value})} type="text" name="phone" className="form-control" id="phone" placeholder="Phone number" value={this.state.phoneNumber}/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Your Message</label>
                            <textarea onChange={e => this.setState({ message: e.target.value})} name="message" className="form-control" type="text" placeholder="Please write your message here" value={this.state.message} required/>
                        </div>
                        <button type="submit" className="btn btn-default">{ this.state.buttonText }</button>
                    </form>
                </div>
                <div className="col-md-4">
                    <aside className="sidebar">
                        <div className="widget contact-info">
                            <h3 className="widget-title">Contact info</h3>
                            <p>Please call, email or fill in the contact form and we will be happy to assist you</p>
                            <div>
                                <h6>Location Info</h6>
                                <span> EcoCash Holdings, 1906 Borrowdale Road, Harare, Zimbabwe</span>
                            </div>
                            <div>
                                <h6>Hotline numbers</h6>
                                <a href="tel:+263771222151">+263771222151</a>
    
                            </div>
                            <div>
                                <h6>Email Us</h6>
                                <a href="mailto:ecocashhelpdesk@ecocashholdings.co.zw">ecocashhelpdesk@ecocashholdings.co.zw</a>
                            

                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <h6>Social Media</h6>
                                    <ul className="bill-items nav nav-tabs" id="teleporter">
                                        <li className='nav-item'><a href='https://twitter.com/EcoCashZW' target='_blank' className="nav-link"><i className="ti-twitter fa-lg"></i></a></li>
                                        <li className='nav-item'><a href='https://www.facebook.com/ecocashzim/?fref=ts' target='_blank' className="nav-link"><i className="ti-facebook fa-lg"></i></a></li>
                                        <li className='nav-item'><a href='https://www.linkedin.com/company/ecocashholdings/mycompany/' target='_blank' className="nav-link"><i className="ti-linkedin fa-lg"></i></a></li>
                                        <li className= 'nav-item'><a href='https://www.youtube.com/user/econetzimbabwe' target='_blank' className="nav-link"><i className="ti-youtube fa-lg"></i></a></li>
                                    </ul>
                                    

                                </div>
                            </div>
                 
                        </div>
                    </aside>
                   {/* <div>
                   <SocialIcon style={{ height: 30, width: 30 }}  url="https://twitter.com/" />
                    <SocialIcon style={{ height: 30, width: 30 }}  url="https://facebook.com/" />
                    <SocialIcon style={{ height: 30, width: 30 }}  url="https://whatsapp.com/" />
                    <SocialIcon style={{ height: 30, width: 30 }}  url="https://instagram.com/" />
                    <SocialIcon style={{ height: 30, width: 30 }}  url="https://www.linkedin.com/" />
                   </div>
                     */}

                </div>
            </div>
        </div>
    </section>

            </div>



        );

    }




}

export default withNavigation(Contact);
import React, { Component } from 'react';

class TransactionDetails extends Component {
    constructor(props){
        super(props)

        console.log(props.biller)
    }
    render() {
        return (
            <div>
                <div class="col-lg-12">
                        <div class="profile-content">
                            <div class="order-summery transaction-summery">
                                <div class="order-details-area table">
                                    <div class="table-head">
                                        <div class="modal_header">
                                            <span class="status bg-success">{this.props.tDetails.status}</span>
                                            <div class="row">
                                                <div class="col">
                                                    <div class="content-area">
                                                        <b>Payment sent to</b>
                                                        &nbsp;
                                                        {this.props.tBillerName}
                                                        <p>{`Transation date :  ${ new Date().toLocaleString() + ""}`}</p>
                                                        <p>Payment Status: {this.props.tDetails.status}</p>
                                                        {/* <p>Biller Category: {this.props.biller.billerCategory.name}</p> */}
                                                        <p>Account Number: {this.props.tDetails.accountNumber}</p>
                                                        <p>payingMsisdn: {this.props.tDetails.msisdn}</p>
                                                        <p>Zbc Package: {this.props.tBroadBandPackage}</p>
                                                        <p>Duration: {this.props.tDuration}</p>
                                                       
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="content-area text-right">
                                                        <div class="price">
                                                            <b>Amount</b>
                                                            <p>{this.props.tDetails.currency}  {this.props.tDetails.amount}</p>
                                                        </div>                                                        
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Recent Activity End --> */}
                        </div>
                </div>
            </div>
          
        );
    }
}

export default TransactionDetails;
function TermsAndConditions(){
    return(
        <div>
            <div style={{paddingLeft:'20px'}}> 
                A Recurring Bill Payment is effective from the following month of the Registration Date and is outlined as follows:
                <br/>
                <br/>
                <ul style={{listStyleType: 'disc', paddingLeft:'40px'}}>
                    <li>The customer's EcoCash wallet is automatically deducted each month with the secheduled amount on the same date on which a bill payment was originally marked or registered as recurring. That is if a customer registers a bill payment as recurring on the 3rd of a given month, recurring payments will be processed on 3rd of the following month and each month thereafter.</li>
                    <br/>
                    <li>A recurring bill payment will also result in the customer's account being instantly settled with the scheduled amount.</li>
                    <br/>
                    <li>A customer can register or mark a bill payment as recurring through the EcoCash Online Bill Payments portal or using short-code *151*100#.</li>
                    <br/>
                    <li>A customer can deregister a bill payment as recurring via the same platforms and deregistration becomes effective immediately.</li>
                </ul>
            </div>
            

        </div>
    ) 
}

export default TermsAndConditions;
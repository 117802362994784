import React, { Component } from 'react';
import TermsAndConditions from './TermsAndConditions';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';


class WhatWeDoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            position:'center',
            isShowingTermsAndConditionsDialog:false

        }

        this.renderFooter = this.renderFooter.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    onClick(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }

        this.setState(state);
    }

    onHide(name) {
        this.setState({
            [`${name}`]: false,
        });
    }

    renderFooter(name) {
        return (
            <div>
                <Button label="Close" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-text" />
            </div>
        );
    }


    render() {
        return (
            <div>
                <div className="container">
                     <div className="row">
                         <div className="col-lg-6 col-md-9 m-auto text-center">
                             <div className="sec-heading">
                                 <span className="tagline">What We Do </span>
                                 <h3 className="sec-title">Simple, fast and hassle free payments </h3>
                             </div>
                         </div>
                     </div>
                     <div className="row">
                         <div className="col-lg-6">
                             <div className="process">
                                 <ul>
                                     <li className="card card-body flexi-fill" style={{minHeight:'160px'}}>
                                         <span className="icon-bg">
                                             <i className="ti-money"></i>
                                         </span>
                                         <h5>Online Bill Payments</h5>
                                         <p>Make online bill payments with EcoCash for real-time and instant validation and settlement of your account, policy or subscription.                                                                                                                                                                     </p>
                                     </li>
                                   
                                     <li className="card" style={{minHeight:'160px'}}>
                                         <span className="icon-bg">
                                             <i className="ti-save"></i>
                                         </span>
                                         <h5>Save Favourites</h5>
                                         <p>Allow us to remember your account, policy or subscription numbers of your favourite and frequent billers for you. Start saving today to make express and simple payments.</p>
                                     </li>
                                    
                                     <li className="card" style={{minHeight:'160px'}} >
                                         <span className="icon-bg">
                                             <i className="ti-receipt"></i>
                                         </span>
                                         <h5>Transactions</h5>
                                         <p>Get a statement of all your paid bills and easily view and manage your transactions. Register and Login to manage your transactions in a safe and secure way.</p>
                                     </li>
                                 </ul>
                             </div>

                         </div>
                         <div className="col-lg-6">
                             <div className="process">
                                 <ul>
                                     <li className="card card-body flexi-fill" style={{minHeight:'160px'}}>
                                         <span className="icon-bg">
                                             <i className="ti-reload"></i>
                                         </span>
                                         <h5>Recurring Payments </h5>
                                         <p>Allows you to register for monthly auto-debit recurring payments and you can deregister anytime.&nbsp;Terms and Conditions apply.</p>
                                     </li>
                                  

                                     <li className="card" style={{minHeight:'160px'}}>
                                         <span className="icon-bg">
                                             <i className="ti-search"></i>
                                         </span>
                                         <h5>Get Account Balance</h5>
                                         <p>Get your outstanding balance, instalment or subscription due. Know what amount you need to pay before authorising your payments.</p>
                                     </li>

                                     <li className="card" style={{minHeight:'160px'}}>
                                         <span className="icon-bg">
                                             <i className="ti-hand-point-up"></i>
                                         </span>
                                         <h5>Express Payments</h5>
                                         <p>After you save your favourite and frequent billers, you can easily make express payments and significantly reduce the time you need to pay your bills.</p>
                                     </li>
                                   
                                 </ul>
                             </div>

                         </div>
                     </div>
                 </div>

                 <Dialog header= { <h3 class="admin-heading bg-offwhite">
                                            <p>Terms and Conditions</p>
                                            <span>Recurring Payment Commencement Date and Duration</span>
                                        </h3>
                                    } visible={this.state.isShowingTermsAndConditionsDialog} position={this.state.position} modal style={{ width: '70%', height:'70%' }} footer={this.renderFooter('isShowingTermsAndConditionsDialog')} onHide={() => this.onHide('isShowingTermsAndConditionsDialog')} breakpoints={{'960px': '75vw'}}>
                           <TermsAndConditions/>
                </Dialog> 

            </div>
        );
    }
}

export default WhatWeDoComponent;

import React, {useContext, useState} from "react";
import {BundleCategoryContext, BundleContext} from "../config/contexts";
import FormLabel from "@material-ui/core/FormLabel";

const BundleCategory = () => {
    const {responseBundleCategory, selectedBundleCategory, setSelectedBundleCategory, data} = useContext(BundleContext);
    return (
        <div>

            <div className="row">
                <div className="col-md-12 form-area pt-5">
                    <div className='text-center'>
                        <FormLabel id="airtime-radio-buttons-group-label" className="text-4 mb-3"><h5
                            className="text-4 mb-3">Choose Bundles Category</h5></FormLabel>
                    </div>
                    <div className="mb-3" style={{padding:'5px'}}> 
                        {responseBundleCategory.map((bundle, i) => {
                            return (
                                <div className="custom-control custom-radio custom-control-inline"
                                     key={i}>
                                    <label>

                                        <input type="radio"
                                               name="biller"
                                               value={parseInt(bundle.id)}
                                               onChange={(e) => {
                                                   const bundle = responseBundleCategory.filter((b) => b.id == e.target.value)[0]
                                                   setSelectedBundleCategory(() => ({...bundle, payingMsisdn: data?.payingMsisdn, receivingMsisdn: data?.receivingMsisdn}))
                                               }}
                                        />
                                        <div className="card align-items-center" style={{
                                            height: '160px',
                                            width: '210px',
                                            borderRadius: "15px",
                                            boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
                                        }}>
                                            {/* <div className='custom-control-inline d-flex flex-row justify-content-center col-md-12 text-center'> */}
                                                <div className="card-body ">
                                                    <img src={bundle.imageUrl} alt={bundle.name}/>
                                                </div>
                                                <p className='text-5' style={{fontSize:'.75em'}}>{bundle.displayText}</p>
                                            {/* </div> */}

                                        </div>
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>


        </div>
    )
}

export default BundleCategory;


// <FormControl>
//     <FormLabel id="airtime-radio-buttons-group-label" className="text-4 mb-3"><h5
//         className="text-4 mb-3">Choose Bundles Category</h5></FormLabel>
//     <RadioGroup
//         aria-labelledby="airtime-radio-buttons-group-label"
//         value={parseInt(selectedBundleCategory.id) || ""}
//         row
//         name="radio-buttons-group"
//         // onChange={(e) => this.props.setOption((state) => {state.numberOption = e.target.value})}
//         onChange={(e) => {
//             const bundle = responseBundleCategory.filter((b) => b.id == e.target.value)[0]
//             setSelectedBundleCategory(() => ({...bundle, payingMsisdn: data?.payingMsisdn, receivingMsisdn: data?.receivingMsisdn}))
//         }}
//     >
//         {responseBundleCategory.map((bundle, i) => {
//             return (
//                 <FormControlLabel key={i} value={parseInt(bundle.id)} control={<Radio/>} label={bundle.displayText}/>
//             )
//         })}
//     </RadioGroup>
// </FormControl>
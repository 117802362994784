import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation
  } from "react-router-dom";

function CmsUserAsidePane(){

    useLocation(); 
    return(
        <div>
            <div className="widget admin-widget p-0">
                                        <div className="Profile-menu">
                                            <ul className="nav secondary-nav">
                                                <Link to='/'><li className={window.location.pathname == '/' ? `nav-item active` :  `nav-item`}><a className="nav-link"><i className="fas fa-tachometer-alt"></i>Home</a></li></Link>
                                                <Link to='/upload-banner'><li className={window.location.pathname == '/upload-banner' ? `nav-item active` :  `nav-item`}><a className="nav-link"><i className="fas fa-tachometer-alt"></i>Upload Banners</a></li></Link>
                                                <Link to='/bulk-airtime-bundles'><li className={window.location.pathname == '/bulk-airtime-bundles' ? `nav-item active` :  `nav-item`}><a className="nav-link"><i className="fas fa-tachometer-alt"></i>update WHAT WE DO</a></li></Link>
                                                {/* <Link to='/dashboard'><li className={window.location.pathname == '/dashboard' ? `nav-item active` :  `nav-item`}><a className="nav-link"><i className="fas fa-tachometer-alt"></i>Dashboard</a></li></Link>
                                                <Link to='/add-biller'><li className={window.location.pathname == '/add-biller' ? `nav-item active` :  `nav-item`}><a className="nav-link"><i className="fab fa-autoprefixer"></i>Save Favourite</a></li></Link>
                                                <Link to='/dashboard'><li className={window.location.pathname == '/dashboard' ? `nav-item active` :  `nav-item`}><a className="nav-link"><i className="fas fa-tachometer-alt"></i>Saved Billers</a></li></Link>
                                                <Link to='/transactions'><li className={window.location.pathname == '/transactions' ? `nav-item active` :  `nav-item`}><a className="nav-link"><i className="fas fa-list-ul"></i>Transactions</a></li></Link>
                                                <Link to='/statements'><li className={window.location.pathname == '/statements' ? `nav-item active` :  `nav-item`}><a className="nav-link"><i className="fas fa-list-ul"></i>Wallet Statement</a></li></Link> */}
                                    
                                            </ul>
                                        </div>
                                    </div>
                
                                    {/* <div className="widget admin-widget">
                                        <i className="fas fa-comments admin-overlay-icon"></i>
                                        <h2>Wallet Statement</h2>
                                        <p>Do you want a list of your transactions for a particular period?<br/>
                                            Click below!</p>
                                            <Link to='/statements'><a  className="btn btn-default btn-center" style={{color:'#fff'}}><span>Wallet Statements</span></a></Link>
                                    </div> */}
            
        </div>
    )
}
export default CmsUserAsidePane;
import React, { Component,useRef } from 'react';
import "aos/dist/aos.css";
import { AuthService } from '../services/AuthService';
import { Link } from 'react-router-dom'
import vector from '../images/vector.png';
import { UserService } from '../services/UserService';
import { Toast } from 'primereact/toast';
import {Navigate, useNavigate} from 'react-router-dom';
import axios from 'axios'
import { SocialIcon } from 'react-social-icons';
import { ContactUs } from './facebookPixelEvent';
import UserAsidePane from './utils/UserAsidePane';
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import {DataTable} from 'primereact/datatable';
import {Dialog} from 'primereact/dialog';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {confirmPopup} from 'primereact/confirmpopup';
import {ProgressSpinner} from 'primereact/progressspinner';
import ReactPolling from "react-polling";
//import TransactionDetails from './TransactionDetails';
import TransactionDetails from './SmartbizTransactionDetails';
import config from '../components/utils/config';
import CryptoJS from 'crypto-js';
import imgBundles from '../../src/buybundles.png'
import { PaymentService } from '../services/PaymentService';




export const  withNavigation = (Component) => {
    return props => <Component {...props} navigate={useNavigate()} />;

  } 

  const key2 = config.encryptionkey2;
  const iv = config.encryptioniv;
  


        
  
class SmartbizUsdBundles extends Component{


    constructor(props) {
        super(props);
        this.state = {
            Categories:null,
            SelectedPackage:null,
            body:[],
            //numberOptions:null,
            numberOption: 'self',
            setNumberOption:null,
            BroadbandState:null,
            payingMsisdn:'',
            receivingMsisdn:null,
            accountNumber:'',
            mpin:'',
            currency: '',
            price:'',
            disableProcessPayment: false,
            accountNumber:'',
            isShowingPaymentDetailsDialog:false,
            token:'',
            position: 'bottom',
            details: [],
            sourceReference:'',
            isProcessingPayment: false,
            isShowingPaymentTransactionDetailDialog: false,
            transactionDetails: null,
            isShowingTermsAndConditionsDialog: false,
            accountName:'',
            productId:'',
            packageName:'',
            productId:'',
            Buyoption:'',
            Billercode:'34749',
            token:null,
            transactionDetailsList:null,
            pin:'',

        };
        this.textInput = React.createRef();
    
        this.handleDisplayPaymentConfirmation = this.handleDisplayPaymentConfirmation.bind(this);
        this.renderFooter = this.renderFooter.bind(this);
        // this.paymentConfirmation =  this.paymentConfirmation.bind(this);
        this.epgpaymentConfirmation = this.epgpaymentConfirmation.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onClick = this.onClick.bind(this);
        // this.accept = this.accept.bind(this);
        this.epgaccept = this.epgaccept.bind(this);
        this.encrypt = this.encrypt.bind(this);
        this.reject = this.reject.bind(this);
        this.authService = new AuthService();
        this.paymentService = new PaymentService();

    };


    onClick(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }

        this.setState(state);
    }

    onHide(name) {
        this.setState({
            [`${name}`]: false,
        });
    }


    


    renderFooter(name) {
        return (

            
            
            <div>
                

                <div style={{ flexDirection: "row" , justifyContent: 'space-between' }}>
{/*                                
                                <button disabled={this.state.disableProcessPayment} className="btn btn-filled btn-round" 
                                        onClick={this.paymentConfirmation}><span className="bh"  ></span> <span>Process payment</span>
                                </button> */}

                                <Button label="Cancel" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-text"/>

                </div>
            </div>
        );
    }

    // paymentConfirmation(event){

    //     confirmPopup({
    //         target: event.currentTarget,
    //         message: 'Are you sure you want to proceed?',
    //         icon: 'pi pi-exclamation-triangle',
    //         accept: this.accept,
    //         reject: this.reject
    //     });
        
    // }


    epgpaymentConfirmation(event){

      

        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            accept : this.epgaccept,
            reject: this.reject
        });
        
    }

    reject() {
        // this.toast.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    encrypt = (pin) => {
        try {
            // const jsonString = JSON.stringify(pin);
            // console.log('concatenatedString:', user);
            let keyHex = CryptoJS.enc.Utf8.parse(key2);
            let ivHex = CryptoJS.enc.Utf8.parse(iv);
            let messageHex = CryptoJS.enc.Utf8.parse(pin);
            
            let encrypted = CryptoJS.AES.encrypt(messageHex, keyHex, {
                iv: ivHex,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            });
        
           const finalText  = encrypted.toString();
           console.error('finalText', finalText);
            // Return the result as a base64 encoded string
            return finalText
        } catch (error) {
            console.error('Encryption error:', error);
            throw error;
        }
    };

    

    epgaccept = async () => {
        try {
            // Encrypt the pin
            const encrypted = await this.encrypt(this.state.pin);
            console.log("pin", encrypted);
    
            // Set the encrypted pin in the state
            this.setState({ pin: encrypted });
    
            // Log the updated pin state
            console.log("pin", this.state.pin);
    
            // Show progress spinner
            // this.setState({ showProgressSpinner: true });
    
            // Ensure msisdn is correctly formatted
            let msisdn = this.state.payingMsisdn;
            if (msisdn.startsWith('0')) {
                msisdn = msisdn.substring(1);
            }
    
            console.log(msisdn);
            // Prepare payment DTO
            let paymentDto = {
                partnerCode: "SMARTBIZ",
                accountNumber: this.state.accountNumber,
                // packageCode: "activee",
                currency: this.state.currency,
                amount: this.state.price,
                msisdn: msisdn,
                walletId: "12",
                pin: encrypted, // Use the encrypted pin from the state
                secure: true,
                packageCode:this.state.productId,
            };
    
            console.log("paymentDto", paymentDto);
    
            // Make the payment
            const response = await this.paymentService.EpgmakePayment(this.state.token, paymentDto);
    
            // Handle response
            // console.log("response", response.data.body.status);
            console.log("response", response);
    
            if (response.data.body.status === "200") {
                this.toast.show({
                    severity: 'info',
                    summary: 'Payment success',
                    detail: 'Your payment was successful',
                    life: 5000
                });
    
                this.setState({
                    isProcessingPayment: false,
                    showProgressSpinner: false,
                    msisdn: '',
                    accountNumber: this.state.accountNumber,
                    amount: '',
                    isShowingPaymentTransactionDetailDialog: true,
                    transactionDetails: response.data.body
                });
    
                return false;
            } else if (response.data.status === 400) {
                console.log('failed', response);
    
                this.toast.show({
                    severity: 'error',
                    summary: 'Transaction failed',
                    detail: `${response.data.message}`,
                    life: 5000
                });
    
                this.setState({ 
                    isProcessingPayment: false,
                    showProgressSpinner: false,
                    isShowingPaymentTransactionDetailDialog: false,
                });
                return false;
            }
    
            window.fbq('track', this.state.selectedBiller.name, { ProcessPayment: 'ProcessPayment' });
    
        } catch (error) {
            // console.error("Payment error", error);
            // this.toast.show({
            //     severity: 'error',
            //     summary: 'Error',
            //     detail: 'An error occurred during the payment process. Please try again later.',
            //     life: 5000
            // });
            this.setState({ isProcessingPayment: false, showProgressSpinner: false });
        }
    }


    // accept() {
    //     let paymentDto = {
    //         endUserId: this.state.payingMsisdn,
    //         billerCode: this.state.Billercode,
    //         billerName: 'TelOne Prepaid',
    //         amount: this.state.price,//price
    //         accountNumber: this.state.accountNumber,
    //         accountName: this.state.accountName,
    //         // packageCode: (this.state.selectBillerPackage != null ? this.state.selectBillerPackage.packageCode : null),
    //         packageCode: `${this.state.numberOption}&${this.state.productId}`,
          
    //         currency: this.state.currency == 'ZWG' ? "ZWG" : this.state.currency,
    //         //teloneAccountType:  (this.state.teloneAccountType != null ? this.state.teloneAccountType : null),
    //         productId:this.state.productId,
    //         receiving:  this.state.numberOption == 'other' ? this.state.payingMsisdn : null,

    //     };
        
    //     //console.log('paymentDto',paymentDto);


    //     // axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/auth/generatetoken`, {
    //     //     username: `${process.env.REACT_APP_USERNAME}`,
    //     //     password: `${process.env.REACT_APP_PASSWORD}`,
    //     //   }).then((response)=>{
    //     //     this.setState({token:response.data.body});
    //     //     console.log('token',this.state.token);

              
    //     axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/payment/amount`, paymentDto,
    //         {
    //         headers: {
    //             'Authorization': `Bearer ${this.state.token}`
    //         }
    //         }
    //     )
    //         .then((response) => {
    //            // console.log('acccept postpayment',response.data)
    //             this.setState({
    //                 sourceReference: response.data.body.field3,
    //                 isProcessingPayment: true,
    //                 isShowingPaymentDetailsDialog: false
    //             });
    //         })
    //         .catch((e) => {
               
    //            // console.log(e,'errrrrror')
    //         })
    //    // })


    // }

    
    componentDidMount() {


        //this.setState({currency:localStorage.getItem("currency")});
        //console.log(this.state.currency,'currencyontactus');



    //   axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/auth/generatetoken`, {
    //     username: `${process.env.REACT_APP_USERNAME}`,
    //     password: `${process.env.REACT_APP_PASSWORD}`,
    //   }).then((response)=>{

    this.authService.getToken().then(
    
        response => this.setState({token: response.data.body}, () => {
            const getData = "data";

          
    axios.get(`${process.env.REACT_APP_BACKEND_HOST_URL}/epggateway/billpay/smartbiz/find-all`,
    )
        .then((res) => {
            
            const { body } = res.data;

            // Directly set the array in state
            this.setState({ Categories: body }, () => {
                console.log(this.state.Categories, 'formatted JSON object');
            });

            this.state.Categories.map((category) => {
                console.log(category.price);
            });

            this.setState({currency:body.currency});
        })
        .catch((e) => {
            // show({
            //     severity: 'error',
            //     summary: 'Failed',
            //     detail: 'Bundle categories could not be fetched!',
            //     life: 3000
            // })
            //console.log(e,'errrrrror')
        })
   // })

}));
}

// handleClickSetFocus (){
//         this.textInput.current.focus();
//       };

handleDisplayPaymentConfirmation() {

        let accountNumber = this.state.accountNumber;

        // Check if accountNumber starts with '0' and replace it with '263'
        if (accountNumber.startsWith('0')) {
            accountNumber = '263' + accountNumber.substring(1);
        } 
        // Check if accountNumber starts with '7' and prepend '263'
        else if (accountNumber.startsWith('7')) {
            accountNumber = '263' + accountNumber;
        }
        

        // Optionally, if you want to check if it already starts with '2637' and do nothing:
        if (!accountNumber.startsWith('2637')) {
            // Additional logic for cases that don't match the above conditions
        }

        this.setState({ accountNumber });

    console.log(this.state.accountNumber);

    // this.setState({
    //     disableProcessPayment: disableProcessPayment,
    //     details: [
    //         { 'name': 'Account Number', 'value': this.state.accountNumber},
    //         {'name': 'Account Name', 'value': this.state.payingMsisdn},
    //         {'name': 'Biller Name', 'value': this.state.SelectedPackage},
    //     ]
    // });
    // this.setState({isShowingPaymentDetailsDialog: true})
    //this.setState
   
    // axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/auth/generatetoken`, {
    //     username: `${process.env.REACT_APP_USERNAME}`,
    //     password: `${process.env.REACT_APP_PASSWORD}`,
    //   }).then((response)=>{

    this.authService.getGuestUserTokenWithMsisdn(this.state.payingMsisdn).then(
    
        response => this.setState({token: response.data.body}, () => {
           console.log(response.data.body);
          
    axios.get(`${process.env.REACT_APP_BACKEND_HOST_URL}/epg/biller/find-biller-account/SMARTBIZ/0/${this.state.accountNumber}/${this.state.payingMsisdn}`,
        {
        headers: {
            'Authorization': `Bearer ${response.data.body}`
        }
        }
    )
        .then((res) => {
           // console.log('lookup',res.data.body );
            // console.log('lookupdescription',res.data.body.description
            // );

            this.setState({accountName:res.data.body.accountName});

            var disableProcessPayment = false; 

            if ( res.data.body.accountName === 'Your number is not a smartbiz account number') {
                this.setState({accountName:'Your mobile number is not a smartbiz number'});
                    
                disableProcessPayment =  true;

             }

          

            
            this.setState({
                          disableProcessPayment: disableProcessPayment,
                         details: [
                             { 'name': 'SmartBiz Number', 'value': this.state.accountNumber},
                             {'name': 'Ecocash Number', 'value': this.state.payingMsisdn},
                             this.state.accountName != null ? {'name': 'Account Name', 'value': this.state.accountName }:  {'name': 'Account Name', 'value': res.data.body.description },
                            //  {'name': 'Biller Code', 'value': this.state.Billercode },
                             {'name': 'SmartBiz Package', 'value':  this.state.SelectedPackage},
                             {'name': 'Currency', 'value':  this.state.currency},
                             {'name': 'Price', 'value':  this.state.price},
                            
                         ]
                     });
            this.setState({isShowingPaymentDetailsDialog: true})
        })
        .catch((e) => {
            // show({
            //     severity: 'error',
            //     summary: 'Failed',
            //     detail: 'Bundle categories could not be fetched!',
            //     life: 3000
            // })
            // console.log(e,'errrrrror')
        })
    //})
    }));

}

      



    


    render(){

        
        return(
            <div>
                <Toast ref={(el) => this.toast = el}/>

               <div className="row">
                <div className="col-md-12 form-area pt-5">

                                
                {
    this.state.Categories && this.state.Categories.length > 0 ? (
        this.state.Categories.map((category) => (
            <div className="custom-control custom-radio custom-control-inline" key={category.id}>
                <label>
                    <input 
                        type="radio" 
                        name="biller" 
                        value={category.packageName} 
                        // onClick={() => this.textInput.current.focus()}
                        onChange={(e) => this.setState({
                            SelectedPackage: e.target.value,
                            price: category.price,
                            currency: category.currency,
                            productId: category.code
                        })}
                    />
                    
                    <div className="card align-items-center" style={{
                        borderRadius: "15px",
                        boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                        width: "80%",
                        height: "80%"
                    }}>
                        <div className="card-body">
                            <img src={imgBundles} alt={category.packageName} />
                        </div>
                        
                        <p className="text-5">{category.packageName}</p>
                        <p className="text-5">{`USD ${category.price}`}</p>
                    </div>
                </label>
            </div>
        ))
    ) : (
        <p>No categories available</p>
    )
}
             </div>
             </div>

            
               <FormControl>
                <FormLabel id="airtime-radio-buttons-group-label" className="text-4 mb-3"><h5
                    className="text-4 mb-3">Buy SmartBiz Subscription for</h5></FormLabel>
                {/* <RadioGroup
                    aria-labelledby="airtime-radio-buttons-group-label"
                    defaultValue={'self'}
                    row
                    name="radio-buttons-group"
                    onChange={(e) => {this.setState({numberOption:e.target.value})}}
                >
                    <FormControlLabel value={'self'} control={<Radio/>}   label="My Number"/>
                    <FormControlLabel value={'other'} control={<Radio/>} label="Other Mobile"/>
                </RadioGroup> */}
              </FormControl>



              <div className="row">
                <div className="m-auto">
                    <form>
                        <div className="row">


                        <div className="form-group col-sm-6">
                                <label htmlFor="pin">SmartBiz Number<small>(required)</small></label>
                                <input name="pin"
                                       className="form-control" type="text" 
                                       required="required"
                                       placeholder="Enter your Account Number 2637******"
                                       value={this.state.accountNumber}
                                       onChange={(e) => {
                                        e.persist();
                                        this.setState((state) => (state.accountNumber = e.target.value))
                                    }}
                                />
                            </div>


                            <div className="form-group col-sm-6">
                                <label htmlFor="pin">Ecocash
                                    Number<small>(required)</small></label>
                                <input name="payingMsisdn"
                                       className="form-control" type="text" 
                                       required="required"
                                       placeholder="Enter Mobile Number (07********)"
                                       value={this.state.payingMsisdn}
                                       onChange={(e) => {
                                        e.persist();
                                        this.setState((state) => (state.payingMsisdn = e.target.value))
                                    }}
                                />
                            </div>
                            
                                
                                {/* <div className="form-group col-sm-6">
                                <label htmlFor="payingMsisdn">My Mobile
                                    Number<small>(required)</small></label>
                                <input name="payingMsisdn"
                                       className="form-control" type="text"
                                       required="required"
                                       placeholder="Enter Mobile Number (07********)"
                                       value={this.state.payingMsisdn}
                                       onChange={(e) => {
                                        e.persist();
                                        this.setState((state) => (state.payingMsisdn = e.target.value))
                                    }}
                                    
                                />
                            </div> */}


                            <div className="form-group col-sm-6">
                                        <label htmlFor="payingMsisdn">Ecocash Pin<small>(required)</small></label>
                                                <input
                                                            type="password" // Changed to "password" to hide the input for PIN
                                                            className="form-control"
                                                            data-bv-field="pin" // Updated to "pin"
                                                            id="pin" // Updated to "pin"
                                                            value={this.state.pin} // Updated to "this.state.pin"
                                                            required
                                                            placeholder="Enter PIN" // Updated placeholder text
                                                            onChange={(e) => {
                                                                e.persist();
                                                                this.setState((state) => (state.pin = e.target.value)); // Updated to "state.pin"
                                                            }}
                                                        />
                                    </div>
                                
    
                            {
                                this.state.numberOption  !== 'self' && (
                                    <div className="form-group col-sm-6">
                                        <label htmlFor="receivingMsisdn">Receiving Mobile
                                            Number<small>(required)</small></label>
                                        <input name="receivingMsisdn"
                                               className="form-control" type="text"
                                               required="required"
                                               placeholder="Enter Mobile Number (07********)"
                                               value={this.state.receivingMsisdn}
                                               onChange={(e) => {
                                                e.persist();
                                                this.setState((state) => (state.receivingMsisdn = e.target.value))
                                            }}
                                        />
                                    </div>
                                )
                            }
                          

                            

                           
                            {/* <div className="form-group col-sm-6">
                                <label htmlFor="amount">Amount<small>(required)</small></label>
                                <input name="msisdn"
                                       className="form-control" type="text"
                                       required="required"
                                       placeholder="Enter Amount"
                                       value={airtimeState.amount}
                                       onChange={e => setAirtimeState((s) => ({
                                           ...s,
                                           amount: e.target.value
                                       }))}
                                />
                            </div> */}
                            <div>
                            </div>
                        </div>
                    </form>
                    {
                                this.state.numberOption  !== 'self' && (

                                    <div className="col-md-6 col-lg-2 form-group">
                                                        <button className="btn btn-default rounded-2"
                                                                onClick={this.handleDisplayPaymentConfirmation}>Continue
                                                        </button>
                                    </div>
                                )
                    }

                   {
                                this.state.numberOption  !== 'other' && (

                                    <div className="col-md-6 col-lg-2 form-group">
                                                        <button className="btn btn-default rounded-2"
                                                                onClick={this.handleDisplayPaymentConfirmation}>Continue
                                                        </button>
                                    </div>
                                )
                    }
                </div>

            </div>

            <Dialog header={<h3 class="admin-heading bg-offwhite">
                    <p>Payment Details</p>
                    <span>Verify payment details</span>
                </h3>
                } visible={this.state.isShowingPaymentDetailsDialog} position={this.state.position} modal
                        style={{width: '70%', height: '80%'}}
                        footer={this.renderFooter('isShowingPaymentDetailsDialog')}
                        onHide={() => this.onHide('isShowingPaymentDetailsDialog')} breakpoints={{'960px': '75vw'}}
                        >
                    <div className='row' style={{paddingLeft: '10%', paddingRight: '10%', marginTop: '0%'}}>
                    
                        <div className='col-sm-10'style={{display: 'flex', justifyContent: 'space-between'}}>
                                <h3>Account Details</h3>
                                <button disabled={this.state.disableProcessPayment} className="btn btn-filled btn-round"
                                        onClick={this.epgpaymentConfirmation}><span className="bh"></span> <span>Process payment</span>
                                </button>

                             </div>
                        <br/>

                        <div className='row'>
                            <DataTable value={this.state.details} stripedRows responsiveLayout="scroll">
                                <Column field="name" header="Name"></Column>
                                <Column field="value" header="Value"></Column>
                            </DataTable>

                        </div>

                        <div className='row' style={{marginTop: '20px'}}>

                            <div className='col-sm-12'>
                                <button disabled={this.state.disableProcessPayment} className="btn btn-filled btn-round"
                                        onClick={this.epgpaymentConfirmation}><span className="bh"></span> <span>Process payment</span>
                                </button>

                            </div>

                        </div>

                    </div>
                </Dialog>


                <Dialog header={<h3 class="admin-heading bg-offwhite">
                    <p>Transaction processing</p>
                    <span>Enter your ecocash pin on your phone...</span>
                </h3>
                } visible={this.state.isProcessingPayment} position={this.state.position} style={{width: '20vw'}}
                       modal
               onHide={() => this.onHide('isProcessingPayment')} breakpoints={{'960px': '75vw'}}
                >
                    
                    <ReactPolling
                        url={`${process.env.REACT_APP_BACKEND_HOST_URL}/biller-transaction/find-by-source-ref/${this.state.sourceReference}`}
                        interval={3000} // in milliseconds(ms)
                        retryCount={3} // this is optional
                        headers={{
                            //"Content-Type": "application/json",
                            'Authorization': `Bearer ${this.state.token}`,
                        }}
                        onSuccess={(data) => {
                            console.log('successdata',data);
                            if (data.status == "SUCCESS") {
                                // this.showSticky('success', 'completed successfully');
                                this.toast.show({
                                    severity: 'info',
                                    summary: 'Payment success',
                                    detail: 'Your payment was successful',
                                    life: 5000
                                })

                                this.setState({
                                    isProcessingPayment: false,
                                    msisdn: '',
                                    accountNumber: '',
                                    amount: '',
                                    isShowingPaymentTransactionDetailDialog: true,
                                    transactionDetails: data

                                });

                                return false;
                            } else if (data.status == "FAILED") {
                                console.log('faileddddddd',data);
                                // this.showSticky('error', " failed. Please try again later");
                                if(this.state.selectedBiller.name == 'ZINWA Satewave'){

                                    this.toast.show({
                                        severity: 'error',
                                        summary: 'Transaction failed',
                                        detail: 'payer transaction amount less than defined value',
                                        life: 7000
                                    })
    

                                }

            
                                this.toast.show({
                                    severity: 'error',
                                    summary: 'Transaction failed',
                                    detail: 'Your transaction failed. Please try again later',
                                    life: 5000
                                })

                                this.setState({isProcessingPayment: false});
                                return false;
                            }
                            return true;
                        }}
                        onFailure={(data) => console.log("handle failure",data)} // this is optional
                        render={({startPolling, stopPolling, isPolling}) => {
                            if (isPolling) {
                                return (
                                    <div className="" style={{align: "centre"}}>
                                        <ProgressSpinner/>

                                    </div>
                                );
                            } else {
                                return <></>;
                            }
                        }}
                    />
                </Dialog>


                <Dialog header={<h3 class="admin-heading bg-offwhite">
                    <p>Transaction Details</p>
                    <span>Payment details made</span>
                </h3>
                }
                        visible={this.state.isShowingPaymentTransactionDetailDialog} position={this.state.position}
                        modal style={{width: '70%', height: '70%'}}
                        footer={this.renderFooter('isShowingPaymentTransactionDetailDialog')}
                        onHide={() => this.onHide('isShowingPaymentTransactionDetailDialog')}
                        breakpoints={{'960px': '75vw'}}>
                    <TransactionDetails tDetails={this.state.transactionDetails} tBillerName={'Smartbiz'} tBroadBandPackage={this.state.SelectedPackage} tAccountNumber={this.state.accountNumber} />
                </Dialog>




            </div>

        );

    }




}

export default withNavigation(SmartbizUsdBundles);
import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import Switch from '@mui/material/Switch';

const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: blue[600],
        '&:hover': {
            backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: blue[600],
    },
}));

const label = { inputProps: { 'aria-label': 'bundles' } };

const textSwitch = {
    display: "flex",
    justifyContent:"center",
    alignItem:"center",
    height: 20,
    width: 40,
    fontSize:8,
    weight: 300,
    color:"#fff",
    paddingRight:2
}


export default function ColorSwitches({toggleState, setToggleState}) {
    return (
        <>
            <GreenSwitch checked={toggleState} onChange={() => setToggleState((t) => (!t))} {...label} {...label} defaultChecked />
        </>
    );
}

import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { BillerTransactionService } from '../services/BillerTransactionService';
import UserProfileHeader from './utils/UserProfileHeader';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { AuthService } from '../services/AuthService';
import { Ripple } from 'primereact/ripple';

import './DataTableDemo.css';

import UserAsidePane from './utils/UserAsidePane';
import { TransactionsPage } from './facebookPixelEvent';

class Transactions extends Component {
    constructor(props){
        super(props);
        this.state={
            transactions : [],
        }
      
        this.billerTransactionService = new BillerTransactionService();
        this.authService = new AuthService();
    }

    componentDidMount(){
        TransactionsPage();
        var token = localStorage.getItem('token');
        var user = JSON.parse(localStorage.getItem('user'));

        this.authService.getToken().then(
            resp=> this.setState({ token: resp.data.body},()=>{
        this.billerTransactionService.getAccountTransactions(this.state.token, user.msisdn)
        .then(response =>{
           // console.log(response.data)
            this.setState({
                transactions : response.data
            })
        })
        .catch(error => {
//console.log(error);
        })
    }))
    }

   
    
    render() {
        const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
        const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;
        const template1 = {
            layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
            'PrevPageLink': (options) => {
                return (
                    <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                        <span className="p-3">Previous</span>
                        <Ripple />
                    </button>
                )
            },
            'NextPageLink': (options) => {
                return (
                    <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                        <span className="p-3">Next</span>
                        <Ripple />
                    </button>
                )
            },
            'PageLinks': (options) => {
                if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                    const className = classNames(options.className, { 'p-disabled': true });

                    return <span className={className} style={{ userSelect: 'none' }}>...</span>;
                }

                return (
                    <button type="button" className={options.className} onClick={options.onClick}>
                        {options.page + 1}
                        <Ripple />
                    </button>
                )
            },
            'RowsPerPageDropdown': (options) => {
                const dropdownOptions = [
                    { label: 10, value: 10 },
                    { label: 20, value: 20 },
                    { label: 50, value: 50 },
                    { label: 'All', value: options.totalRecords }
                ];

                return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
            },
            'CurrentPageReport': (options) => {
                return (
                    <span className="mx-3" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
                        Go to <InputText size="2" className="ml-1" value={this.state.currentPage} tooltip={this.state.pageInputTooltip}
                            onKeyDown={(e) => this.onPageInputKeyDown(e, options)} onChange={this.onPageInputChange}/>
                    </span>
                )
            }
        };
       

        return (
            <div>           
                <UserProfileHeader/> 

                {/* <!-- Content  --> */}
                <div id="content" className="py-4">
                    <div className="container">
                        <div className="row">

                            {/* <!-- Left sidebar --> */}
                            <aside className="col-lg-3 sidebar">
                                <UserAsidePane/>
                            </aside>
                                {/* <!-- Left Panel End --> */}

                            {/* <!-- Container Panel  --> */}
                            <div className="col-lg-9">
                                <h2 className="admin-heading bg-offwhite">Transactions</h2>

                                {/* <!-- All Transactions  --> */}
                                <div className="profile-content">                                    
                                        {/* <!-- Transaction List --> */}
                                        <div className="transaction-area">
                                        <div className="card">
                                            <DataTable value={this.state.transactions} paginator responsiveLayout="scroll" size="large" stripedRows  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10,20,50]}
                        paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} sortMode="multiple">
                                                <Column field="transactionDate" header="Transaction Date" sortable></Column>
                                                <Column field="biller.name" header="Biller Name" sortable></Column>
                                                <Column field="biller.code" header="Biller Code" sortable></Column>
                                                <Column field="status" header="Status" sortable></Column>
                                                <Column field="amount" header="Amount(ZWG)" sortable></Column>
                                            </DataTable>
                                        </div>
                                            

                                        </div>
                                        {/* <!-- Transaction List End --> */}
                                </div>
                                {/* <!-- All Transactions End --> */}
                            </div>
                            {/* <!-- Middle End --> */}
                        </div>
                    </div>
                </div>            
            </div>
        );
    }
}

export default Transactions;
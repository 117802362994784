const config = {
    
    google_key: "6LeOP_UiAAAAADD3skNGlv7UaBdO70KAT55C1hmJ",
    encryptionkey2: 'b!5Tb+EjdKyaYj6{M/atWSdkghjd8945',
    encryptioniv: '1234567890000000',
    secret:"1d47defb9a71c79fd37935bb11bde354131d35105ab6aab6a807458342247892",

    passwordkey:"EcoHashingK3y2024",
    source : "WEB",
    Username : "GuestUser",
  };
  
  
  export default config;
import React, { Component } from 'react';
import OnlinePayements from './OnlinePayements';


export default class MicroFinance extends Component {

    render(){ return (
        <div>
            <OnlinePayements category={process.env.REACT_APP_MICRO_FINANCE_ID}/>
        </div>
    )
   }
    
};

import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation,
    useNavigate
  } from "react-router-dom";
// import vector from '../images/vector.png';
import vector from '../images/lgimage.jpg';

import { AuthService } from '../services/AuthService';
import { UserService } from '../services/UserService';
import { Toast } from 'primereact/toast';

import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { Checkbox } from '@material-ui/core';
import axios from 'axios';


export const  withNavigation = (Component) => {
    return props => <Component {...props} navigate={useNavigate()} />;
  } 



class ForgotPassword extends Component {
    constructor(props) {
        super(props);
    this.state = {
        email: '',
        sent: false,
        buttonText: 'Send Email',
        data:[],
        token:''
 
  };
  this.authService = new AuthService();

}


  formSubmit = (e) => {

            e.preventDefault()
            
            this.setState({
                    buttonText: '...sending',

            })
  
            let data = {
                
                email: this.state.email,
            
            }
           

        this.authService.getToken().then(
                response => this.setState({token: response.data.body}, () => {
                    console.log('token',this.state.token)
                    axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/password/reset-password`, {
                        
                        
                        email: this.state.email
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${response.data.body}`
                        }

                    }
                    
                    ,)
                    .then( res => {

                        if (res.data.status === 500) {
                            this.toast.show({ severity: 'error', summary: 'Error creating account', detail: 'User already exists', life: 3000 });
                         
                        } else if (res.data.status === 423) {
                            this.toast.show({ severity: 'error', summary: 'Error creating account', detail: `${res.data.message}`, life: 3000 });
                              
                            this.setState({ sent: true },
                                this.resetForm(),
                               
                               // this.props.navigate('/')
                               )
                      
                        } else {
                            this.toast.show({ severity: 'success', summary: 'Password changed', detail:'Any Email will be sent with a link to reset your password', life: 5000 });
                           
                            this.setState({ sent: true },
                                this.resetForm(),
                               
                              
                               )

                              // this.props.navigate('/')
                        }
                       
                    })
                    .catch( res => {  
                    // console.log(res);
                    
                   
                    })





                }));
          

            // console.log('Data',this.state.data);
    

          
  }

   resetForm = () => {
            this.setState({
                
                email: '',
            
                buttonText: 'Email Sent',
            })
        }
  


    render() {

        
        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
               <section className="page-feature py-5">
                    <div className="container text-center">
                        <div className="row">
                            <div className="col-md-6">
                                <h2 className=" text-left">Forgot Password</h2>
                            </div>
                            <div className="col-md-6">
                                <div className="breadcrumb text-right">
                                   <Link to='/'><a >Home</a></Link>
                                    <span>/ Forgot Password</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="banner login-registration">
                    <div className="vector-img">
                        <img src={vector} alt=""/>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="content-box">
                                    <h2>Forgot Password</h2>

                                   
                                </div>
                                <div  className="sl-form">
                                    <form onSubmit={ (e) => this.formSubmit(e)} method="POST">
                                    <p>Any Email will be sent with a link to reset your password</p>
                                    <div className="form-group">
                                        <label htmlFor="email">Enter your Email</label>
                                        <input onChange={(e) => this.setState({ email: e.target.value})} name="email" className="form-control" type="email" placeholder="your@email.com" required value={this.state.email} />
                                    </div>
                                    <button type="submit" className="btn btn-default">{ this.state.buttonText }</button>
                                
                                    </form>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default withNavigation(ForgotPassword);